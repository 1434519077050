import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import type { VesselRecord } from "../lib/types";
import { type CloudSyncProperties, useCloudSync } from "./useCloudSync";
import { useDataList } from "./data";

interface AppState extends CloudSyncProperties {
  activeVessel: VesselRecord | null;
  changeVessel: (arg0: string) => void;
}
const AppStateContext = createContext<AppState | undefined>(undefined);

export const useAppState = () => {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error(
      "useAppState can't be used without value from AppStateProvider",
    );
  }
  return context;
};

export const AppStateProvider = ({ children }: PropsWithChildren) => {
  const cloudSync = useCloudSync();
  const vessels = useDataList<VesselRecord>("vessels");
  const [activeVessel, setActiveVessel] = useState<VesselRecord | null>(null);

  const changeVessel = useCallback(
    async (newId: string) => {
      setActiveVessel(null);
      if (!vessels) {
        return;
      }

      if (!newId) {
        sessionStorage.removeItem("activeVesselId");
        return;
      }

      const vessel = vessels?.filter(({ id }) => newId === id)[0];
      if (vessel) {
        setActiveVessel(vessel);
        sessionStorage.setItem("activeVesselId", vessel.id);
        return;
      }

      if (vessels.length === 1) {
        // If only 1 vessel is available, use it as the default active vessel
        setActiveVessel(vessels[0]);
        sessionStorage.setItem("activeVesselId", vessels[0].id);
      }
    },
    [vessels],
  );

  useEffect(() => {
    const rememberedVesselId = sessionStorage.getItem("activeVesselId");
    changeVessel(rememberedVesselId || "");
  }, [changeVessel, vessels]);

  return (
    <AppStateContext.Provider
      value={{ activeVessel, changeVessel, ...cloudSync }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
