import { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Button,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Block as CancelIcon } from "@mui/icons-material";

import { EquipmentRecord, QueueRecord } from "@smartship-services/core";
import { asString } from "@smartship-services/core/utils";
import {
  deleteData,
  getQueueListByRecordId,
  putDataList,
  useData,
} from "@smartship-services/core/hooks";
import {
  itemsWithOwnPage,
  makeLink,
  QueueDisplay,
  readableMethod,
  readableTable,
} from "../SyncQueue/QueueDisplay";
import { EquipmentDetails, StatusChip } from "@smartship-services/ui";

const QueuedRequestPage = () => {
  const { requestId = "" } = useParams();
  const queuedRequest = useData<QueueRecord>("queue", requestId);
  const [relatedRequests, setRelatedRequests] = useState<QueueRecord[]>([]);
  const [actionsErrorMessage, setActionsErrorMessage] = useState("");

  const handleRetry = async () => {
    if (!queuedRequest) {
      return;
    }

    try {
      await putDataList("queue", [
        {
          ...queuedRequest,
          status: "retry",
        },
      ]);
    } catch (err) {
      console.error(err);
      setActionsErrorMessage("Error updating queue to retry");
    }
  };

  const handleDelete = async (id: string) => {
    if (!id) {
      return;
    }

    try {
      await deleteData("queue", id);
    } catch (err) {
      console.error(err);
      setActionsErrorMessage("Error deleting queued change");
    }

    setRelatedRequests((prev) => prev.filter((related) => related.id !== id));
  };

  useEffect(() => {
    if (!queuedRequest?.recordId) {
      return;
    }

    getQueueListByRecordId(queuedRequest.recordId)
      .then(setRelatedRequests)
      .catch((err) => {
        console.error(err);
        setRelatedRequests([]);
      });
  }, [queuedRequest]);

  return queuedRequest ? (
    <main>
      <Typography variant="h1">
        Queued Request{" "}
        <StatusChip
          errorMessage={queuedRequest.errorMessage}
          status={queuedRequest.status}
        />
      </Typography>
      {queuedRequest.errorMessage ? (
        <Alert severity="error" sx={{ mb: 1 }}>
          <AlertTitle>Sync error message</AlertTitle>
          {asString(queuedRequest.errorMessage)}
        </Alert>
      ) : null}
      <Typography>
        <strong>Timestamp:</strong>&nbsp;
        {queuedRequest.timestamp?.toLocaleString()}
      </Typography>
      <Typography>
        <strong>Related work order:</strong>&nbsp;
        {queuedRequest.workOrderId ? (
          <Link
            to={"/work-orders/" + queuedRequest.workOrderId}
            component={RouterLink}
          >
            Work Order
          </Link>
        ) : (
          "none"
        )}
      </Typography>
      <Typography>
        <strong>Change type:</strong>&nbsp;
        {readableMethod(queuedRequest.method)}
      </Typography>
      <Typography>
        <strong>Item:</strong>&nbsp;
        {itemsWithOwnPage.includes(queuedRequest.table) ? (
          <Link
            to={makeLink(queuedRequest.table, queuedRequest.recordId)}
            component={RouterLink}
          >
            {readableTable(queuedRequest.table)}
          </Link>
        ) : (
          <span>{readableTable(queuedRequest.table)}</span>
        )}
      </Typography>
      {queuedRequest.table === "equipment" && queuedRequest.record ? (
        <div>
          <Typography variant="h2" sx={{ mb: 1, mt: 3 }}>
            Preview Changes
          </Typography>
          <Paper>
            <EquipmentDetails
              equipment={queuedRequest.record as EquipmentRecord}
            />
          </Paper>
        </div>
      ) : null}
      <Typography variant="h2" sx={{ mt: 3 }}>
        Related Requests
      </Typography>
      {relatedRequests.length > 1 ? (
        <div>
          <Typography>These requests affect the same item.</Typography>
          <QueueDisplay
            handleDelete={handleDelete}
            list={relatedRequests.filter(({ id }) => id !== requestId)}
          />
        </div>
      ) : (
        <Paper sx={{ p: 2 }}>none</Paper>
      )}
      <Typography variant="h2" sx={{ mb: 2, mt: 3 }}>
        Actions
      </Typography>
      <Stack gap={1} direction="row">
        {queuedRequest.status === "rejected" ? (
          <Button color="warning" onClick={handleRetry} variant="outlined">
            Retry rejected request
          </Button>
        ) : null}
        <Button
          color="error"
          onClick={() => handleDelete(requestId)}
          startIcon={<CancelIcon />}
          variant="outlined"
        >
          Remove request
        </Button>
      </Stack>
      {actionsErrorMessage ? (
        <Alert severity="error" sx={{ mt: 1 }}>
          <AlertTitle>Actions error</AlertTitle>
          {asString(actionsErrorMessage)}
        </Alert>
      ) : null}
    </main>
  ) : (
    <main>
      <Typography>No queued request found with this ID.</Typography>
    </main>
  );
};

export default QueuedRequestPage;
