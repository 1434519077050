import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  Alert,
  AlertTitle,
  Box,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";

import {
  EquipmentRecord,
  FileMetadataRecord,
  Suggestions,
} from "@smartship-services/core";
import {
  getSerialFromQr,
  useEquipmentListByVessel,
  writeData,
  writeDataList,
} from "@smartship-services/core/hooks";
import { buildSuggestions } from "@smartship-services/core/utils";
import {
  SaveButton,
  SaveAndCreateAnotherButton,
  ResetButton,
} from "../../buttons";
import {
  EquipmentCombobox,
  EquipmentTrafficLight,
  EquipmentTextField,
  ImageUploadField,
} from "..";
import { QrReaderFormField } from "../..";

const defaultFields = [
  "equipmentName",
  "serialNumber",
  "apId",
  "makerName",
  "location",
  "freeComment",
  "condition",
];

type BoolNumString = boolean | number | string;
const fieldProperties: Record<string, Record<string, BoolNumString>> = {
  condition: {},
  description: {
    multiline: true,
    minRows: 2,
    maxRows: 10,
  },
  equipmentName: {
    required: true,
  },
  freeComment: {
    multiline: true,
    minRows: 2,
    maxRows: 10,
  },
  location: {
    options: true,
  },
  makerName: {
    options: true,
  },
};

interface CreateEquipmentForm {
  equipmentName: string;
  [key: string]: string;
}
const buildForm = (fields: string[]) => {
  const form = {} as CreateEquipmentForm;

  fields.forEach((field) => {
    form[field] = "";
  });

  return form;
};

export const CreateEquipmentForm = ({ vesselId }: { vesselId: string }) => {
  //hooks
  const navigate = useNavigate();
  const equipmentList = useEquipmentListByVessel(vesselId);

  //local state
  const [suggestions, setSuggestions] =
    useState<Suggestions>(buildSuggestions());
  const formFields = defaultFields; //TODO: allow user to choose form field template
  const [formValues, setFormValues] = useState(buildForm(formFields));
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [recentEquipment, setRecentEquipment] = useState<
    Array<[string, string]>
  >([]);
  const [imageMetadata, setImageMetadata] = useState<FileMetadataRecord[]>([]);

  const handleFormValues = (field: string, newValue: string | undefined) => {
    //TODO: form field validation
    setFormValues((prev) => {
      const updated = { ...prev };
      updated[field] = newValue || "";
      if (field === "apId") {
        updated.almacoSerialNumber = getSerialFromQr(newValue);
      }
      sessionStorage.setItem("create-equipment-form", JSON.stringify(updated));
      return updated;
    });
  };

  const resetForm = (skipSure = false) => {
    if (!skipSure) {
      const isSure = window.confirm(
        "Are you sure you want to empty all form fields?",
      );
      if (!isSure) {
        return;
      }
    }

    setFormValues((prev) => {
      const emptied = { ...prev };
      Object.keys(emptied).forEach((key) => {
        emptied[key] = "";
      });
      return emptied;
    });
    setImageMetadata([]);
    sessionStorage.removeItem("create-equipment-form");
  };

  const handleSave = async (doneOrContinue: "done" | "continue") => {
    if (formValues.equipmentName.length === 0) {
      setErrorMessage("Please add a name for this equipment");
      return;
    }

    setErrorMessage("");

    const equipmentDraft: EquipmentRecord = {
      custom: [],
      id: uuid(),
      vesselId: vesselId,
      ...formValues,
      apId: formValues.apId || null,
      almacoSerialNumber: formValues.almacoSerialNumber || null,
    };
    resetForm(true);

    let path = "";
    try {
      const createdEquipmentId = await writeData<EquipmentRecord>(
        "equipment",
        equipmentDraft,
      );
      if (!createdEquipmentId) {
        setErrorMessage("Saving data failed");
        return;
      }
      path = `/vessels/${vesselId}/equipment/${createdEquipmentId}`;
    } catch (err) {
      setErrorMessage("Saving data failed: database error");
      return;
    }

    //after successfully saving equipment, save any file metadata
    if (imageMetadata.length) {
      imageMetadata.forEach((metadata, i) => {
        imageMetadata[i].equipmentId = equipmentDraft.id;
      });
      try {
        await writeDataList<FileMetadataRecord>("fileMetadata", imageMetadata);
      } catch (err) {
        setErrorMessage("Error storing image files.");
      }
    }

    if (doneOrContinue === "done") {
      navigate(path);
    } else {
      setIsToastOpen(true);
      setRecentEquipment((prev) => {
        const updated = [...prev];
        updated.push([path, formValues.equipmentName]);
        return updated;
      });
    }
  };

  const handleToastClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsToastOpen(false);
  };

  const handleImages = (
    change: "add" | "remove",
    newMetadata: FileMetadataRecord[],
  ) => {
    if (change === "remove") {
      const toRemove = newMetadata[0];
      setImageMetadata((prev) => prev.filter(({ id }) => id !== toRemove.id));
    } else if (change === "add") {
      setImageMetadata((prev) => {
        const prevFilenames = prev.map(({ filename, id }) => filename || id);
        const uniqueNewImages = newMetadata.filter(
          (each) => !prevFilenames.includes(each.filename || each.id),
        );
        return [...prev, ...uniqueNewImages];
      });
    }
  };

  useEffect(() => {
    if (equipmentList?.length) {
      const suggested = buildSuggestions(equipmentList);
      setSuggestions(suggested);
    } else {
      setSuggestions({});
    }
  }, [equipmentList]);

  useEffect(() => {
    const sessionValue = sessionStorage.getItem("create-equipment-form");
    if (sessionValue) {
      const parsedSessionValue = JSON.parse(sessionValue);
      setFormValues(parsedSessionValue);
    }
  }, []);

  return (
    <main>
      <Typography variant="h1">Create equipment</Typography>
      <div className="flex justify-center mt-5">
        <RouterLink
          to={`/vessels/${vesselId}/equipment/import`}
          className="btn btn-primary"
        >
          Import from spreadsheet
        </RouterLink>
      </div>
      <div className="divider my-8">OR</div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          alignItems: "center",
          maxWidth: 600,
        }}
      >
        {formFields.map((field) => {
          const key = "create-form-" + field;
          const setter = (value: string) => handleFormValues(field, value);
          const value: string = formValues[field];

          if (fieldProperties[field]?.options) {
            return (
              <EquipmentCombobox
                id={field}
                key={key}
                setter={setter}
                suggestions={suggestions[field]}
                value={value}
              />
            );
          }

          if (field === "condition") {
            return (
              <EquipmentTrafficLight key={key} setter={setter} value={value} />
            );
          }

          if (field === "apId") {
            return (
              <QrReaderFormField key={key} setter={setter} value={value} />
            );
          }

          return (
            <EquipmentTextField
              id={field}
              key={key}
              otherProps={fieldProperties[field]}
              setter={setter}
              value={value}
            />
          );
        })}
        <ImageUploadField imageMetadata={imageMetadata} setter={handleImages} />
        {errorMessage ? (
          <Alert severity="error">
            <AlertTitle>Form validation error</AlertTitle>
            {errorMessage}
          </Alert>
        ) : null}
        <SaveButton handler={() => handleSave("done")} />
        <SaveAndCreateAnotherButton handler={() => handleSave("continue")} />
        <ResetButton handler={() => resetForm()} />
      </Box>
      {recentEquipment.length > 0 ? (
        <Box>
          <Typography variant="h2" sx={{ m: 2 }}>
            Recently Added:
          </Typography>
          <ul>
            {recentEquipment.map(([path, name]) => (
              <li key={path}>
                <Link component={RouterLink} to={path}>
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </Box>
      ) : null}
      <Snackbar
        autoHideDuration={6000}
        onClose={handleToastClose}
        open={isToastOpen}
      >
        <Alert
          onClose={handleToastClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Equipment saved!
        </Alert>
      </Snackbar>
    </main>
  );
};
