import { DetailedHTMLProps, FC, ImgHTMLAttributes } from "react";

import openBoxIcon from "./open-box.svg";
import qrCodeIcon from "./qr-code.svg";
import shipIcon from "./ship.svg";

type ImageProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

export const OpenBoxIcon: FC<ImageProps> = (props) => {
  return <img src={openBoxIcon} alt="Open box icon" {...props} />;
};

export const ScanQrCodeIcon: FC<ImageProps> = (props) => {
  return <img src={qrCodeIcon} alt="Scan QR code icon" {...props} />;
};

export const ShipIcon: FC<ImageProps> = (props) => {
  return <img src={shipIcon} alt="Ship icon" {...props} />;
};
