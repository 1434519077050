import { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import {
  Alert,
  type AlertColor,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
} from "@mui/material";

import { justTheDate } from "@smartship-services/core/utils";
import { bottomSpaceForAppBar } from "../lib/consts";

interface DateFieldProps {
  label?: string;
  setter: (arg0: Date | null) => void;
  value: Date | null;
}

export const DateField = ({ label, setter, value }: DateFieldProps) => {
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setter(new Date(e.target.value));
    } else {
      setter(null);
    }
  };

  return (
    <TextField
      label={label || "Date"}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={changeHandler}
      type="date"
      value={value ? justTheDate(value) : ""}
    />
  );
};

interface TextFieldProps {
  label?: string;
  setter: (arg0: string) => void;
  value: string | undefined;

  [key: string]: unknown; //for rest props
}

export const QuickTextField = ({
  label,
  setter,
  value,
  ...rest
}: TextFieldProps) => {
  //locally managed state to prevent lag in large complicated forms
  const [localState, setLocalState] = useState("");

  const blurHandler = (e: FocusEvent<HTMLInputElement>) =>
    setter(e.target.value);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setLocalState(e.target.value);

  useEffect(() => {
    //use provided value but prevent infinite loop
    if (value !== localState) {
      setLocalState(value || "");
    }
  }, [value]);

  return (
    <TextField
      fullWidth
      label={label || "Enter text"}
      onBlur={blurHandler}
      onChange={changeHandler}
      value={localState}
      {...rest}
    />
  );
};

export const QuickParagraphField = ({
  label,
  setter,
  value,
}: TextFieldProps) => (
  <QuickTextField
    label={label || "Comments"}
    maxRows={20}
    minRows={3}
    multiline={true}
    setter={setter}
    value={value}
  />
);

interface MultipleSelectProps {
  label?: string;
  optionLabels?: string[]; //can include labels if options aren't very human readable
  options: string[];
  setter: (arg0: string[]) => void;
  value: string[];
}

export const MultipleSelect = ({
  label,
  optionLabels,
  options,
  setter,
  value,
}: MultipleSelectProps) => {
  const changeHandler = (e: SelectChangeEvent<string | string[]>) => {
    let newValue = e.target.value;
    if (typeof newValue === "string") {
      newValue = newValue.split(",");
    }
    setter(newValue);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="multiple-select-label">
        {label || "Select one or more"}
      </InputLabel>
      <Select
        label={label || "Select one or more"}
        labelId="multiple-select-label"
        multiple
        onChange={changeHandler}
        value={value}
      >
        {options.map((option, index) => (
          <MenuItem key={option} value={option}>
            {optionLabels?.[index] ? optionLabels[index] : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface SuccessOrErrorToastProps {
  isOpen: boolean;
  message: string;
  setter: (arg0: boolean) => void;
  severity?: AlertColor;
}

export const AlertToast = ({
  isOpen,
  message,
  setter,
  severity = "success",
}: SuccessOrErrorToastProps) => {
  const handleCloseToast = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setter(false);
  };
  return (
    <Snackbar
      autoHideDuration={5000}
      onClose={handleCloseToast}
      open={isOpen}
      className={bottomSpaceForAppBar}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};
