//TODO: this options list should come from a central source so it's always up-to-date
//TODO: uncomment the combination option when that feature is complete
//SelectEquipmentField for import page also depends on this list
export const equipmentKeyDescriptions = [
  //{ key: '', label: '', description: '' },
  {
    key: "equipmentName",
    label: "Equipment Name",
    description:
      "REQUIRED. customer's name for this piece of equipment, e.g. Combi oven lower level",
  },
  {
    key: "makerName",
    label: "Brand / Maker",
    description: "brand / company name of the equipment maker, e.g. Convotherm",
  },
  {
    key: "custom",
    label: "☆ Custom",
    description:
      "If there are no good matches, choose this option to use the column name from the spreadsheet",
  },
  {
    key: "freeComment",
    label: "Free Comment",
    description: "notes and observations about the equipment",
  },
  {
    key: "idFromCustomer",
    label: "ID from customer",
    description: "ID or code used by customer for identifying the equipment",
  },
  {
    key: "ignore",
    label: "⏭ Ignore / skip",
    description: "do not import this column",
  },
  {
    key: "installationDate",
    label: "Installation Date",
    description: "the date that the equipment was installed",
  },
  {
    key: "location",
    label: "Location",
    description: "location aboard the ship, e.g. Dk 05 Main galley",
  },
  {
    key: "locationIdFromCustomer",
    label: "Location ID from customer",
    description: "ID or code used by customer for identifying the location",
  },
  {
    key: "model",
    label: "Model",
    description: "specific model of this product, e.g. GSM 5 Star",
  },
  {
    key: "serialNumber",
    label: "Serial Number",
    description:
      "the original serial number from the maker / manufacturer, e.g. 43789423-328",
  },
  {
    key: "condition",
    label: "Traffic Light Condition",
    description: "condition of equipment: good, needs service, or fail",
  },
  {
    key: "warrantyEnds",
    label: "Warranty End Date",
    description: "the date when any warranty for this equipment ends",
  },
  {
    key: "ymc",
    label: "YMC",
    description:
      "Yard Marking Code, indicates physical location aboard the ship, e.g. Dk 05 Main galley (0110)",
  },
  {
    key: "almacoSerialNumber",
    label: "ALMACO Serial Number",
    description: "ALMACO's serial number for this particular equipment",
  },
  {
    key: "apId",
    label: "ALMACO apId",
    description: "ID of this area product from the Product Database",
  },
  //{ key: 'combination', label: '⤭ combination', description: 'Choose this if the column matches more than one option, e.g. Deep Fryer Dk 5' },
];
export const makeNameHumanReadable = (rawName: string) => {
  const match = equipmentKeyDescriptions.filter(
    (each) => each.key === rawName,
  )[0];
  if (match) {
    return match.label;
  } else {
    return rawName;
  }
};
export const makeNamesHumanReadable = (rawList: string[]) => {
  return rawList.map(makeNameHumanReadable);
};
