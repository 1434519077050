import {
  CheckIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { EquipmentCondition, EquipmentRecord } from "./types";

export const mapConditionToVisuals = (
  condition?: EquipmentRecord["condition"],
) => {
  switch (condition) {
    case "good":
    //fallthrough
    case EquipmentCondition.GOOD:
      return {
        label: "Good",
        icon: <CheckIcon className="text-success w-6 h-6" />,
        color: "success" as const,
      };
    case "needs service":
    //fallthrough
    case EquipmentCondition.NEEDS_SERVICE:
      return {
        label: "Needs Service",
        icon: <WrenchIcon className="text-warning w-6 h-6" />,
        color: "warning" as const,
      };
    case "fail":
    //fallthrough
    case EquipmentCondition.FAIL:
      return {
        label: "Fail",
        icon: <ExclamationCircleIcon className="text-error w-6 h-6" />,
        color: "error" as const,
      };
    default:
      return {
        label: "Unknown",
        icon: <QuestionMarkCircleIcon className="text-secondary w-6 h-6" />,
        color: "secondary" as const,
      };
  }
};
