import { PropsWithChildren } from "react";

import { useAuthentication } from "@smartship-services/core/hooks"; // hooks
import { hasAllRequired } from "@smartship-services/core/utils"; // utils

interface ShowOnlyForProps extends PropsWithChildren {
  permissions?: string[];
}

export const ShowOnlyFor = ({ children, permissions }: ShowOnlyForProps) => {
  const { currentUser } = useAuthentication();

  if (!hasAllRequired(currentUser?.permissions, permissions)) {
    return null;
  }

  return children;
};
