import { useEffect, useState } from "react";
import { bottomSpaceForAppBar } from "../lib/consts";

interface BeforeInstallPromptEvent extends Event {
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  prompt(): Promise<void>;
}

export const InstallPWAToast = () => {
  const [deferredEvent, setDeferredEvent] =
    useState<BeforeInstallPromptEvent | null>(null);

  const handleInstall = async () => {
    await deferredEvent?.prompt();
    setDeferredEvent(null);
  };

  const handleDecline = () => {
    setDeferredEvent(null);
  };

  const handleInstallPromptEvent = (e: BeforeInstallPromptEvent) => {
    e.preventDefault();
    setDeferredEvent(e);
  };

  useEffect(() => {
    window.addEventListener(
      "beforeinstallprompt",
      handleInstallPromptEvent as EventListener,
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleInstallPromptEvent as EventListener,
      );

      setDeferredEvent(null);
    };
  }, []);

  if (!deferredEvent) {
    return null;
  }

  return (
    <div className={`toast z-10 ${bottomSpaceForAppBar}`}>
      <div className="alert bg-white">
        Add this progressive web app to your device
        <div className="flex gap-2">
          <button
            onClick={handleDecline}
            className="btn btn-outline btn-warning"
          >
            Decline
          </button>
          <button onClick={handleInstall} className="btn btn-primary">
            Install
          </button>
        </div>
      </div>
    </div>
  );
};
