import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

import { almacoLogo } from "../../assets";
import { useAuthentication } from "@smartship-services/core/hooks";
import { MicrosoftLoginButton } from "../../buttons";

interface Props {
  source: string;
  title: string;
  subTitle: string;
}

export const LoginForm = ({ source, title, subTitle }: Props) => {
  const {
    apiLogin,
    authErrorMessage,
    authSuccessMessage,
    isAuthInProgress,
    isMsAuthenticated,
    sendMagicLink,
  } = useAuthentication();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter your email address"),
    }),
    onSubmit: ({ email }) => {
      if (sendMagicLink) {
        sendMagicLink(email, source);
      }
    },
  });

  useEffect(() => {
    if (isMsAuthenticated && apiLogin) {
      apiLogin();
    }
  }, [apiLogin, isMsAuthenticated]);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const token = searchParams.get("token");
    if (token && apiLogin) {
      apiLogin(token);
    }

    return () => {
      searchParams.delete("token");
    };
  }, [apiLogin]);

  return (
    <div className="min-h-screen w-full bg-white bg-ships-at-sea bg-cover">
      <div className="min-h-screen w-full flex flex-col items-center">
        {authSuccessMessage ? (
          <div className="toast toast-top toast-end">
            <div className="alert alert-success">
              <CheckCircleIcon className="text-black icon-size-default" />
              <span>{authSuccessMessage}</span>
            </div>
          </div>
        ) : null}
        {authErrorMessage ? (
          <div className="toast toast-top toast-end">
            <div className="alert alert-error">{authErrorMessage}</div>
          </div>
        ) : null}
        {isAuthInProgress ? (
          <div className="toast toast-top toast-end">
            <div className="alert">
              <span className="loading loading-spinner m-2"></span>
              <span>Logging in...</span>
            </div>
          </div>
        ) : null}
        <header className="place-self-start mt-8 ml-8 sm:mt-16 sm:ml-16 montserrat">
          <span className="text-5xl sm:text-6xl font-black">{title}</span>
          <br />
          <span className="text-lg font-medium">{subTitle}</span>
        </header>
        <div className="flex flex-col flex-grow w-full max-w-[450px] justify-center items-center p-5">
          <h2 className="font-black">WELCOME</h2>
          <span className="montserrat font-medium mb-10">
            Sign in to your account
          </span>
          <form onSubmit={formik.handleSubmit} className="w-full">
            <input
              id="email"
              name="email"
              type="text"
              className="input w-full shadow-md"
              placeholder="Email address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-error text-sm mt-1 p-1 backdrop-blur-lg rounded">
                {formik.errors.email}
              </div>
            ) : null}
            <button type="submit" className="btn btn-primary w-full mt-5 mb-5">
              <span className="normal-case montserrat text-white font-extrabold">
                Sign in with your email
              </span>
            </button>
          </form>
          <div className="flex flex-col w-full border-opacity-50 mb-5">
            <div className="divider">
              <span className="montserrat text-sm font-medium flex-shrink mx-1 text-gray-500">
                Or continue with
              </span>
            </div>
          </div>
          <MicrosoftLoginButton />
          {isMsAuthenticated ? (
            <div className="mt-2 p-2 backdrop-blur-lg rounded">
              <span>You have successfully signed in with Microsoft</span>
            </div>
          ) : null}
        </div>
        <footer className="justify-self-end mb-4">
          <img src={almacoLogo} alt="ALMACO logo" className="h-24" />
        </footer>
      </div>
    </div>
  );
};
