import { VesselRecord } from "@smartship-services/core";
import { useDataList } from "@smartship-services/core/hooks";
import { VesselList } from "@smartship-services/ui/lists";

const VesselListPage = () => {
  const vessels = useDataList<VesselRecord>("vessels");
  return <VesselList vessels={vessels} />;
};

export default VesselListPage;
