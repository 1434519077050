import { deleteData, getQueueList } from "../hooks/data";

export async function consolidateQueue() {
  /*
    If a record is created and deleted locally before ever being synced,
    this function removes all related requests from the sync queue
    so it won't be needlessly created and deleted in the cloud.
  */
  try {
    const rawQueue = await getQueueList();
    const queuedDeletions = rawQueue.filter(
      ({ method }) => method === "DELETE",
    );

    for (const queuedDeletion of queuedDeletions) {
      const queuedRequestsForSameRecord = rawQueue.filter(
        ({ recordId }) => recordId === queuedDeletion.recordId,
      );

      const isCreationQueued = queuedRequestsForSameRecord.filter(
        ({ method }) => method === "POST",
      ).length;

      if (isCreationQueued) {
        for (const relatedRequest of queuedRequestsForSameRecord) {
          await deleteData("queue", relatedRequest.id);
        }
      }
    }
  } catch (err) {
    console.error("Error consolidating queue:", err);
  }
}
