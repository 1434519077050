import { Link as RouterLink } from "react-router-dom";
import {
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Block as CancelIcon,
  CallMade as UpRightArrowIcon,
} from "@mui/icons-material";

import { QueueRecord } from "@smartship-services/core";

import { StatusChip } from "@smartship-services/ui";
import { HelpButton } from "@smartship-services/ui/buttons";

export const itemsWithOwnPage = ["equipment", "workOrders"];

export const readableMethod = (method: string) => {
  const methodMap: Record<string, string> = {
    GET: "read",
    POST: "create",
    PUT: "edit",
    DELETE: "delete",
  };
  return methodMap[method] || method;
};

export const readableTable = (table: string) => {
  const tableMap: Record<string, string> = {
    fileMetadata: "photo",
    locations: "location",
    vessels: "vessel",
    workOrders: "work order",
  };
  return tableMap[table] || table;
};

export const makeLink = (table: string, id: string) => {
  const route = table === "workOrders" ? "work-orders" : table;
  return `/${route}/${id}`;
};

interface QueueDisplayProps {
  handleDelete: (arg0: string) => void;
  list?: QueueRecord[];
}

export const QueueDisplay = ({ handleDelete, list }: QueueDisplayProps) => (
  <TableContainer component={Paper} sx={{ mt: 1 }}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Status</TableCell>
          <TableCell>Change</TableCell>
          <TableCell>
            <span>Cancel</span>
            <HelpButton content="Remove from sync queue. Changes will remain local and may be overwritten during next sync" />
          </TableCell>
          <TableCell>Details</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list?.length ? (
          list.map(({ errorMessage, id, method, recordId, status, table }) => (
            <TableRow key={id}>
              <TableCell>
                <StatusChip errorMessage={errorMessage} status={status} />
              </TableCell>
              <TableCell>
                {readableMethod(method)}
                :&nbsp;&nbsp;
                {itemsWithOwnPage.includes(table) ? (
                  <Link to={makeLink(table, recordId)} component={RouterLink}>
                    {readableTable(table)}
                  </Link>
                ) : (
                  <span>{readableTable(table)}</span>
                )}
              </TableCell>
              <TableCell>
                <IconButton color="error" onClick={() => handleDelete(id)}>
                  <CancelIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton
                  color="primary"
                  component={RouterLink}
                  to={"/sync-queue/" + id}
                >
                  <UpRightArrowIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell>
              <StatusChip status="done" />
            </TableCell>
            <TableCell>No pending changes to upload</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
