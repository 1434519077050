import { LoginForm } from "@smartship-services/ui/forms";

const LandingPage = () => {
  return (
    <LoginForm
      source="MAINS_APP"
      title="mains"
      subTitle="Maintenance & Inspection"
    />
  );
};

export default LandingPage;
