import { useNavigate } from "react-router-dom";

interface IconAndButtonCardProps {
  accented?: boolean;
  action?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  path?: string;
  title: string;
}
export const ContentAndButtonCard = ({
  accented,
  action,
  children,
  disabled = false,
  path = "",
  title,
}: IconAndButtonCardProps) => {
  const navigate = useNavigate();

  const actionOrPath = () => {
    if (disabled) {
      return;
    }

    action ? action() : navigate(path);
  };

  return (
    <div className={`bg-neutral ${accented ? "accent-top" : ""}`}>
      <div
        onClick={actionOrPath}
        className={`sm:pointer-events-none ${disabled ? "" : "cursor-pointer"}`}
      >
        {children}
      </div>
      <button
        disabled={disabled}
        onClick={actionOrPath}
        className="btn btn-primary w-full disabled:bg-secondary disabled:text-white"
      >
        {title}
      </button>
    </div>
  );
};
