import React from "react";
import ReactDOM from "react-dom/client";

import { ErrorBoundary } from "react-error-boundary";

import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/900.css";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

import { initSentry, msalInstance } from "@smartship-services/core";
import { ErrorPage } from "@smartship-services/ui";

initSentry(process.env.REACT_APP_SENTRY_DSN);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <App msal={msalInstance} />
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
