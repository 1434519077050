import {
  type Configuration,
  type EventMessage,
  type PopupRequest,
  type AuthenticationResult,
  EventType,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";

const CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID || "";
const TENANT_ID = process.env.REACT_APP_AZURE_TENANT_ID;
const REDIRECT_URI = window.location.origin;

if (!CLIENT_ID || !TENANT_ID || !REDIRECT_URI) {
  console.error("Missing Microsoft login config variables in environment");
}

const isTestEnv = process.env.NODE_ENV === "test";
const isProdEnv = process.env.NODE_ENV === "production";

const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: "https://login.microsoftonline.com/" + TENANT_ID,
    redirectUri: REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii || isTestEnv || isProdEnv) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

async function initializeMsalAndAccount() {
  await msalInstance.initialize();
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    //default to first active account
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload
    ) {
      const { account } = event.payload as AuthenticationResult;
      msalInstance.setActiveAccount(account);
    }
  });
}
initializeMsalAndAccount();

export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};
