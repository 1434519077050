import {
  EllipsisHorizontalCircleIcon,
  ArrowLeftEndOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { useAppState, useAuthentication } from "@smartship-services/core/hooks";
import { Link } from "react-router-dom";
import { CloudSyncButton } from "../../buttons/CloudSyncButton";

export const SettingsDisplay = () => {
  const { syncStatus } = useAppState();
  const { logout } = useAuthentication();

  return (
    <main className="mx-auto max-w-2xl">
      <div className="border-t-[3px] border-primary shadow-md">
        <h5 className="text-center mt-2 montserrat font-bold text-secondary uppercase">
          Settings
        </h5>
        <ul className="menu p-0 montserrat uppercase text-secondary font-medium mt-6">
          <li className="border border-neutral hover:bg-neutral">
            <span className="p-3">
              <CloudSyncButton />
              Sync status: {syncStatus}
            </span>
          </li>
          <li className="border border-neutral hover:bg-neutral">
            <Link to="/sync-queue" className="p-3">
              <EllipsisHorizontalCircleIcon className="icon-size-default" />
              View Sync Queue
            </Link>
          </li>
          <li className="border border-neutral hover:bg-neutral">
            <button role={"link"} onClick={logout} className="p-3 uppercase">
              <ArrowLeftEndOnRectangleIcon className="icon-size-default text-secondary" />
              Logout
            </button>
          </li>
        </ul>
      </div>
    </main>
  );
};
