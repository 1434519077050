import Dexie, { Table } from "dexie";

import {
  EquipmentRecord,
  FileMetadataRecord,
  LocationRecord,
  QueueRecord,
  VesselRecord,
  WorkOrderRecord,
} from "./types";
import { api } from "../utils/api";
import sampleEquipment from "../assets/dummy_equipment.json";
import sampleLocations from "../assets/dummy_locations.json";
import sampleVessels from "../assets/dummy_vessels.json";
import sampleWorkOrders from "../assets/dummy_work_orders.json";

export class MaintenanceDB extends Dexie {
  //declare types for stores
  equipment!: Table<EquipmentRecord>;
  fileMetadata!: Table<FileMetadataRecord>;
  locations!: Table<LocationRecord>;
  queue!: Table<QueueRecord>;
  vessels!: Table<VesselRecord>;
  workOrders!: Table<WorkOrderRecord>;

  constructor() {
    super("maintenance-offline"); //db name
    this.version(1).stores({
      // primary key, other indexed fields
      equipment: "&id, vesselId",
      vessels: "&id, &vesselName",
      workOrders: "&id, vesselId",
    });
    this.version(2)
      .stores({
        locations: "&id, vesselId",
      })
      .upgrade((transaction) => {
        /* clear dummy data from first version */
        transaction.table("equipment").clear();
        transaction.table("vessels").clear();
        transaction.table("workOrders").clear();
      });
    this.version(3).stores({
      queue: "&id, table, recordId",
      fileMetadata: "&id, filename",
    });
    this.version(4).stores({
      equipment: "&id, vesselId, apId",
    });
    this.version(5).stores({
      queue: "&id, table, recordId, workOrderId",
    });
    this.version(6).stores({
      fileMetadata: "&id, filename, equipmentId",
    });

    this.on("ready", async (db) => {
      const vesselCount = await db.table("vessels").count();
      const isDev = process.env.REACT_APP_API_URL?.includes("dev");
      const isTest = process.env.NODE_ENV === "test";
      if ((isTest || isDev) && vesselCount < 3) {
        db.table("vessels").bulkAdd(sampleVessels);
        db.table("locations").bulkAdd(sampleLocations);
        db.table("equipment").bulkAdd(sampleEquipment);
        db.table("workOrders").bulkAdd(sampleWorkOrders);
      }

      if (!isTest) {
        api.sync();
      }
    });
  }
}

const db = new MaintenanceDB();

/* Dexie will open browser database automatically when first queried,
   which should only happen after login */

export { db };
