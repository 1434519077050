import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { PageTitleWithOptionalButtons } from "../../layout/PageTitleWithOptionalButtons";
import { EquipmentRecord } from "@smartship-services/core";
import { ReactNode } from "react";

const EditButton = () => (
  <Link to="edit">
    <PencilSquareIcon className="icon-size-default" />
  </Link>
);

export { EquipmentDetails } from "./EquipmentDetails";

interface Props {
  equipment?: EquipmentRecord;
  hasEditPermission?: boolean;
  children?: ReactNode;
}

export const EquipmentDisplay = ({
  equipment,
  hasEditPermission,
  children,
}: Props) => {
  return (
    <main className="m-auto max-w-6xl border border-funnel40 accent-top">
      <PageTitleWithOptionalButtons
        OptionalRightSideButton={hasEditPermission ? <EditButton /> : undefined}
        title={(equipment?.name as string) || "[No name]"}
      />
      <div className="p-3">{children}</div>
    </main>
  );
};
