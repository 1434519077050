import useSWR from "swr";
import { fetchWrapper } from "../utils";
import { Vessel } from "@almaco-group/project-types";

// Fetches all vessels from the project-service
export const useAllVessels = () => {
  const { data, error, isLoading } = useSWR<{ data: Vessel[] }>(
    "/rest/vessels?takeAll=true",
    fetchWrapper,
  );

  if (error) {
    console.error(error);

    return {
      error: new Error(
        "Could not fetch vessels, please check your internet connection",
      ),
    };
  }

  return {
    vessels: data?.data,
    isLoading,
  };
};
