import { AlignmentType, BorderStyle, WidthType } from "docx";

//Max px length of longest side of photo, whether width or height
export const MAX_PHOTO_DIMENSION = 200;

export const borderNone = {
  borders: {
    top: { style: BorderStyle.NIL },
    bottom: { style: BorderStyle.NIL },
    left: { style: BorderStyle.NIL },
    right: { style: BorderStyle.NIL },
  },
};

export const centerAligned = { alignment: AlignmentType.CENTER };

export const colorMap: Record<string, string> = {
  primary: "179DD9", // OCEAN BLUE 60
  secondary: "505759", // FUNNEL 100
  success: "71A151", // COAST GREEN 80
  warning: "DF6426", // FIREZONE 60
  error: "AA4B26", // FIREZONE 80
};

export const fullWidth = {
  width: {
    size: 100,
    type: WidthType.PERCENTAGE,
  },
};

export const halfWidth = {
  width: {
    size: 50,
    type: WidthType.PERCENTAGE,
  },
};

export const documentStyle = {
  paragraphStyles: [
    {
      id: "Heading1",
      name: "Heading 1",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 72, //half-points
        color: colorMap.secondary,
        font: "Montserrat",
        bold: true,
      },
      paragraph: {
        spacing: {
          before: 240, //TWIPs
          after: 480,
        },
        alignment: AlignmentType.CENTER,
      },
    },
    {
      id: "Heading2",
      name: "Heading 2",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 24, //half-points
        color: colorMap.primary,
        font: "Montserrat",
      },
      paragraph: {
        spacing: {
          before: 240,
          after: 480,
        },
        alignment: AlignmentType.CENTER,
      },
    },
    {
      id: "Heading3",
      name: "Heading 3",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 18, //half-points
        color: colorMap.primary,
        font: "Montserrat",
      },
      paragraph: {
        spacing: {
          before: 480,
          after: 240,
        },
      },
    },
    {
      id: "Heading4",
      name: "Heading 4",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 18, //half-points
        color: colorMap.secondary,
        font: "Montserrat",
      },
      paragraph: {
        spacing: {
          before: 360,
          after: 240,
        },
      },
    },
    {
      id: "Normal",
      name: "Normal",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 18, //half-points
        font: "Montserrat",
      },
      paragraph: {
        spacing: {
          after: 120,
        },
      },
    },
  ],
};
