import { useAppState } from "@smartship-services/core/hooks";
import { ContentAndButtonCard } from "./ContentAndButtonCard";
import { OpenBoxIcon } from "../assets";
import { defaultCardSizeClass } from "../lib/consts";

export const EquipmentListCard = ({ className = defaultCardSizeClass }) => {
  const { activeVessel } = useAppState();

  return (
    <ContentAndButtonCard
      accented={true}
      path={
        activeVessel
          ? `/vessels/${activeVessel?.id}/equipment`
          : `/vessels/active?vesselRedirect=equipment`
      }
      title="Equipment List"
    >
      <OpenBoxIcon className={`text-secondary mx-auto ${className}`} />
    </ContentAndButtonCard>
  );
};
