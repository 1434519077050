import { FC, useEffect, useRef } from "react";
import QrScanner from "qr-scanner";

export const QrReader: FC<{ onSuccess: (qrData: string) => void }> = ({
  onSuccess,
}) => {
  const scannerRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!scannerRef.current) {
      return;
    }

    const qrScanner = new QrScanner(
      scannerRef.current,
      (result) => {
        if (result.data) {
          onSuccess(result.data);
        }
      },
      {
        onDecodeError: () => void 0,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      },
    );

    qrScanner.start();

    if (qrScanner.$overlay) {
      // Pulsing square that highlights reading region
      const regionSVG = qrScanner.$overlay.querySelector(
        "svg.scan-region-highlight-svg",
      ) as SVGElement;

      // Small square that highlights QR code
      const codeSVG = qrScanner.$overlay.querySelector(
        "svg.code-outline-highlight",
      ) as SVGElement;

      codeSVG.style.stroke = "oklch(var(--a))"; // accent
      regionSVG.style.stroke = "oklch(var(--p))"; // primary
    }

    return () => {
      qrScanner.destroy();
    };
  }, [onSuccess]);

  return <video ref={scannerRef} />;
};
