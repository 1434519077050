import { ChangeEvent, useState } from "react";
import { Button } from "@mui/material";
import { DynamicForm as DynamicFormIcon } from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";

interface FileUploadFieldProps {
  handler: (arg0: ArrayBuffer) => void;
}

export const FileUploadField = ({ handler }: FileUploadFieldProps) => {
  const [fileHandle, setFileHandle] = useState<File | null>(null);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFileHandle(e.target.files[0]);
    } else {
      setFileHandle(null);
    }
  };

  return (
    <form id="file-upload-form" onSubmit={(e) => e.preventDefault()}>
      <label id="file-upload-label" htmlFor="file-upload-input">
        <span style={visuallyHidden}>Upload file</span>
      </label>
      <input
        accept=".csv,.ods,.rtf,.txt,.xls,.xlsm,.xlsx"
        id="file-upload-input"
        onChange={handleChange}
        type="file"
      />
      {fileHandle && (
        <Button
          onClick={async () => {
            const content = await fileHandle.arrayBuffer();
            handler(content);
          }}
          size="small"
          startIcon={<DynamicFormIcon />}
          sx={{ ml: 2 }}
          variant="contained"
        >
          Process this file
        </Button>
      )}
    </form>
  );
};
