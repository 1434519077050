import { Button, Snackbar, SnackbarContent } from "@mui/material";
import { FC } from "react";
import { useRegisterSW } from "virtual:pwa-register/react";
import { api } from "@smartship-services/core/utils";

export const VersionUpdateToast: FC = () => {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW();

  const update = async () => {
    await api.sync();
    await updateServiceWorker(true);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={needRefresh}
    >
      <SnackbarContent
        message="A new version of the app is available."
        action={<Button onClick={update}>Update</Button>}
        sx={{ background: "white", color: "black" }}
      />
    </Snackbar>
  );
};
