import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getEquipmentByApId } from "@smartship-services/core/hooks";

const QrRedirectPage = () => {
  const { apId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!apId) {
      return;
    }

    getEquipmentByApId(apId)
      .then((equipment) => {
        const { id, vesselId } = equipment || {};
        navigate(`/vessels/${vesselId}/equipment/${id}`);
      })
      .catch((err) => {
        console.error(err);
        navigate("/");
      });
  }, [apId, navigate]);

  return <p>Locating equipment by apId {apId}...</p>;
};

export default QrRedirectPage;
