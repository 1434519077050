import {
  CheckIcon,
  CloudArrowUpIcon,
  CloudIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CloudIcon as CloudSolidIcon } from "@heroicons/react/24/solid";

import { SyncStatus } from "@smartship-services/core";
import { useAppState } from "@smartship-services/core/hooks";

export const CloudSyncButton = () => {
  const { sync, syncStatus } = useAppState();

  let label = "Unknown cloud sync status";
  let color = "text-secondary";
  let icon = <CloudIcon />;

  switch (syncStatus) {
    case SyncStatus.OFF:
      label = "Cloud sync is currently off";
      icon = (
        <div className="relative icon-size-default">
          <CloudIcon className="absolute" />
          <XMarkIcon className="absolute scale-50" />
        </div>
      );
      break;
    case SyncStatus.SYNCING:
      label = "Cloud sync in progress";
      color = "text-primary";
      icon = (
        <div className="relative icon-size-default">
          <CloudIcon className="absolute" />
          <span className="absolute left-0 loading loading-ring"></span>
        </div>
      );
      break;
    case SyncStatus.PENDING:
      label = "Changes are queued for next cloud sync";
      color = "text-primary";
      icon = <CloudArrowUpIcon />;
      break;
    case SyncStatus.ERROR:
      label = "An error occurred during cloud sync";
      color = "text-error";
      icon = <ExclamationTriangleIcon />;
      break;
    case SyncStatus.DONE:
      label = "Cloud sync completed successfully";
      color = "text-primary";
      icon = (
        <div className="icon-size-default relative">
          <CloudSolidIcon className="absolute" />
          <CheckIcon className="absolute scale-50 text-white" />
        </div>
      );
      break;
  }

  return (
    <button
      aria-label="cloud sync"
      onClick={sync}
      title={label}
      className={`icon-size-default ${color}`}
    >
      {icon}
    </button>
  );
};
