import { EquipmentCondition } from "../lib/types";

export const limitScaleProportionally = (
  [x, y]: [number, number],
  max: number,
) => {
  //Used to scale images down so that the longest side will never be over max
  const longest = x > y ? x : y;
  if (longest > max) {
    const scaleReduction = max / longest;
    return [Math.round(x * scaleReduction), Math.round(y * scaleReduction)];
  } else {
    return [x, y];
  }
};

//-----DATE HELPERS-----

type Dateable = Date | number | string;

export const isValidDate = (couldBeDate: unknown): boolean => {
  if (!couldBeDate) {
    return false;
  }

  const validTypes = ["number", "object", "string"];
  if (!validTypes.includes(typeof couldBeDate)) {
    return false;
  }

  const castToDate = new Date(couldBeDate as Dateable);
  if (isNaN(castToDate.getTime())) {
    return false;
  }

  return true;
};

export const justTheDate = (date: Dateable): string =>
  //returns just the YYYY-MM-DD part of any valid date
  new Date(date).toISOString().split("T")[0];

//-----STRING HELPERS-----

export const asString = (couldBeString: unknown): string => {
  //string type coercion
  if (!couldBeString) {
    return "";
  } else {
    return typeof couldBeString === "string"
      ? couldBeString
      : JSON.stringify(couldBeString);
  }
};

export const includesLowercase = (
  string1?: unknown,
  string2?: unknown,
): boolean => {
  //case insensitive version of string.includes() with type coercion
  if (!string1 || !string2) {
    return false;
  }
  return asString(string1)
    .toLowerCase()
    .includes(asString(string2).toLowerCase());
};

export const capitalize = (word: string): string =>
  word.charAt(0).toUpperCase() + word.toLowerCase().slice(1);

//-----ARRAY HELPERS-----

export const hasAllRequired = (
  givenList?: string[],
  requiredList?: string[],
) => {
  if (!requiredList?.length) {
    return false;
  }

  const unmetRequirement = requiredList.find(
    (requiredItem) => !givenList?.includes(requiredItem),
  );
  return unmetRequirement ? false : true;
};

export const pushIfUnique = (arr: string[], value: string) => {
  if (!arr.includes(value)) {
    arr.push(value);
    return true;
  } else {
    return false;
  }
};

interface PullableArray extends Array<string> {
  pull: (arg0: string) => PullableArray;
}

export const makePullable = (arr: Array<string>): PullableArray => {
  //adds method for removing an element from an array
  const pullable = [...arr] as PullableArray;
  pullable.pull = (element) => {
    if (!pullable.includes(element)) {
      return pullable;
    }
    const matchIndex = pullable.indexOf(element);
    pullable.splice(matchIndex, 1);
    return pullable;
  };

  return pullable;
};

interface MightHaveCustom {
  custom?: Array<[string, unknown]>;

  [key: string]: unknown; //unknown for maximum compatibility. EquipmentField if type needed
}

export const compareByKey = (key: string, order?: "asc" | "desc") => {
  //comparator for sorting arrays of objects
  return (first: MightHaveCustom, second: MightHaveCustom) => {
    const a = first[key] || matrixLookup(first.custom, key) || "";
    const b = second[key] || matrixLookup(second.custom, key) || "";

    if (a < b) {
      return order === "desc" ? 1 : -1;
    } else if (a > b) {
      return order === "desc" ? -1 : 1;
    } else {
      return 0;
    }
  };
};

// Lowercase values support old condition data format
const conditionRanking = [
  EquipmentCondition.ALERT,
  EquipmentCondition.FAIL,
  "fail",
  EquipmentCondition.NEEDS_SERVICE,
  "needs service",
  EquipmentCondition.GOOD,
  "good",
  "",
];
export const compareByCondition = (order?: "asc" | "desc") => {
  // Replaces condition with a number so it can be sorted worst to best, with alerts first
  return (first: MightHaveCustom, second: MightHaveCustom) => {
    const a = conditionRanking.indexOf(asString(first.condition));
    const b = conditionRanking.indexOf(asString(second.condition));

    if (a < b) {
      return order === "desc" ? 1 : -1;
    } else if (a > b) {
      return order === "desc" ? -1 : 1;
    } else {
      return 0;
    }
  };
};

export const matrixLookup = (
  matrix: Array<[string, unknown]> | undefined,
  key: string,
) => {
  //gets value by key from list of [key, value] pairs
  const match = matrix?.filter((each: [string, unknown]) => each[0] === key)[0];
  return match ? match[1] : undefined;
};

export const anyOverlap = (arr1: unknown[], arr2: unknown[]): boolean => {
  //immediately returns true if any element found in both arrays
  for (const element of arr1) {
    if (arr2.includes(element)) {
      return true;
    }
  }
  return false;
};

export const uniqueObjectValuesByKey = <U>(
  list: Array<Record<string, unknown>>,
  key: string,
) => {
  //builds list of all unique values for a given key in a list of objects
  const uniqueValues: Array<U> = [];
  list.forEach((each) => {
    if (!each[key]) {
      return;
    } //skip empty/undefined/null values
    if (!uniqueValues.includes(each[key] as U)) {
      uniqueValues.push(each[key] as U);
    }
  });
  return uniqueValues;
};

//TODO: unit tests (esp. edge cases) for each of my helper functions
