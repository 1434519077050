import { EquipmentCondition } from "@smartship-services/core";
import {
  useAppState,
  useEquipmentListByVessel,
} from "@smartship-services/core/hooks";
import { ContentAndButtonCard } from "./ContentAndButtonCard";
import { EquipmentConditionIcon } from "../lib/indicators";
const sizeOfConditionIcons = "h-16 w-16";

export const ConditionSummaryCard = () => {
  const { activeVessel } = useAppState();

  const equipmentList = useEquipmentListByVessel(activeVessel?.id ?? "");

  const alertCount =
    equipmentList?.filter(
      ({ condition }) => condition === EquipmentCondition.ALERT,
    ).length || 0;
  const serviceCount =
    equipmentList?.filter(
      ({ condition }) =>
        condition === EquipmentCondition.NEEDS_SERVICE ||
        condition === "needs service",
    ).length || 0;
  const failCount =
    equipmentList?.filter(
      ({ condition }) =>
        condition === EquipmentCondition.FAIL || condition === "fail",
    ).length || 0;

  const conditionList = [
    {
      condition: EquipmentCondition.ALERT,
      label: "ALERTED",
      count: alertCount,
    },
    {
      condition: EquipmentCondition.NEEDS_SERVICE,
      label: "SERVICE",
      count: serviceCount,
    },
    {
      condition: EquipmentCondition.FAIL,
      label: "FAILED",
      count: failCount,
    },
  ];

  return (
    <ContentAndButtonCard
      title="See Complete List"
      path={
        activeVessel
          ? `/vessels/${activeVessel?.id}/equipment`
          : `/vessels/active?vesselRedirect=equipment`
      }
      accented={true}
    >
      <div className="grid grid-cols-3 p-5 place-items-center montserrat">
        {conditionList.map(({ condition, label, count }) => (
          <div key={condition} className="relative text-center">
            <div className="grid place-items-center">
              <EquipmentConditionIcon
                sizeClass={sizeOfConditionIcons}
                condition={condition}
              />
            </div>
            <span className="text-secondary text-xs font-bold">{label}</span>
            <div className="badge badge-secondary absolute top-0 right-0">
              {count}
            </div>
          </div>
        ))}
      </div>
    </ContentAndButtonCard>
  );
};
