import { Link } from "react-router-dom";

import { EquipmentRecord } from "@smartship-services/core";
import { EquipmentConditionIcon } from "../lib/indicators";

interface EquipmentCardProps {
  equipment: Partial<EquipmentRecord>;
}
export const EquipmentCard = ({ equipment }: EquipmentCardProps) => (
  <Link
    to={`/vessels/${equipment.vesselId}/equipment/${equipment.id || "missing"}`}
  >
    <div className="flex justify-between items-center px-2 py-1 border border-neutral hover:bg-neutral">
      <div className="flex flex-col montserrat text-secondary text-sm">
        <span className="font-bold uppercase">
          {equipment?.equipmentName || <em>No equipment name</em>}
        </span>
        <span>{equipment?.makerName || <em>n/a</em>}</span>
        <span>{equipment?.location || <em>n/a</em>}</span>
      </div>
      <div className="shrink-0">
        <EquipmentConditionIcon
          condition={equipment?.condition}
          sizeClass="h-9 w-9"
        />
      </div>
    </div>
  </Link>
);
