import { makeNameHumanReadable } from "../../lib/equipmentKeyDescriptions";

export const equipmentKeysToDisplay = {
  //condition handled separately
  idFromCustomer: makeNameHumanReadable("idFromCustomer"),
  installationDate: makeNameHumanReadable("installationDate"),
  //location handled separately
  makerName: makeNameHumanReadable("makerName"),
  model: makeNameHumanReadable("model"),
  //name handled separately
  serialNumber: makeNameHumanReadable("serialNumber"),
  freeComment: "Comment",
};

export const workOrderKeysToDisplay = {
  customerName: "CUSTOMER",
  vesselName: "SHIP NAME",
  onboardContactName: "CONTACT PERSON ON BOARD",
  plannedStartDate: "DATE OF INTERVENTION",
  portOfVisit: "PORT OF VISIT", //custom field
  serviceEngineers: "SERVICE ENGINEER",
};

export const purposeText = [
  "This report describes the inspection carried out on board on your Galleys equipment.",
  "The inspection includes visual checks, electrical measurements, and functional tests of the equipment.",
  "The aim is to provide you a list of maintenances and spare parts your equipment may require to be operated securely and efficiently.",
];

export const classificationText = [
  "The components or equipment are classified in 3 categories which summarize their condition:",
];
export const classificationBullets = [
  [
    "Good condition",
    "Item capable of numerous operations, only regular maintenance required",
  ],
  ["Needs Service", "Corrective maintenance and regular maintenance required"],
  ["Fail", "Equipment in poor condition, replacement recommended"],
];

export const endingText = [
  "We hope to serve you well with this report and we look forward to hearing from you.",
  "Sincerely,",
  "____________________",
];

export const footerText =
  "Finland   |   France   |   USA   |   Italy   |   China   |   Germany   |   Singapore   |   Brazil";
