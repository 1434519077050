import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

import {
  EquipmentRecord,
  makeNameHumanReadable,
} from "@smartship-services/core";
import {
  useAuthentication,
  useEquipmentListByVessel,
} from "@smartship-services/core/hooks";
import { EquipmentCard, PageTitleWithOptionalButtons } from "../layout";
import { SearchBox } from "../forms";

// List of filterable fields to include in SearchBox
const filterList = [
  {
    id: "location",
    label: makeNameHumanReadable("location"),
    includeMultiselect: true,
  },
];

// Removes extra fields so that full text search won't include them
const removeExtraFields = (equipment: EquipmentRecord) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { activity, activityRequests, fileMetadata, ...rest } = equipment;
  return rest;
};

const CreateEquipmentButton = () => (
  <Link to="create">
    <PlusCircleIcon className="icon-size-default" title="Create equipment" />
  </Link>
);

export const EquipmentList = ({ vesselId }: { vesselId: string }) => {
  const rawEquipmentList = useEquipmentListByVessel(vesselId);
  const equipmentList = useMemo(
    () => rawEquipmentList?.map(removeExtraFields) || [],
    [rawEquipmentList],
  );

  const [filteredSortedEquipment, setFilteredSortedEquipment] = useState<
    EquipmentRecord[]
  >([]);
  const batchSize = 20;
  const [displayedLength, setDisplayedLength] = useState(batchSize);

  const { currentUser } = useAuthentication();
  const canModify = currentUser?.permissions?.includes("mains:vessels:write");
  const defaultSort = canModify ? "condition" : "equipmentName";

  const displayMore = () => {
    setDisplayedLength((prev) => prev + batchSize);
  };

  return (
    <main className="m-auto max-w-4xl">
      <div className="mb-2 -mx-2">
        <PageTitleWithOptionalButtons
          title="Equipment List"
          OptionalRightSideButton={
            canModify ? <CreateEquipmentButton /> : undefined
          }
        />
      </div>
      <SearchBox
        defaultSortBy={defaultSort}
        filterableFields={filterList}
        list={equipmentList}
        setList={setFilteredSortedEquipment}
      />
      <div className="flex flex-col mt-2 accent-top">
        {filteredSortedEquipment.length ? (
          <>
            {filteredSortedEquipment
              .slice(0, displayedLength)
              .map((equipment) => (
                <EquipmentCard key={equipment.id} equipment={equipment} />
              ))}
            {displayedLength < filteredSortedEquipment.length ? (
              <button
                className="btn btn-primary mt-2 w-full md:w-96 md:mx-auto"
                onClick={displayMore}
              >
                Load More
              </button>
            ) : null}
          </>
        ) : (
          <div className="p-2 text-center border border-neutral">
            {!vesselId || vesselId === "undefined"
              ? "No vessel selected"
              : "No results"}
          </div>
        )}
      </div>
    </main>
  );
};
