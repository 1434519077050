import { useAuthentication } from "@smartship-services/core/hooks";

const MicrosoftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23px"
    height="23px"
    viewBox="0 0 23 23"
  >
    <path fill="#2f2f2f" d="M0 0h23v23H0z" />
    <path fill="#f35325" d="M1 1h10v10H1z" />
    <path fill="#81bc06" d="M12 1h10v10H12z" />
    <path fill="#05a6f0" d="M1 12h10v10H1z" />
    <path fill="#ffba08" d="M12 12h10v10H12z" />
  </svg>
);

export const MicrosoftLoginButton = () => {
  const { msLogin } = useAuthentication();

  return (
    <div>
      <button className="btn btn-secondary bg-[#2f2f2f]" onClick={msLogin}>
        <MicrosoftIcon />
        <span className="normal-case text-base">Sign in with Microsoft</span>
      </button>
    </div>
  );
};
