import CreateSpreadsheet from "./CreateSpreadsheet";
import CreateWorkOrderReport from "./CreateWorkOrderReport";
import { FC } from "react";

const Reports: FC = () => {
  return (
    <main>
      <h1>Reports</h1>
      <CreateSpreadsheet />
      <CreateWorkOrderReport />
    </main>
  );
};

export default Reports;
