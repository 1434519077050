import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { extractApid, QrReaderDrawer } from "./index";

// TODO: refactor, this should be a component, and not hook
export const useQrReader = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (openState: boolean) => {
    setIsDrawerOpen(openState);
  };

  const handleQrResult = (result: string) => {
    if (!result) {
      return;
    }

    navigate("/qr-redirect/" + extractApid(result));
  };

  return {
    openScanner: () => toggleDrawer(true),
    QrDrawer: () => (
      <QrReaderDrawer
        closeDrawer={() => toggleDrawer(false)}
        isOpen={isDrawerOpen}
        resultCallback={handleQrResult}
      />
    ),
  };
};
