import { useEffect, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import {
  Checkbox,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import type {
  EquipmentConditions,
  EquipmentRecord,
} from "@smartship-services/core";
import {
  anyOverlap,
  asString,
  compareByKey,
  includesLowercase,
  isValidDate,
  justTheDate,
  matrixLookup,
} from "@smartship-services/core/utils";
import { EquipmentTableHead } from "./TableHead";
import { TrafficLight } from "@smartship-services/ui";

interface EquipmentListProps {
  columnNames: string[];
  isSelected?: (arg0: string) => boolean;
  list?: EquipmentRecord[];
  toggleSelect?: (arg0: string) => void;
}

interface EquipmentRowProps {
  columnNames: string[];
  equipment: EquipmentRecord;
  isSelected?: (arg0: string) => boolean;
  toggleSelect?: (arg0: string) => void;
}

const EquipmentRow = ({
  columnNames,
  equipment,
  isSelected,
  toggleSelect,
}: EquipmentRowProps) => {
  return (
    <TableRow hover>
      {columnNames.map((columnName, index) => {
        const value =
          equipment[columnName] ||
          matrixLookup(equipment.custom, columnName) ||
          "";
        let content: JSX.Element;

        switch (columnName) {
          case "checkboxPlaceholder":
            content =
              isSelected && toggleSelect ? (
                <Checkbox
                  checked={isSelected(equipment.id)}
                  onChange={() => toggleSelect(equipment.id)}
                />
              ) : (
                <span id="placeholder"></span>
              );
            break;
          case "condition":
            content = (
              <TrafficLight
                condition={equipment.condition as EquipmentConditions}
              />
            );
            break;
          case "equipmentName":
            content = (
              <Typography>
                <Link
                  component={RouterLink}
                  to={`/vessels/${equipment.vesselId}/equipment/${equipment.id}`}
                  underline="hover"
                >
                  {equipment.equipmentName || "Open detailed view"}
                </Link>
              </Typography>
            );
            break;
          case "description":
            content = (
              <Typography variant="caption">
                {asString(equipment.description)}
              </Typography>
            );
            break;
          default:
            if (
              columnName.toLowerCase().includes("date") &&
              isValidDate(value)
            ) {
              content = (
                <Typography noWrap>🗓 {justTheDate(value as Date)}</Typography>
              );
            } else {
              content = <Typography>{asString(value)}</Typography>;
            }
        }

        return <TableCell key={columnName + index}>{content}</TableCell>;
      })}
    </TableRow>
  );
};

export const WorkOrderEquipmentList = ({
  columnNames,
  isSelected,
  list,
  toggleSelect,
}: EquipmentListProps) => {
  const [searchParams] = useSearchParams();
  const [filteredList, setFilteredList] = useState<EquipmentRecord[]>([]);
  //TODO: add grouping to filter and sort options
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);

    if (!list || list.length === 0 || columnNames.length === 0) {
      setFilteredList([]);
      return;
    }

    const params = Array.from(searchParams);

    //filtering
    let filtered: EquipmentRecord[] = [];
    if (
      anyOverlap(
        columnNames,
        params.map(([key]) => key),
      )
    ) {
      list.forEach((equipment) => {
        for (const [key, value] of params) {
          if (!columnNames.includes(key)) {
            continue; //skips param with invalid name
          }

          if (!equipment[key]) {
            //if equipment missing key, check custom list for key
            const customValue = matrixLookup(equipment.custom, key);
            if (!customValue) {
              return;
            }
            if (!includesLowercase(customValue, value)) {
              return;
            }
          } else {
            if (!includesLowercase(equipment[key], value)) {
              return;
            }
          }

          //equipment passed tests, continue to next param
        }
        //equipment passed tests for all params
        filtered.push(equipment);
      });
    } else {
      filtered = list;
    }

    //sorting
    let order = searchParams.get("order") as "asc" | "desc";
    if (!order) {
      order = "asc";
    }
    let sortBy = searchParams.get("sortBy");
    if (!sortBy) {
      sortBy = "equipmentName";
    }

    const sortedList = filtered.sort(compareByKey(sortBy, order));
    setFilteredList(sortedList); //displays final result
  }, [columnNames, list, searchParams]);

  return (
    <TableContainer sx={{ mb: 2, mt: 2 }}>
      <Table size="small">
        <EquipmentTableHead columnNames={columnNames} />
        <TableBody>
          {filteredList && rowsPerPage > 0 ? (
            filteredList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <EquipmentRow
                  columnNames={columnNames}
                  equipment={row}
                  isSelected={isSelected}
                  key={row.id}
                  toggleSelect={toggleSelect}
                />
              ))
          ) : (
            <TableRow key="empty-equipment">
              <TableCell>No equipment found</TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell></TableCell>
            <TablePagination
              rowsPerPageOptions={[10, 50, 100]}
              colSpan={3}
              count={filteredList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
