import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <span>
      The requested page was not found. Click your browser's Back button to go
      back, or{" "}
      <Link className="link link-primary" to="/">
        return to the homepage
      </Link>
      .
    </span>
  );
};

export default NotFound;
