export interface IdAndLabel {
  id: string;
  label: string;
}

export interface HasHandler {
  handler: () => void;
}

export interface Suggestions {
  [key: string]: string[];
}

export enum SyncStatus {
  OFF = "OFF", // offline mode
  SYNCING = "SYNCING", // sync in progress
  PENDING = "PENDING", // pending changes to upload
  DONE = "DONE", // sync finished with no errors
  ERROR = "ERROR", // sync aborted or finished with an error
  UNKNOWN = "UNKNOWN", // default
}

export type WorkOrderCategories =
  | "inventory"
  | "inspection"
  | "maintenance"
  | "other";

export type WorkOrderStatuses =
  | "planned"
  | "active"
  | "visit done"
  | "review done"
  | "archived";

//type used in the past
export type EquipmentConditions = "good" | "needs service" | "fail";

//enum to use going forward
export enum EquipmentCondition {
  ALERT = "ALERT",
  GOOD = "GOOD",
  NEEDS_SERVICE = "NEEDS_SERVICE",
  FAIL = "FAIL",
}

type ChecklistField = string | boolean | null | string[];

interface ActivityBody {
  equipmentFields?: Partial<EquipmentRecord>;
  checklistFields?: Record<string, ChecklistField>;
}

export enum RequestStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum EquipmentActivityStatus {
  PLANNED = "PLANNED",
  COMPLETE = "COMPLETE",
  PARTIALLY_COMPLETE = "PARTIALLY_COMPLETE",
  UNABLE_TO_COMPLETE = "UNABLE_TO_COMPLETE",
  CANCELLED = "CANCELLED",
}

export interface WorkOrderSummary {
  apIdCount: number;
  failEquipment: EquipmentRecord[];
  goodEquipment: EquipmentRecord[];
  needsServiceEquipment: EquipmentRecord[];
  otherEquipment: EquipmentRecord[];
  outOfScopeEquipmentIds: string[];
  photoIds: string[];
  totalCount: number;
}

//offline database types

interface DefaultRecord {
  id: string;
  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface EquipmentActivityRecord extends DefaultRecord {
  body: ActivityBody;
  checklistId: string;
  equipmentId: string;
  status: EquipmentActivityStatus;
  vesselId: string;
  workOrderId?: string;
}

export interface ActivityRequest extends EquipmentActivityRecord {
  requestStatus: RequestStatus;
}

export type EquipmentField = string | Date | boolean | null | unknown[];

export interface EquipmentRecord extends DefaultRecord {
  activity?: EquipmentActivityRecord[];
  activityRequests?: ActivityRequest[];
  almacoSerialNumber?: string | null;
  apId?: string | null;
  condition?: EquipmentCondition | EquipmentConditions | null;
  custom: Array<[string, EquipmentField]>;
  equipmentName: string;
  fileMetadata?: FileMetadataRecord[];
  freeComment?: string;
  idFromCustomer?: string;
  installationDate?: Date | null;
  location?: string;
  locationId?: string;
  locationIdFromCustomer?: string;
  makerName?: string;
  model?: string;
  removed?: boolean;
  serialNumber?: string;
  vesselId: string;
  warrantyEnds?: Date;
  ymc?: string;

  [key: string]: EquipmentField | undefined;
}

export interface FileMetadataRecord {
  id: string;
  equipmentId?: string; //optional for backwards compatibility
  filename: string;
  fileHandle?: File; //local files
  s3location?: string; //cloud files
}

export interface LocationRecord extends DefaultRecord {
  vesselId: string;
  locationName: string;
  idFromCustomer?: string;
  parentId?: string;
}

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";
export type QueueStatus = "pending" | "retry" | "rejected" | "file pending";

/*
pending: request is waiting to be sent
failed: sent but got network error, okay to retry
rejected: sent and got API error, need user to resolve
file pending: metadata has been uploaded but the file itself has not
*/
export interface QueueRecord extends DefaultRecord {
  table: string; //table name where the record belongs
  recordId: string; //id of record that was changed
  method: HttpMethod;
  path: string; //api path that should be called
  timestamp: Date; //date that this queue record was made
  status: QueueStatus;
  userId?: string; //id of user if they were already logged in
  workOrderId?: string; //id of associated work order if applicable
  errorMessage?: string; //error response from API if already rejected
  record?: Record<string, unknown>; //actual record in question for rejected requests
}

export interface VesselRecord extends DefaultRecord {
  customColumnNames: string[];
  standardColumnNames: string[];
  vesselName: string;
  wpVesselId?: number;
}

export interface WorkOrderRecord extends DefaultRecord {
  actualStartDate?: Date;
  categories?: WorkOrderCategories[];
  customerName: string;
  equipmentIds: string[];
  equipmentDetailList?: EquipmentRecord[];
  freeComment?: string;
  onboardContactName?: string;
  plannedStartDate: Date | null;
  serviceEngineers: string[];
  summary?: WorkOrderSummary;
  status?: WorkOrderStatuses;
  vesselId: string;
  vesselName?: string;

  [key: string]: EquipmentField | WorkOrderSummary | undefined;
}
