export const oceanBlue = {
  20: "#CDECF8",
  40: "#A0DAF7",
  60: "#179DD9",
  80: "#0079A6",
  100: "#0B4E6C",
};

export const fireZone = {
  60: "#Df6426",
};

export const funnel = {
  20: "#F2F2F2",
  40: "#DBDCDC",
  60: "#A3AAAD",
  80: "#758086",
  100: "#505759",
};

export const seaweed = {
  60: "#6AA654",
};
