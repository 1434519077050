import { useEffect, useState } from "react";

import { SyncStatus } from "../lib/types";
import { subscribe, unsubscribe } from "../utils/events";
import { api } from "../utils/api";

export const OFFLINE_MODE_STORAGE_KEY = "offlineMode";
const SYNC_INTERVAL = 300000; // 5 minutes

export interface CloudSyncProperties {
  clearSyncError: () => void;
  isOfflineMode: boolean;
  sync: () => void;
  syncError: string;
  syncStatus: SyncStatus;
  toggleOfflineMode: () => void;
}

export const useCloudSync = (): CloudSyncProperties => {
  const [syncStatus, setSyncStatus] = useState<SyncStatus>(SyncStatus.UNKNOWN);
  const [syncError, setSyncError] = useState("");
  const [isOfflineMode, setIsOfflineMode] = useState(false);

  const clearSyncError = () => {
    setSyncError("");
  };

  const sync = async () => {
    if (syncStatus === SyncStatus.SYNCING) {
      // Sync already in progress
      return;
    }

    clearSyncError();
    api.sync();
  };

  const toggleOfflineMode = () => {
    if (isOfflineMode) {
      //Switch to online mode
      sessionStorage.setItem(OFFLINE_MODE_STORAGE_KEY, "online");
      setIsOfflineMode(false);
      setSyncStatus(SyncStatus.UNKNOWN);
      sync();
    } else {
      //Switch to offline mode
      sessionStorage.setItem(OFFLINE_MODE_STORAGE_KEY, "offline");
      setIsOfflineMode(true);
      setSyncStatus(SyncStatus.OFF);
    }
  };

  useEffect(() => {
    const offlineMode = sessionStorage.getItem(OFFLINE_MODE_STORAGE_KEY);
    if (offlineMode === "offline") {
      setIsOfflineMode(true);
      setSyncStatus(SyncStatus.OFF);
    }

    const handleSyncErrorEvent = ((e: CustomEvent) => {
      if (e.detail) {
        setSyncError(e.detail);
      } else {
        setSyncError("Unknown sync error");
      }
    }) as EventListener;

    const handleSyncStatusEvent = ((e: CustomEvent) => {
      const newStatus = e.detail in SyncStatus ? e.detail : SyncStatus.UNKNOWN;
      setSyncStatus(newStatus);
    }) as EventListener;

    subscribe("syncError", handleSyncErrorEvent);
    subscribe("syncStatusChange", handleSyncStatusEvent);

    const automaticSync = setInterval(() => api.sync(), SYNC_INTERVAL);

    return () => {
      clearInterval(automaticSync);

      unsubscribe("syncError", handleSyncErrorEvent);
      unsubscribe("syncStatusChange", handleSyncStatusEvent);
    };
  }, []);

  return {
    clearSyncError,
    isOfflineMode,
    sync,
    syncError,
    syncStatus,
    toggleOfflineMode,
  };
};
