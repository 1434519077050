import { InstallPWAToast } from "@smartship-services/ui/toasts";
import { useAuthentication } from "@smartship-services/core/hooks";
import { EquipmentUserView, OnboardTechnicianView } from "./Views";

const Home = () => {
  const { currentUser } = useAuthentication();

  // Permissions for roles still being refined
  const isEquipmentUser = !currentUser?.permissions.includes(
    "mains:vessels:write",
  );

  return (
    <main className="m-auto max-w-4xl">
      {isEquipmentUser ? <EquipmentUserView /> : <OnboardTechnicianView />}
      <InstallPWAToast />
    </main>
  );
};

export default Home;
