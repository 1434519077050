import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  BellAlertIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

import {
  EquipmentCondition,
  mapConditionToVisuals,
} from "@smartship-services/core";
import { writeActivity } from "@smartship-services/core/hooks";
import { PageTitleWithOptionalButtons } from "../../layout";

interface ConditionFormValues {
  comment: string;
  condition: EquipmentCondition | "";
}

export const EquipmentAlertForm = () => {
  const { equipmentId = "", vesselId = "" } = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async ({ comment, condition }: ConditionFormValues) => {
    setErrorMessage("");
    try {
      await writeActivity("condition", {
        equipmentId,
        vesselId,
        body: {
          equipmentFields: {
            condition: condition || null,
          },
          checklistFields: {
            comment,
          },
        },
      });

      setIsSubmitted(true);
    } catch (err) {
      console.error(err);
      setErrorMessage("Error updating equipment condition");
    }
  };

  return (
    <main className="m-auto max-w-6xl bg-neutral accent-top montserrat">
      <PageTitleWithOptionalButtons title="" />
      {equipmentId && vesselId ? (
        <div className="p-3 pb-5 flex flex-col items-center">
          {isSubmitted ? (
            <div className="flex flex-col items-center gap-4 mt-8 text-secondary font-bold uppercase mb-5">
              <BellAlertIcon className="icon-size-large text-secondary" />
              <p>Thanks!</p>
              <p>Your alert has been sent</p>
              <Link to="/" className="btn btn-primary w-full">
                Go to home
              </Link>
            </div>
          ) : (
            <Formik
              initialValues={{
                condition: "",
                comment: "",
              }}
              validationSchema={Yup.object({
                condition: Yup.string().required("Please select condition"),
              })}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form className="flex flex-col items-center w-full max-w-sm gap-2">
                  <BellAlertIcon className="h-12 w-12 text-secondary" />
                  <span className="text-secondary font-bold uppercase">
                    Fill in the form
                  </span>
                  <Field
                    as="select"
                    id="condition"
                    name="condition"
                    className="select select-bordered border-funnel40 shadow-md w-full"
                  >
                    <option value="">Select condition</option>
                    <option
                      value={EquipmentCondition.NEEDS_SERVICE}
                      key="NEEDS_SERVICE"
                    >
                      {
                        mapConditionToVisuals(EquipmentCondition.NEEDS_SERVICE)
                          .label
                      }
                    </option>
                    <option value={EquipmentCondition.FAIL} key="FAIL">
                      {mapConditionToVisuals(EquipmentCondition.FAIL).label}
                    </option>
                  </Field>
                  {touched.condition && errors.condition ? (
                    <div className="text-error text-sm mt-1 p-1">
                      {errors.condition}
                    </div>
                  ) : null}
                  <Field
                    as="textarea"
                    id="comment"
                    name="comment"
                    className="textarea textarea-bordered border-funnel40 shadow-md w-full"
                    placeholder="Comment..."
                  />
                  <button type="submit" className="btn btn-primary w-full mt-2">
                    Alert technician
                  </button>
                  {errorMessage ? (
                    <div className="alert alert-error">
                      <ExclamationCircleIcon className="icon-size-default" />
                      {errorMessage}
                    </div>
                  ) : null}
                </Form>
              )}
            </Formik>
          )}
        </div>
      ) : (
        <div className="p-5">
          <p>Missing equipment ID and/or vessel ID</p>
        </div>
      )}
    </main>
  );
};
