import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Fab, IconButton, Tooltip } from "@mui/material";
import {
  DeleteForever as DeleteIcon,
  Edit as EditIcon,
  FilterAltOff as FilterAltOffIcon,
  HelpOutline as HelpOutlineIcon,
  PlayCircleOutlined as StartIcon,
  RestartAlt as ResetIcon,
  Save as SaveIcon,
  SaveAs as SaveAsIcon,
  Search as SearchIcon,
} from "@mui/icons-material";

import { HasHandler } from "@smartship-services/core";

export const HelpButton = ({ content }: { content: ReactNode | string }) => (
  <Tooltip
    arrow={true}
    title={content}
    enterTouchDelay={0}
    leaveTouchDelay={5000}
  >
    <IconButton aria-label="help">
      <HelpOutlineIcon />
    </IconButton>
  </Tooltip>
);

export const ClearFilterButton = ({ handler }: HasHandler) => (
  <Tooltip
    arrow={true}
    title="Clear filters"
    enterTouchDelay={0}
    leaveTouchDelay={5000}
  >
    <IconButton aria-label="clear filters" onClick={handler}>
      <FilterAltOffIcon />
    </IconButton>
  </Tooltip>
);

export const ResetButton = ({ handler }: HasHandler) => (
  <Button
    color="warning"
    onClick={handler}
    startIcon={<ResetIcon />}
    variant="outlined"
  >
    Reset form
  </Button>
);

export const SaveButton = ({ handler }: HasHandler) => (
  <Button onClick={handler} startIcon={<SaveIcon />} variant="contained">
    Save
  </Button>
);

export const SaveAndCreateAnotherButton = ({ handler }: HasHandler) => (
  <Button onClick={handler} startIcon={<SaveAsIcon />} variant="contained">
    Save and create another
  </Button>
);

interface DeleteButtonProps extends HasHandler {
  label?: string;
}

export const DeleteButton = ({ handler, label }: DeleteButtonProps) => {
  const confirmDelete = () => {
    const isSure = window.confirm(
      "This cannot be undone. Are you sure you want to delete this record?",
    );
    if (isSure) {
      handler();
    }
  };
  return (
    <Button
      color="error"
      onClick={confirmDelete}
      startIcon={<DeleteIcon />}
      variant="outlined"
    >
      Delete {label || ""}
    </Button>
  );
};

export const SetActiveWorkOrderButton = ({ handler }: HasHandler) => (
  <Button
    color="success"
    onClick={handler}
    startIcon={<StartIcon />}
    variant="outlined"
  >
    Start work order (set active)
  </Button>
);

interface EditAndSaveFabProps {
  editHandler: () => void;
  isActive: boolean;
  resetHandler: () => void;
  saveHandler: () => void;
}

export const EditAndSaveFab = ({
  editHandler,
  isActive,
  resetHandler,
  saveHandler,
}: EditAndSaveFabProps) => (
  <Box sx={{ "& > :not(style)": { m: 1 } }}>
    <Fab
      color="primary"
      onClick={isActive ? saveHandler : editHandler}
      style={{ position: "fixed", bottom: 24, right: 16 }}
      variant={isActive ? "extended" : "circular"}
    >
      {isActive ? <SaveIcon sx={{ mr: 1 }} /> : <EditIcon />}
      {isActive ? "Save changes" : null}
    </Fab>
    {isActive ? (
      <Fab
        color="warning"
        onClick={resetHandler}
        style={{ position: "fixed", bottom: 90, right: 16 }}
        variant="extended"
      >
        <ResetIcon sx={{ mr: 1 }} />
        Reset changes
      </Fab>
    ) : null}
  </Box>
);

export const SearchButton = ({ path }: { path: string }) => (
  <IconButton component={RouterLink} to={path}>
    <SearchIcon />
  </IconButton>
);
