import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { type IPublicClientApplication } from "@azure/msal-browser";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { Header, AppBar } from "@smartship-services/ui/layout";
import {
  RouterBreadcrumbs,
  bottomSpaceForAppBar,
} from "@smartship-services/ui";
import { VersionUpdateToast } from "@smartship-services/ui/toasts";

import {
  AppStateProvider,
  AuthenticationProvider,
} from "@smartship-services/core/hooks";

import CreateEquipment from "./pages/CreateEquipment";
import CreateWorkOrder from "./pages/CreateWorkOrder";
import Equipment from "./pages/Equipment";
import EquipmentAlert from "./pages/EquipmentAlert";
import EquipmentCondition from "./pages/EquipmentCondition";
import EquipmentEdit from "./pages/EquipmentEdit";
import EquipmentList from "./pages/EquipmentList";
import EquipmentRedirect from "./pages/EquipmentRedirect";
import Home from "./pages/Home";
import ImportEquipment from "./pages/ImportEquipment";
import NotFound from "./pages/NotFound";
import QrRedirect from "./pages/QrRedirect";
import Reports from "./pages/Reports";
import QueuedRequest from "./pages/QueuedRequest";
import Settings from "./pages/Settings";
import SelectActiveVessel from "./pages/SelectActiveVessel";
import SyncQueue from "./pages/SyncQueue";
import Vessel from "./pages/Vessel";
import VesselList from "./pages/VesselList";
import WorkOrder from "./pages/WorkOrder";
import WorkOrderList from "./pages/WorkOrderList";

import defaultTheme from "./theme";
import ImportVessel from "./pages/ImportVessel";
import LandingPage from "./pages/LandingPage";

const pagesConfig = [
  { path: "/", page: <Home /> },
  { path: "/auth/signin", page: <Navigate to="/" replace={true} /> },
  { path: "/sync-queue", page: <SyncQueue /> },
  { path: "/sync-queue/:requestId", page: <QueuedRequest /> },
  { path: "/reports", page: <Reports /> },
  { path: "/qr-redirect/:apId", page: <QrRedirect /> },
  { path: "/equipment/:id", page: <EquipmentRedirect /> },
  { path: "/work-orders", page: <WorkOrderList /> },
  { path: "/work-orders/create", page: <CreateWorkOrder /> },
  { path: "/settings", page: <Settings /> },
  { path: "/work-orders/:workOrderId", page: <WorkOrder /> },
  { path: "/vessels", page: <VesselList /> },
  { path: "/vessels/active", page: <SelectActiveVessel /> },
  { path: "/vessels/import", page: <ImportVessel /> },
  { path: "/vessels/:vesselId/equipment", page: <EquipmentList /> },
  { path: "/vessels/:vesselId/equipment/create", page: <CreateEquipment /> },
  { path: "/vessels/:vesselId/equipment/import", page: <ImportEquipment /> },
  { path: "/vessels/:vesselId/equipment/:equipmentId", page: <Equipment /> },
  {
    path: "/vessels/:vesselId/equipment/:equipmentId/alert",
    page: <EquipmentAlert />,
  },
  {
    path: "/vessels/:vesselId/equipment/:equipmentId/condition",
    page: <EquipmentCondition />,
  },
  {
    path: "/vessels/:vesselId/equipment/:equipmentId/edit",
    page: <EquipmentEdit />,
  },
  { path: "/vessels/:vesselId", page: <Vessel /> },
  { path: "*", page: <NotFound /> },
];

interface AppProps {
  msal: IPublicClientApplication;
}

const App = ({ msal }: AppProps) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <MsalProvider instance={msal}>
        <AuthenticationProvider UnauthorizedPage={LandingPage}>
          <AppStateProvider>
            <Router>
              <Header />
              <div className={`mx-auto max-w-6xl p-4 ${bottomSpaceForAppBar}`}>
                <RouterBreadcrumbs />
                <Routes>
                  {pagesConfig.map(({ path, page }) => (
                    <Route key={path} path={path} element={page} />
                  ))}
                </Routes>
              </div>
              <AppBar />
            </Router>
          </AppStateProvider>
        </AuthenticationProvider>
        <VersionUpdateToast />
      </MsalProvider>
    </ThemeProvider>
  );
};

export default App;
