import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Breadcrumbs,
  Link as MuiLink,
  LinkProps,
  Typography,
} from "@mui/material";

import { VesselRecord } from "@smartship-services/core";
import { useDataList } from "@smartship-services/core/hooks";
import { capitalize } from "@smartship-services/core/utils";

interface BreadcrumbLinkProps extends LinkProps {
  to: string;
}
const BreadcrumbLink = (props: BreadcrumbLinkProps) => {
  return <MuiLink {...props} underline="hover" component={RouterLink as any} />; //eslint-disable-line
};

export const RouterBreadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const vessels = useDataList<VesselRecord>("vessels");

  const formatBreadcrumbName = (path: string) =>
    path.split("-").map(capitalize).join(" ");

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <BreadcrumbLink to="/">Home</BreadcrumbLink>
      {pathnames.map((path, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const previous = pathnames[index - 1];
        let label: string | undefined = formatBreadcrumbName(path);

        if (previous === "vessels") {
          const vessel = vessels?.filter((each) => each.id === path)[0];
          label = vessel?.vesselName || path;
        }

        return last ? (
          <Typography key={to}>{label}</Typography>
        ) : (
          <BreadcrumbLink to={to} key={to}>
            {label}
          </BreadcrumbLink>
        );
      })}
    </Breadcrumbs>
  );
};
