import { Typography, Box, Alert, Button, Link } from "@mui/material";
import { ManageSearch as ManageSearchIcon } from "@mui/icons-material";
import { VesselRecord } from "@smartship-services/core";
import {
  useData,
  useLocationListByVessel,
} from "@smartship-services/core/hooks";
import { Link as RouterLink } from "react-router-dom";
import { AccentedAccordion } from "../../layout/AccentedAccordion";

export const VesselDisplay = ({ vesselId }: { vesselId: string }) => {
  const vessel = useData<VesselRecord>("vessels", vesselId);
  const locations = useLocationListByVessel(vesselId);

  return vessel ? (
    <main>
      <Typography variant="h1">{vessel.vesselName}</Typography>
      <Box sx={{ p: 1, mb: 1 }}>
        {vesselId.includes("local") ? (
          <Alert severity="info">
            <strong>Local record: changes will not sync</strong>
            <br />
            Changes to this vessel and its equipment will appear in the sync
            queue as a demonstration, but sync will ignore them.
          </Alert>
        ) : null}
        {/* TODO: vessel info will go here */}
      </Box>
      <AccentedAccordion title="Visit History">
        <Typography>
          <em>In development: display past work orders here</em>
        </Typography>
      </AccentedAccordion>
      <Typography variant="h2">
        <span
          style={{ lineHeight: "2em" /* for button gap on small screens */ }}
        >
          Equipment by category
        </span>
        <Button
          variant="outlined"
          component={RouterLink}
          to="equipment"
          startIcon={<ManageSearchIcon />}
          sx={{ ml: 2 /* for button gap on large screens */ }}
        >
          View full equipment list
        </Button>
      </Typography>
      <Typography variant="h5">Locations</Typography>
      {locations ? (
        <ul>
          {locations.map((location) => (
            <li key={location.id}>
              <Link
                component={RouterLink}
                to={"equipment?location=" + location.locationName}
              >
                {location.locationName}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </main>
  ) : (
    <main>
      <Typography>No vessel found with this ID.</Typography>
    </main>
  );
};
