import { VesselRecord } from "@smartship-services/core";
import { SelectShip } from "..";
import { PageTitleWithOptionalButtons } from "../../layout";

interface Params {
  handler: (result: string[]) => void;
  vessels: VesselRecord[] | undefined;
}
export const SelectVesselForm = ({ handler, vessels }: Params) => {
  return (
    <main className="mx-auto max-w-2xl accent-top bg-neutral">
      <PageTitleWithOptionalButtons
        title="Select Ship"
        showBackButton={false}
      />
      <div className="p-4">
        <SelectShip
          onSubmit={handler}
          canSelectMultiple={false}
          vessels={vessels}
        />
      </div>
    </main>
  );
};
