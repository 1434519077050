import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { defaultCardSizeClass } from "../lib/consts";
import { ContentAndButtonCard } from "./ContentAndButtonCard";

export const ReportsCard = ({ className = defaultCardSizeClass }) => (
  <ContentAndButtonCard accented={true} path="/reports" title="Reports">
    <DocumentTextIcon
      className={`text-secondary mx-auto [&>path]:stroke-[1.2] ${className}`}
    />
  </ContentAndButtonCard>
);
