import {
  EquipmentRecord,
  makeNameHumanReadable,
} from "@smartship-services/core";
import { asString, justTheDate } from "@smartship-services/core/utils";

const displayedFields = [
  "makerName",
  "model",
  "location",
  "serialNumber",
  "almacoSerialNumber",
  "apId",
  "idFromCustomer",
  "installationDate",
  "warrantyEnds",
  "ymc",
];

export const EquipmentDetails = ({
  equipment,
}: {
  equipment: EquipmentRecord;
}) => (
  <>
    {displayedFields
      .filter((field) =>
        !equipment[field] || equipment[field] === "null" ? false : true,
      ) //don't show empty fields
      .map((field) => {
        const value = equipment[field];
        let content: string | React.ReactNode =
          typeof value === "string" ? value : JSON.stringify(value);
        if (field === "installationDate" || field === "warrantyEnds") {
          content = justTheDate(value as Date);
        }

        return (
          <p key={field}>
            {makeNameHumanReadable(field)}: {content}
          </p>
        );
      })}
    {equipment.custom?.length > 0
      ? equipment.custom
          .filter(([, value]) => (!value || value === "null" ? false : true)) //don't show empty fields
          .map(([key, value]) => (
            <p key={key}>
              ☆ {key}: {asString(value)}
            </p>
          ))
      : null}
    {equipment.freeComment ? (
      <div key="freeComment">
        <p>{makeNameHumanReadable("freeComment")}:</p>
        <p style={{ marginTop: 0, whiteSpace: "pre-line" }}>
          {equipment.freeComment}
        </p>
      </div>
    ) : null}
  </>
);
