import { FC, useState } from "react";
import {
  DocumentPlusIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { api } from "@smartship-services/core/utils";
import {
  createAndDownloadSpreadsheet,
  getEquipmentListByVessel,
} from "@smartship-services/core/hooks";
import { EquipmentField } from "@smartship-services/core";
import { SelectVessel } from "@smartship-services/ui/forms";

const CreateSpreadsheet: FC = () => {
  const [activeVessel, setActiveVessel] = useState<string | null>(null);
  const [tableErrorMessage, setTableErrorMessage] = useState(""); //equipment table
  const [tableLoadingStatus, setTableLoadingStatus] = useState(false);

  const generateEquipmentSheet = async () => {
    if (!activeVessel) {
      setTableErrorMessage("No vessel selected");
      return;
    }

    setTableErrorMessage("");
    setTableLoadingStatus(true);
    await api.sync();
    try {
      const equipmentList = await getEquipmentListByVessel(activeVessel);
      if (equipmentList.length === 0) {
        setTableErrorMessage("No equipment found for this vessel");
      }

      //expand custom into own columns
      const equipmentWithCustomList = equipmentList.map((equipment) => {
        const customSection: Record<string, EquipmentField | undefined> = {};
        equipment.custom.forEach(([key, value]) => {
          customSection[key] = value;
        });

        return {
          ...equipment,
          ...customSection,
        };
      });

      //remove unnecessary fields
      const condensedEquipmentList = equipmentWithCustomList.map(
        (equipment) => {
          const condensedEquipment = { ...equipment };
          const unneededFields = [
            "custom",
            "customFieldKeys",
            "customFieldValues",
            "name",
            "photoIds",
            "photoFilenames",
            "vesselId",
          ]; //most are leftovers from backend db
          unneededFields.forEach((field) => delete condensedEquipment[field]);
          return condensedEquipment;
        },
      );

      createAndDownloadSpreadsheet(condensedEquipmentList, "equipment-list");
    } catch (err) {
      console.error(err);
      const { message } = err as Error;
      setTableErrorMessage(message || "Error generating table");
    }

    setTableLoadingStatus(false);
  };

  return (
    <>
      <h2>Equipment table for export</h2>
      {tableErrorMessage ? (
        <div role="alert" className="alert alert-error mb-1 mt-1 max-w-sm">
          <ExclamationCircleIcon className="icon-size-default" />
          {tableErrorMessage}
        </div>
      ) : null}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-6 max-w-3xl">
        <SelectVessel
          setter={(value) => {
            setActiveVessel(value);
          }}
        />
        <span className="self-center">Select a vessel</span>
        <div>
          <button
            disabled={!activeVessel}
            onClick={generateEquipmentSheet}
            className="btn btn-primary btn-outline mr-1"
          >
            {tableLoadingStatus ? (
              <span className="loading loading-spinner loading-sm"></span>
            ) : (
              <DocumentPlusIcon className="icon-size-default" />
            )}
            Create spreadsheet
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateSpreadsheet;
