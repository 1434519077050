import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

import { IdAndLabel, VesselRecord } from "@smartship-services/core";
import { useDataList } from "@smartship-services/core/hooks";

interface SelectVesselProps {
  setter: (arg0: string | null) => void;
}

export const SelectVessel = ({ setter }: SelectVesselProps) => {
  const [activeVessel, setActiveVessel] = useState<IdAndLabel | null>(null);
  const vessels = useDataList<VesselRecord>("vessels");

  useEffect(() => {
    const sessionVessel = sessionStorage.getItem("activeVessel");
    if (sessionVessel) {
      const parsedSessionVessel = JSON.parse(sessionVessel);
      setActiveVessel(parsedSessionVessel);
      setter(parsedSessionVessel.id);
    }
  }, [setter]);

  return (
    <Autocomplete
      blurOnSelect
      id="vessel-select"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        setActiveVessel(newValue);
        if (newValue) {
          setter(newValue.id);
          sessionStorage.setItem("activeVessel", JSON.stringify(newValue));
        } else {
          setter(null);
          sessionStorage.removeItem("activeVessel");
        }
      }}
      options={
        vessels
          ? vessels.map(({ id, vesselName }) => ({ id, label: vesselName }))
          : []
      }
      renderInput={(params) => <TextField {...params} label="Vessel" />}
      value={activeVessel}
    />
  );
};
