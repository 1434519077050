import { getTokenFromStorage } from "../hooks/useAuthentication";
import { publish } from "./events";

const API_URL = process.env.REACT_APP_API_URL || "";
if (!API_URL) {
  console.error("No API URL found; cannot sync with backend");
}

export interface NetworkError extends Error {
  statusCode: number;
}
const createNetworkError = (statusCode: number, message: string) => {
  const error = new Error(message) as NetworkError;
  error.statusCode = statusCode;
  return error;
};

const recognizeGatewayErrorStatus = (status: number, message: string) => {
  if (status === 500 && message.includes("Error: Unauthorised")) {
    return message.includes("scope") ? 403 : 401;
  } else {
    return status;
  }
};

export interface FetchConfig {
  method?: string;
  body?:
    | FormData
    | Record<string, string | number | boolean | null | undefined>;
  headers?: { [key: string]: string };
  auth?: boolean;
}
export const fetchWrapper = async (
  endpoint: string,
  fetchConfig: FetchConfig = {},
) => {
  const { method, body, auth = true, ...customConfig } = fetchConfig;
  const config: RequestInit = {
    method: method ?? "GET",
    ...customConfig,
    headers: { ...customConfig.headers },
  };

  if (auth) {
    try {
      const token = getTokenFromStorage();
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`,
      };
    } catch (err) {
      console.error(err);
    }
  }

  if (body) {
    if (body instanceof FormData) {
      config.body = body;
      // Allow browser to automatically set content-type and boundaries
    } else {
      config.body = JSON.stringify(body);
      config.headers = {
        ...config.headers,
        "content-type": "application/json",
      };
    }
  }

  return fetch(API_URL + endpoint, config).then(async (response) => {
    if (response.ok) {
      const isImage = response.headers.get("content-type")?.includes("image");
      if (isImage) {
        return response.blob();
      } else {
        return response.json();
      }
    } else {
      let errorMessage = await response.text();
      const status = recognizeGatewayErrorStatus(response.status, errorMessage);
      if (status === 403) {
        errorMessage = "Missing one or more required permissions";
      }
      return Promise.reject(createNetworkError(status, errorMessage));
    }
  });
};

export const apiClient = async (endpoint: string, config: FetchConfig = {}) =>
  // Adds prefix for mains-specific requests
  fetchWrapper("/rest/mains" + endpoint, config).catch((err) => {
    if (err.statusCode === 401) {
      // Triggers auth handler when token is no longer valid
      publish("authProblem", "attempt to reauthenticate");
    }
    throw err;
  });
