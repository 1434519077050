import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  Deselect as SelectNoneIcon,
  SelectAll as SelectAllIcon,
} from "@mui/icons-material";

import { EquipmentRecord } from "@smartship-services/core";
import { getEquipmentListByVessel } from "@smartship-services/core/hooks";
import {
  includesLowercase,
  matrixLookup,
} from "@smartship-services/core/utils";
import { WorkOrderEquipmentList } from "../EquipmentList";

const defaultColumnNames = [
  "checkboxPlaceholder",
  "equipmentName",
  "location",
  "makerName",
  "model",
  "condition",
  "apId",
];

const filterListWithParams = (
  params: Array<[string, string]>,
  list: EquipmentRecord[],
) => {
  const filtered: EquipmentRecord[] = [];
  list.forEach((equipment) => {
    for (const [key, value] of params) {
      if (!equipment[key]) {
        //if equipment missing key, check custom list for key
        const customValue = matrixLookup(equipment.custom, key);
        if (!customValue) {
          return;
        }
        if (!includesLowercase(customValue, value)) {
          return;
        }
      } else {
        if (!includesLowercase(equipment[key], value)) {
          return;
        }
      }

      //equipment passed tests, continue to next param
    }
    //equipment passed tests for all params
    filtered.push(equipment);
  });

  return filtered;
};

interface SelectableEquipmentListProps {
  columnNames?: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  selectedIds: string[];
  showAll?: boolean;
  vesselId: string;
}

export const SelectableEquipmentList = ({
  columnNames,
  selectedIds,
  setSelectedIds,
  showAll = true,
  vesselId,
}: SelectableEquipmentListProps) => {
  const [searchParams] = useSearchParams();
  const [isShowingAll, setIsShowingAll] = useState(showAll);
  const [selectAllOrNone, setSelectAllOrNone] = useState<"all" | "none" | null>(
    null,
  );
  const [vesselEquipment, setVesselEquipment] = useState<EquipmentRecord[]>([]);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentRecord[]>(
    [],
  );
  const equipmentColumnNames = columnNames ? columnNames : defaultColumnNames;

  const showSelectedHandler = (e: MouseEvent, newValue: boolean | null) => {
    if (newValue !== null) {
      updateSelectedEquipment();
      setIsShowingAll(newValue);
    }
  };

  const selectAllHandler = (e: MouseEvent, newValue: "all" | "none" | null) => {
    setSelectAllOrNone(newValue);
    if (newValue === "all") {
      const params = Array.from(searchParams);
      if (params.length === 0) {
        //no filters
        const allIds: string[] = vesselEquipment.map(({ id }) => id);
        setSelectedIds(allIds);
        setSelectedEquipment(vesselEquipment);
      } else {
        //all with filters applied
        const filteredEquipment = filterListWithParams(params, vesselEquipment);
        const filteredIds: string[] = filteredEquipment.map(({ id }) => id);
        setSelectedIds(filteredIds);
        setSelectedEquipment(filteredEquipment);
      }
    } else if (newValue === "none") {
      setSelectedIds([]);
      setSelectedEquipment([]);
    }
  };

  const checkIfSelected = (id: string) => selectedIds.includes(id);

  const toggleSelect = (id: string) => {
    setSelectAllOrNone(null);
    setSelectedIds((previousIds) => {
      const selectedIndex = previousIds.indexOf(id);
      const updatedSelected = [...previousIds];

      if (selectedIndex === -1) {
        updatedSelected.push(id);
      } else {
        updatedSelected.splice(selectedIndex, 1);
      }

      return updatedSelected;
    });
  };

  const updateSelectedEquipment = () => {
    setSelectedEquipment(
      vesselEquipment.filter(({ id }) => selectedIds.includes(id)),
    );
  };

  useEffect(() => {
    updateSelectedEquipment();
  }, [selectedIds, vesselEquipment]);

  useEffect(() => {
    setVesselEquipment([]);
    setSelectedEquipment([]);
    if (!vesselId) {
      return;
    }

    getEquipmentListByVessel(vesselId)
      .then((results) => {
        if (!results) {
          return;
        }
        setVesselEquipment(results);
        updateSelectedEquipment();
      })
      .catch((err) => {
        console.error(err);
      });
  }, [vesselId]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
        <ToggleButtonGroup
          aria-label="show selected items or all"
          exclusive
          onChange={showSelectedHandler}
          value={isShowingAll}
        >
          <ToggleButton color="primary" value={false}>
            Show selected ({selectedIds.length})
          </ToggleButton>
          <ToggleButton color="primary" value={true}>
            Show all ({vesselEquipment.length})
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          aria-label="select all or none"
          exclusive
          onChange={selectAllHandler}
          value={selectAllOrNone}
        >
          <ToggleButton color="primary" title="select all" value="all">
            <SelectAllIcon />
          </ToggleButton>
          <ToggleButton color="primary" title="select none" value="none">
            <SelectNoneIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <WorkOrderEquipmentList
        columnNames={equipmentColumnNames}
        isSelected={checkIfSelected}
        list={isShowingAll ? vesselEquipment : selectedEquipment}
        toggleSelect={toggleSelect}
      />
    </>
  );
};
