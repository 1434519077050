import { useNavigate, useSearchParams } from "react-router-dom";

import { VesselRecord } from "@smartship-services/core";
import { useAppState, useDataList } from "@smartship-services/core/hooks";
import { SelectVesselForm } from "@smartship-services/ui/forms";

const SelectActiveVesselPage = () => {
  const navigate = useNavigate();
  const vessels = useDataList<VesselRecord>("vessels");
  const [searchParams] = useSearchParams();
  const { changeVessel } = useAppState();

  const redirect = searchParams.get("redirect");
  const vesselRedirect = searchParams.get("vesselRedirect");

  const handler = (result: string[]) => {
    changeVessel(result[0]);

    if (redirect) {
      navigate(redirect);
    } else if (vesselRedirect) {
      navigate(`/vessels/${result[0]}/${vesselRedirect}`);
    } else {
      navigate("/");
    }
  };

  return <SelectVesselForm handler={handler} vessels={vessels} />;
};

export default SelectActiveVesselPage;
