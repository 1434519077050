import { Table } from "dexie";
import { useLiveQuery } from "dexie-react-hooks";
import { v4 as uuid } from "uuid";

import { db, MaintenanceDB } from "../lib/db";
import {
  EquipmentActivityRecord,
  EquipmentActivityStatus,
  EquipmentRecord,
  FileMetadataRecord,
  HttpMethod,
  LocationRecord,
  QueueRecord,
  SyncStatus,
} from "../lib/types";
import { getUserFromStorage } from "./useAuthentication";
import { publish } from "../utils/events";
import qrToSerial from "../assets/qr_to_serial.json";

// TODO: move to constants
const ACTIVE_WORK_ORDER_STORAGE_KEY = "activeWorkOrder";

type QrKey = keyof typeof qrToSerial;
type HasId = { id: string };

const mapToPath = (method: HttpMethod, table: string, recordId: string) => {
  const pathMap: Record<string, string> = {
    activityRequests: "activity-requests",
    equipmentActivity: "equipment-activity",
    fileMetadata: "file-metadata",
    workOrders: "work-orders",
  };
  const idMap = method === "POST" ? "" : `/${recordId}`;

  return `/${pathMap[table] || table}` + idMap;
};

/* ----- READ-ONLY METHODS ----- */

export function getSerialFromQr(apId?: string | null) {
  return qrToSerial[apId as QrKey] || "";
}

/* -- live queries -- */

export function useData<R>(
  storeName: keyof MaintenanceDB,
  id: string,
): R | undefined {
  const dbStore = db[storeName] as Table<R>;
  return useLiveQuery(() => dbStore.where("id").equals(id).first(), [id]);
}

export function useDataList<R>(
  storeName: keyof MaintenanceDB,
): R[] | undefined {
  const dbStore = db[storeName] as Table<R>;
  return useLiveQuery(() => dbStore.toArray());
}

export function useLocationListByVessel(
  vesselId: string,
): LocationRecord[] | undefined {
  return useLiveQuery(
    () =>
      db.locations.where("vesselId").equals(vesselId).sortBy("locationName"),
    [vesselId],
  );
}

export function useEquipmentListByVessel(
  vesselId: string,
): EquipmentRecord[] | undefined {
  return useLiveQuery(
    () => db.equipment.where("vesselId").equals(vesselId).toArray(),
    [vesselId],
  );
}

export function useQueueList(): QueueRecord[] | undefined {
  return useLiveQuery(() =>
    db.queue.toCollection().reverse().sortBy("timestamp"),
  );
}

/* -- normal promises -- */

export function getTableCount(storeName: keyof MaintenanceDB) {
  const dbStore = db[storeName] as Table;
  return dbStore.count();
}

export function getById<R>(storeName: keyof MaintenanceDB, id: string) {
  const dbStore = db[storeName] as Table<R>;
  return dbStore.where("id").equals(id).first();
}

export function getVesselById(vesselId: string) {
  return db.vessels.where("id").equals(vesselId).first();
}

export function getEquipmentListByVessel(vesselId: string) {
  return db.equipment.where("vesselId").equals(vesselId).toArray();
}

export function getEquipmentListByIds(ids: string[]) {
  return db.equipment.bulkGet(ids);
}

export function getFileListByIds(ids: string[]) {
  return db.fileMetadata.bulkGet(ids);
}

export function getFileListByEquipment(equipmentId: string) {
  return db.fileMetadata.where("equipmentId").equals(equipmentId).toArray();
}

export function getEquipmentByApId(apId: string) {
  return db.equipment.where("apId").equals(apId).first();
}

export function getWorkOrderListByVessel(vesselId: string) {
  return db.workOrders
    .where("vesselId")
    .equals(vesselId)
    .reverse()
    .sortBy("plannedStartDate");
}

export function getQueueList() {
  return db.queue.toCollection().sortBy("timestamp");
}

export function getQueueListByRecordId(recordId: string) {
  return db.queue.where("recordId").equals(recordId).sortBy("timestamp");
}

/* ----- WRITE METHODS ----- */

//for sync to use without getting queued
export async function putDataList<R>(
  storeName: keyof MaintenanceDB,
  list: R[],
) {
  try {
    const dbStore = db[storeName] as Table<R>;
    const result = await dbStore.bulkPut(list);
    return result;
  } catch (err) {
    throw new Error("Error in bulk put operation");
  }
}

export async function putFileMetadataList(list: FileMetadataRecord[]) {
  /*
    Similar to putDataList but prevents loss of fileHandle for files
    that have not yet been uploaded
  */
  try {
    for (const remoteMetadata of list) {
      const hasAlreadyBeenUploaded = remoteMetadata.s3location;
      if (hasAlreadyBeenUploaded) {
        await db.fileMetadata.put(remoteMetadata);
        continue;
      }

      const isAlreadyInDb = await db.fileMetadata
        .where("id")
        .equals(remoteMetadata.id)
        .count();
      if (!isAlreadyInDb) {
        await db.fileMetadata.add(remoteMetadata);
      }
    }
  } catch (err) {
    throw new Error("Error in bulk file metadata operation");
  }
}

async function addToQueue(
  method: HttpMethod,
  table: keyof MaintenanceDB | string,
  recordId: string,
  record?: Record<string, unknown>,
) {
  const { id: userId } = getUserFromStorage();

  const sessionWorkOrder = sessionStorage.getItem(
    ACTIVE_WORK_ORDER_STORAGE_KEY,
  );
  const workOrderId = sessionWorkOrder
    ? JSON.parse(sessionWorkOrder).id
    : undefined;

  const draftQueueRecord: QueueRecord = {
    id: uuid(),
    method,
    path: mapToPath(method, table, recordId),
    record,
    recordId,
    status: "pending",
    table,
    timestamp: new Date(),
    userId,
    workOrderId,
  };

  try {
    await db.queue.add(draftQueueRecord);
    publish("syncStatusChange", SyncStatus.PENDING);
  } catch (err) {
    console.error(err);
  }
}

async function deleteFileListByEquipment(equipmentId: string) {
  //Deletes file metadata locally when equipment is deleted
  //No need to sync, because backend will also handle this itself
  try {
    await db.fileMetadata.where("equipmentId").equals(equipmentId).delete();
  } catch (err) {
    console.error(err);
  }
}

/* ----- WRITE METHODS THAT GET QUEUED ----- */

export async function modifyData<R>(
  storeName: keyof MaintenanceDB,
  id: string,
  data: Record<string, unknown>,
) {
  try {
    const dbStore = db[storeName] as Table<R>;
    const result = await dbStore.update(id, data);
    addToQueue("PUT", storeName, id, data);
    return result;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function writeData<R>(storeName: keyof MaintenanceDB, data: R) {
  const { id } = data as HasId;
  try {
    const dbStore = db[storeName] as Table<R>;
    const result = await dbStore.add(data);
    addToQueue("POST", storeName, id, data as Record<string, unknown>);
    return result;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function writeDataList<R>(
  storeName: keyof MaintenanceDB,
  list: R[],
) {
  try {
    const dbStore = db[storeName] as Table<R>;
    const result = await dbStore.bulkAdd(list);
    list.forEach((record) => {
      const { id } = record as HasId;
      addToQueue("POST", storeName, id, record as Record<string, unknown>);
    });
    return result;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function deleteData<R>(
  storeName: keyof MaintenanceDB,
  id: string,
  skipQueue = false,
) {
  try {
    const dbStore = db[storeName] as Table<R>;
    const result = await dbStore.delete(id);
    if (storeName === "equipment") {
      await deleteFileListByEquipment(id);
    }

    if (storeName !== "queue" && !skipQueue) {
      addToQueue("DELETE", storeName, id);
    }
    return result;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

const checklistIds = {
  condition: "d41a9bf2-f67f-45fa-856b-0b737a8cab41",
  removeEquipment: "d40751cb-98f9-4c18-8a7a-882d3df058b3",
  replaceEquipment: "e4b59a3d-0e9a-4977-9c32-a8b7be23c1a2",
};
type ChecklistNames = keyof typeof checklistIds;

export function getChecklistId(label: ChecklistNames) {
  return checklistIds[label];
}

export async function writeActivity(
  checklistName: ChecklistNames,
  {
    body = {},
    equipmentId,
    status = EquipmentActivityStatus.COMPLETE,
    vesselId,
    workOrderId,
  }: Partial<EquipmentActivityRecord>,
  canChangeWithoutApproval = false,
) {
  if (!equipmentId || !vesselId) {
    throw new Error("Equipment and vessel must be specified");
  }

  const activityDraft = {
    id: uuid(),
    body,
    checklistId: getChecklistId(checklistName),
    createdAt: new Date(),
    equipmentId,
    status,
    vesselId,
    workOrderId,
  };

  const tableName = canChangeWithoutApproval
    ? "equipmentActivity"
    : "activityRequests";

  // Queue new activity for sync
  await addToQueue("POST", tableName, activityDraft.id, activityDraft);

  if (canChangeWithoutApproval) {
    // Update local copy of equipment in the meantime so user can see changes
    const equipment = await db.equipment.get(equipmentId);
    const activity = equipment?.activity || [];
    activity.unshift(activityDraft);
    await db.equipment.update(equipmentId, {
      ...body.equipmentFields,
      activity,
    });
  }

  return activityDraft;
}
