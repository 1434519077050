import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

import {
  IdAndLabel,
  VesselRecord,
  WorkOrderRecord,
  ACTIVE_WORK_ORDER_STORAGE_KEY,
} from "@smartship-services/core";
import { useDataList } from "@smartship-services/core/hooks";
import { justTheDate } from "@smartship-services/core/utils";

interface IdAndLabelWithVesselId extends IdAndLabel {
  vesselId: string;
}

export const readableWorkOrder = (
  {
    customerName,
    id,
    categories,
    plannedStartDate,
    serviceEngineers,
    vesselId,
  }: WorkOrderRecord,
  vessels?: VesselRecord[],
) => {
  const plannedDate = plannedStartDate ? justTheDate(plannedStartDate) : "";
  const vesselName =
    vessels?.filter((each) => each?.id === vesselId)[0]?.vesselName || "";
  const allFields = [
    plannedDate,
    vesselName,
    customerName,
    categories?.join(", "),
    serviceEngineers.join(", "),
  ].filter((x) => x);
  if (allFields.length === 0) {
    return id;
  } else {
    return allFields.join(" - ");
  }
};

interface SelectWorkOrderProps {
  isUsingSession?: boolean;
  setter: (arg0: string | null) => void;
  vesselId?: string | null;
}

export const SelectWorkOrder = ({
  isUsingSession,
  setter,
  vesselId,
}: SelectWorkOrderProps) => {
  const [activeWorkOrder, setActiveWorkOrder] =
    useState<IdAndLabelWithVesselId | null>(null);
  const workOrders = useDataList<WorkOrderRecord>("workOrders");
  const vessels = useDataList<VesselRecord>("vessels");

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: IdAndLabelWithVesselId | null,
  ) => {
    setActiveWorkOrder(newValue);
    if (newValue) {
      setter(newValue.id);
      if (isUsingSession) {
        sessionStorage.setItem(
          ACTIVE_WORK_ORDER_STORAGE_KEY,
          JSON.stringify(newValue),
        );
      }
    } else {
      setter(null);
      if (isUsingSession) {
        sessionStorage.removeItem(ACTIVE_WORK_ORDER_STORAGE_KEY);
      }
    }
  };

  const generateOptions = () => {
    if (!workOrders?.length) {
      return [];
    }

    return workOrders
      .filter((workOrder) =>
        vesselId ? workOrder.vesselId === vesselId : true,
      )
      .map((workOrder) => ({
        id: workOrder.id,
        label: readableWorkOrder(workOrder, vessels) || workOrder.id,
        vesselId: workOrder.vesselId,
      }));
  };

  useEffect(() => {
    setActiveWorkOrder(null);
  }, [vesselId]);

  useEffect(() => {
    if (!isUsingSession) {
      return;
    }

    const sessionWorkOrder = sessionStorage.getItem(
      ACTIVE_WORK_ORDER_STORAGE_KEY,
    );
    if (sessionWorkOrder) {
      const parsedSessionWorkOrder = JSON.parse(sessionWorkOrder);
      if (vesselId && vesselId !== parsedSessionWorkOrder.vesselId) {
        //Don't keep session work order if it's for a different vessel
        sessionStorage.removeItem(ACTIVE_WORK_ORDER_STORAGE_KEY);
        setter(null);
      } else {
        setActiveWorkOrder(parsedSessionWorkOrder);
        setter(parsedSessionWorkOrder.id);
      }
    }
  }, [isUsingSession, setter, vesselId]);

  return (
    <Autocomplete
      blurOnSelect
      fullWidth
      id="work-order-select"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleChange}
      options={generateOptions()}
      renderInput={(params) => <TextField {...params} label="Work Order" />}
      value={activeWorkOrder}
    />
  );
};
