import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

import {
  EquipmentCondition,
  mapConditionToVisuals,
} from "@smartship-services/core";
import { writeActivity } from "@smartship-services/core/hooks";
import { PageTitleWithOptionalButtons } from "@smartship-services/ui/layout";

const conditionOptions = [
  EquipmentCondition.GOOD,
  EquipmentCondition.NEEDS_SERVICE,
  EquipmentCondition.FAIL,
  "N/A (Not applicable)",
];

interface ConditionFormValues {
  comment: string;
  condition: EquipmentCondition | null | "N/A (Not applicable)";
}

const EquipmentConditionPage = () => {
  const navigate = useNavigate();
  const { equipmentId = "", vesselId = "" } = useParams();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async ({ comment, condition }: ConditionFormValues) => {
    setErrorMessage("");

    if (condition === "N/A (Not applicable)") {
      condition = null;
    }

    try {
      await writeActivity(
        "condition",
        {
          equipmentId,
          vesselId,
          body: {
            equipmentFields: {
              condition,
            },
            checklistFields: {
              comment,
            },
          },
        },
        true,
      );
      navigate(-1);
    } catch (err) {
      console.error(err);
      setErrorMessage("Error updating equipment condition");
    }
  };

  return (
    <main className="m-auto max-w-6xl bg-neutral accent-top">
      <PageTitleWithOptionalButtons title="Change Condition" />
      {equipmentId && vesselId ? (
        <div className="p-3 pb-5 flex flex-col items-center montserrat">
          <Formik
            initialValues={{
              condition: null,
              comment: "",
            }}
            validationSchema={Yup.object({
              condition: Yup.string().required("Please select condition"),
            })}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="flex flex-col items-center w-full max-w-sm gap-2">
                <Field
                  as="select"
                  id="condition"
                  name="condition"
                  className="select select-bordered border-funnel40 shadow-md w-full"
                >
                  <option value="">Select condition</option>
                  {conditionOptions.map((option) => (
                    <option key={option} value={option}>
                      {option in EquipmentCondition
                        ? mapConditionToVisuals(option as EquipmentCondition)
                            .label
                        : option}
                    </option>
                  ))}
                </Field>
                {touched.condition && errors.condition ? (
                  <div className="text-error text-sm mt-1 p-1">
                    {errors.condition}
                  </div>
                ) : null}
                <Field
                  as="textarea"
                  id="comment"
                  name="comment"
                  className="textarea textarea-bordered border-funnel40 shadow-md w-full"
                  placeholder="Comment..."
                  rows={5}
                />
                <button type="submit" className="btn btn-primary w-full mt-2">
                  Save
                </button>
                {errorMessage ? (
                  <div className="alert alert-error">
                    <ExclamationCircleIcon className="icon-size-default" />
                    {errorMessage}
                  </div>
                ) : null}
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="p-5">
          <p>Missing equipment ID and/or vessel ID</p>
        </div>
      )}
    </main>
  );
};

export default EquipmentConditionPage;
