import {
  BellAlertIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

import { EquipmentCondition, EquipmentRecord } from "@smartship-services/core";

interface EquipmentConditionIconProps {
  sizeClass?: string;
  condition?: EquipmentRecord["condition"];
}

export const EquipmentConditionIcon = ({
  sizeClass = "icon-size-default",
  condition,
}: EquipmentConditionIconProps) => {
  // Lowercase values support old condition data format
  switch (condition) {
    case EquipmentCondition.ALERT:
      return (
        <BellAlertIcon className={`text-error ${sizeClass}`} title="Alert" />
      );
    case "good":
    //fallthrough
    case EquipmentCondition.GOOD:
      return (
        <CheckCircleIcon className={`text-success ${sizeClass}`} title="Good" />
      );
    case "needs service":
    //fallthrough
    case EquipmentCondition.NEEDS_SERVICE:
      return (
        <ExclamationTriangleIcon
          className={`text-warning ${sizeClass}`}
          title="Needs Service"
        />
      );
    case "fail":
    //fallthrough
    case EquipmentCondition.FAIL:
      return (
        <ExclamationCircleIcon
          className={`text-error ${sizeClass}`}
          title="Fail"
        />
      );
    default:
      return (
        <QuestionMarkCircleIcon
          className={`text-secondary ${sizeClass}`}
          title="No condition provided"
        />
      );
  }
};
