import { useEffect, useState } from "react";
import { read, utils, writeFileXLSX } from "xlsx";

type matrix = string[][];

export const useSpreadsheetParser = () => {
  const [originalFile, setOriginalFile] = useState<ArrayBuffer | null>(null);
  const [parsedFile, setParsedFile] = useState<matrix | null>(null);

  useEffect(() => {
    if (originalFile) {
      const readOptions = {
        cellDates: true,
        cellText: false,
      };
      const writeOptions = {
        blankrows: false,
        defval: null,
        header: 1,
      };

      const workbook = read(originalFile, readOptions);
      const firstSheet = workbook.SheetNames[0];
      const data = utils.sheet_to_json(
        workbook.Sheets[firstSheet],
        writeOptions,
      ) as matrix;

      setParsedFile(data);
    } else {
      setParsedFile(null);
    }
  }, [originalFile]);

  return [parsedFile, setOriginalFile] as const;
};

export const createAndDownloadSpreadsheet = <T>(
  list: Array<T>,
  filenameWithoutExtension = "workbook",
) => {
  const worksheet = utils.json_to_sheet(list);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Data");
  writeFileXLSX(workbook, filenameWithoutExtension + ".xlsx");
  return workbook;
};
