import { type ChangeEvent, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { makePullable } from "@smartship-services/core/utils";

interface SelectShipProps {
  canSelectMultiple?: boolean;
  onSubmit: (arg0: string[]) => void;
  submitButtonLabel?: string;
  vessels?: Array<{
    id: string;
    vesselName: string;
  }>;
}

export const SelectShip = ({
  canSelectMultiple,
  onSubmit,
  submitButtonLabel = "Select",
  vessels = [],
}: SelectShipProps) => {
  const [searchInput, setSearchInput] = useState("");
  const filteredVessels = vessels.filter(({ vesselName }) =>
    vesselName.toLowerCase().includes(searchInput),
  );
  const [selected, setSelected] = useState<string[]>([]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const vesselId = e.target.id.split("_")[0];
    const isChecked = e.target.checked;
    if (canSelectMultiple) {
      if (isChecked) {
        setSelected((prev) => [...prev, vesselId]);
      } else {
        setSelected(makePullable(selected).pull(vesselId));
      }
    } else {
      setSelected(isChecked ? [vesselId] : []);
      return;
    }
  };

  const handleSubmit = () => {
    onSubmit(selected);
  };

  return (
    <div>
      <div className="collapse collapse-arrow">
        <input type="checkbox" />
        <div className="collapse-title border border-funnel40 bg-white shadow">
          <div className="text-secondary">Select</div>
        </div>
        <div
          className="collapse-content p-0 bg-white shadow"
          style={{ paddingBottom: 0 }}
        >
          {vessels.length > 5 ? (
            <label htmlFor="vessel-select-search" className="relative">
              <input
                id="vessel-select-search"
                name="vessel-select-search"
                type="text"
                placeholder="Search..."
                onChange={handleSearch}
                className="input w-full input-bordered border-funnel40 -mt-[1px]"
              />
              <span className="absolute inset-y-0 right-0 pr-2 flex items-center">
                <MagnifyingGlassIcon className="icon-size-default text-secondary" />
              </span>
            </label>
          ) : null}
          {vessels ? (
            filteredVessels.map(({ id, vesselName }) => (
              <div
                key={id}
                className="form-control w-full border border-funnel40 -mt-[1px]"
              >
                <label className="label cursor-pointer justify-start gap-4 p-3">
                  <input
                    id={`${id}_vessel-select`}
                    type="checkbox"
                    checked={selected.includes(id)}
                    onChange={handleSelect}
                    className="checkbox checkbox-sm border-secondary [--chkbg:white] [--chkfg:theme(colors.secondary)]"
                  />
                  <span className="label-text">{vesselName}</span>
                </label>
              </div>
            ))
          ) : (
            <div className="text-center p-2 bg-white">No vessels available</div>
          )}
        </div>
      </div>
      <button
        onClick={handleSubmit}
        disabled={!selected.length}
        className="btn btn-primary w-full mt-4"
      >
        {submitButtonLabel}
      </button>
    </div>
  );
};
