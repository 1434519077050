import { FC } from "react";
import { FallbackProps } from "react-error-boundary";
import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";

const CONTACT_EMAIL = "dev@almaco.cc";

export const ErrorPage: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const emailSubject = `Error at ${window.location.href}`;
  const emailBody = `
---
${error.stack || error}
`;

  return (
    <div className={"h-screen flex"}>
      <div
        className={"m-auto flex items-center gap-1 flex-col p-2 text-center"}
      >
        <WrenchScrewdriverIcon className={"w-12 h-12"} />
        <span className={"text-xl"}>Something went wrong</span>
        <p className={"mb-2"}>
          Please try again. If error persists, contact us at{" "}
          <a
            className={"link"}
            href={`mailto:${CONTACT_EMAIL}?subject=${emailSubject}&body=${encodeURIComponent(
              emailBody,
            )}`}
          >
            {CONTACT_EMAIL}
          </a>
        </p>
        <div className={"flex gap-2"}>
          <button className={"btn"} onClick={resetErrorBoundary}>
            Retry
          </button>
          <a href={"/"} className={"btn"}>
            Go to homepage
          </a>
        </div>
      </div>
    </div>
  );
};
