import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
} from "@mui/icons-material";

import { makeNamesHumanReadable } from "@smartship-services/core";
import { ClearFilterButton } from "@smartship-services/ui/buttons";
import { FilterField } from "@smartship-services/ui/forms";

interface EquipmentTableHeadProps {
  columnNames: string[];
}

export const EquipmentTableHead = ({
  columnNames,
}: EquipmentTableHeadProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [readableColumns, setReadableColumns] = useState<string[] | undefined>(
    undefined,
  );

  const changeSearchParam = (param: string, newValue: string | null) => {
    if (!searchParams.get(param) && !newValue) {
      return;
    } //value is empty anyway
    if (searchParams.get(param) === newValue) {
      return;
    } //value didn't really change

    setSearchParams((params) => {
      if (newValue) {
        params.set(param, newValue);
      } else {
        params.delete(param);
      }
      return params;
    });
  };

  const clearSearchParams = () => {
    setSearchParams((params) => {
      columnNames.forEach((name) => params.delete(name));
      return params;
    });
  };

  const toggleSort = (column: string) => {
    if (searchParams.get("sortBy") === column) {
      if (!searchParams.get("order") || searchParams.get("order") === "asc") {
        changeSearchParam("order", "desc");
      } else {
        changeSearchParam("order", "asc");
      }
    } else {
      changeSearchParam("sortBy", column);
      changeSearchParam("order", "asc");
    }
  };

  useEffect(() => {
    if (!columnNames) {
      setReadableColumns(undefined);
    } else {
      const readableInOrder = makeNamesHumanReadable(columnNames);
      setReadableColumns(readableInOrder);
    }
  }, [columnNames]);

  return (
    <TableHead>
      <TableRow>
        {readableColumns
          ? readableColumns.map((column, index) => (
              <TableCell
                key={column}
                onClick={() => toggleSort(columnNames[index])}
                sx={{ borderBottom: "none", cursor: "pointer" }}
              >
                {column === "checkboxPlaceholder" ? (
                  ""
                ) : (
                  <Typography
                    noWrap
                    fontWeight="bold"
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      lineHeight: "1em",
                    }}
                  >
                    {column}
                    {
                      searchParams.get("order") === "desc" ? (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{
                            ml: 0.5,
                            opacity:
                              searchParams.get("sortBy") === columnNames[index]
                                ? 1
                                : 0,
                          }}
                        />
                      ) : (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{
                            ml: 0.5,
                            opacity:
                              searchParams.get("sortBy") === columnNames[index]
                                ? 1
                                : 0,
                          }}
                        />
                      ) /*icon present but invisible for consistent alignment*/
                    }
                  </Typography>
                )}
              </TableCell>
            ))
          : null}
      </TableRow>
      <TableRow>
        {readableColumns
          ? readableColumns.map((column, index) => (
              <TableCell key={column} sx={{ paddingTop: 0 }}>
                {column === "checkboxPlaceholder" ? (
                  <ClearFilterButton handler={() => clearSearchParams()} />
                ) : (
                  <FilterField
                    handler={(newValue) =>
                      changeSearchParam(columnNames[index], newValue)
                    }
                    value={searchParams.get(columnNames[index])}
                  />
                )}
              </TableCell>
            ))
          : null}
      </TableRow>
    </TableHead>
  );
};
