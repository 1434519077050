import { type ChangeEvent, useMemo, useState } from "react";

import {
  asString,
  uniqueObjectValuesByKey,
} from "@smartship-services/core/utils";

interface CheckboxesProps<T> {
  id: string;
  list: T[];
  resetTrigger?: boolean;
  setSelection: (arg0: string, arg1?: string[]) => void;
}

/* Displays list of checkboxes for all unique values in list for given field id */
export const Checkboxes = <T extends Record<string, unknown>>({
  id,
  list,
  resetTrigger,
  setSelection,
}: CheckboxesProps<T>) => {
  const options = useMemo(() => {
    const listWithStringValues = list.map((each) => ({
      [id]: asString(each[id]),
    }));
    const uniqueSortedOptions = uniqueObjectValuesByKey<string>(
      listWithStringValues,
      id,
    ).sort();
    return uniqueSortedOptions;
  }, [id, list]);
  const [checkboxValues, setCheckboxValues] = useState<
    Array<boolean | undefined>
  >([]);

  if (resetTrigger && checkboxValues.includes(false)) {
    setCheckboxValues(options.map(() => true));
  }

  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const index = Number(e.target.id.split("-")[0]);
    const updatedCheckboxValues = [...checkboxValues];
    updatedCheckboxValues[index] = e.target.checked;
    setCheckboxValues(updatedCheckboxValues);

    if (!updatedCheckboxValues.includes(false)) {
      setSelection(id, undefined);
    } else {
      const selectedOptions = options.filter(
        (_option, index) => !(updatedCheckboxValues[index] === false),
      );
      setSelection(id, selectedOptions);
    }
  };

  const selectAll = () => {
    setCheckboxValues(options.map(() => true));

    // If everything is selected, simply reset selection for parent.
    // More efficient and makes it easier to detect active filters.
    setSelection(id, undefined);
  };
  const selectNone = () => {
    setCheckboxValues(options.map(() => false));
    setSelection(id, []);
  };

  return (
    <details
      data-testid={`checkboxes-${id}`}
      className="collapse collapse-arrow"
      open
    >
      <summary className="collapse-title bg-neutral p-2 min-h-0">
        <span>Select:</span>
        <button
          onClick={selectNone}
          className={`btn btn-sm mx-2 ${
            checkboxValues.includes(true) ? "" : "btn-active"
          }`}
        >
          none
        </button>
        <button
          onClick={selectAll}
          className={`btn btn-sm ${
            checkboxValues.includes(false) ? "" : "btn-active"
          }`}
        >
          all
        </button>
      </summary>
      <div className="collapse-content border border-neutral max-h-48 overflow-y-auto">
        {options.map((option, index) => (
          <div key={id + option} className="form-control">
            <label className="label cursor-pointer justify-start gap-2">
              <input
                id={`${index}-${id}-${option}`}
                type="checkbox"
                checked={checkboxValues[index] ?? true}
                onChange={handleSelect}
                className="checkbox border-secondary [--chkbg:white] [--chkfg:theme(colors.secondary)]"
              />
              <span className="label-text">{option}</span>
            </label>
          </div>
        ))}
      </div>
    </details>
  );
};
