import { useEffect, useState } from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { SelectEquipmentField } from "../../forms";

interface CustomTableHeadProps {
  handleNewHeaders: (arg0: string[]) => void;
  importedHeaders: string[];
}

const CustomTableHead = ({
  handleNewHeaders,
  importedHeaders,
}: CustomTableHeadProps) => {
  const [newColumnNames, setNewColumnNames] = useState<string[]>(
    importedHeaders.map(() => ""),
  );

  const setColumnNameByIndex = (newName: string, index: number) => {
    setNewColumnNames((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = newName;
      return newValues;
    });
  };

  useEffect(() => {
    handleNewHeaders(newColumnNames);
  }, [newColumnNames]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TableHead>
      <TableRow>
        <TableCell>Select:</TableCell>
        {importedHeaders.map((header, i) => (
          <TableCell key={header}>
            <SelectEquipmentField
              field={newColumnNames[i]}
              setField={(name) => {
                setColumnNameByIndex(name, i);
              }}
            />
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        {importedHeaders.map((header, i) => (
          <TableCell key={header + i}>{header}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
