import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

interface AccentedAccordionProps {
  children: React.ReactNode;
  isMountedWhenCollapsed?: boolean;
  title: string;
}
export const AccentedAccordion = ({
  children,
  isMountedWhenCollapsed = true,
  title,
}: AccentedAccordionProps) => {
  const { palette } = useTheme();

  return (
    <div style={{ marginBottom: "1em" }}>
      <Accordion
        disableGutters
        square
        sx={{ borderLeft: `solid 3pt ${palette.primary.main}` }}
        TransitionProps={{ unmountOnExit: !isMountedWhenCollapsed }}
      >
        <AccordionSummary
          aria-controls={title + "-panel-content"}
          expandIcon={<ExpandMoreIcon />}
          id={title + "-header"}
        >
          <Typography variant="h5">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};
