import {
  createContext,
  FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { jwtDecode } from "jwt-decode";

import { loginRequest } from "../lib/authConfig";
import { api } from "../utils/api";
import { subscribe, unsubscribe } from "../utils/events";

interface RolesAndPermissions {
  permissions: string[];
  roles: string[];
}

interface UserFromApi {
  id: string;
  email: string;
  name: string;
}

interface ApiTokenPayload extends RolesAndPermissions {
  user: UserFromApi;
}

interface CurrentUser extends UserFromApi, RolesAndPermissions {}

type LoginStrategies = "Microsoft" | "email" | null;

interface DefaultAuthenticationContext {
  apiLogin?: (arg0?: string) => void;
  apiToken?: string;
  authErrorMessage?: string;
  authSuccessMessage?: string;
  currentUser?: CurrentUser | null;
  isAuthInProgress?: boolean;
  isMsAuthenticated?: boolean;
  logout?: () => void;
  msLogin?: () => void;
  sendMagicLink?: (arg0: string, arg1: string) => void;
}

export function getUserFromStorage() {
  const rawUserData = sessionStorage.getItem("user_data");
  if (!rawUserData) {
    return null;
  }
  return JSON.parse(rawUserData);
}

export function getTokenFromStorage() {
  const token = sessionStorage.getItem("api_token");
  if (!token) {
    return "";
  }
  return token;
}

function setUserToStorage(user: CurrentUser | null) {
  if (!user) {
    sessionStorage.removeItem("user_data");
    return;
  }
  try {
    const stringifiedUser = JSON.stringify(user);
    sessionStorage.setItem("user_data", stringifiedUser);
  } catch (err) {
    console.error("Error saving user data to browser storage");
  }
}

function setTokenToStorage(token: string | null) {
  if (!token) {
    sessionStorage.removeItem("api_token");
    return;
  }
  try {
    sessionStorage.setItem("api_token", token);
    return true;
  } catch (err) {
    console.error("Error saving token to browser storage");
    return false;
  }
}

export const AuthenticationContext =
  createContext<DefaultAuthenticationContext>({});

export const useAuthentication = () => useContext(AuthenticationContext);

export const AuthenticationProvider: FC<
  PropsWithChildren & {
    UnauthorizedPage: FC;
  }
> = ({ children, UnauthorizedPage }) => {
  const [authErrorMessage, setAuthErrorMessage] = useState("");
  const [authSuccessMessage, setAuthSuccessMessage] = useState("");
  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  const [loginStrategy, setLoginStrategy] = useState<LoginStrategies>(null);
  const isMsAuthenticated = useIsAuthenticated(); //Microsoft
  const { instance } = useMsal(); //Microsoft
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null); //ALMACO
  const [apiToken, setApiToken] = useState(""); //ALMACO

  const clearAuthMessages = () => {
    setAuthSuccessMessage("");
    setAuthErrorMessage("");
  };

  const msLogin = async () => {
    clearAuthMessages();
    setIsAuthInProgress(true);
    try {
      await instance.loginRedirect(loginRequest);
    } catch (err) {
      console.error(err);
      setIsAuthInProgress(false);
      setAuthErrorMessage("Error logging in with your Microsoft account");
    }
  };

  const msLocalLogout = useCallback(async () => {
    // for only clearing local session without signing out of Microsoft entirely
    clearAuthMessages();
    try {
      await instance.logoutRedirect({
        onRedirectNavigate: () => {
          return false;
        },
      });
    } catch (err) {
      console.error(err);
      setAuthErrorMessage("Error logging out from your Microsoft account");
    }
  }, [instance]);

  const sendMagicLink = async (email: string, source: string) => {
    clearAuthMessages();
    setIsAuthInProgress(true);

    try {
      await api.sendMagicLink(email, source);
      setAuthSuccessMessage(
        "We have sent the authorization link to your email!",
      );
    } catch (err) {
      console.error(err);
      setAuthErrorMessage("There was an error sending the authorization link.");
    }
    setIsAuthInProgress(false);
  };

  const clearToken = () => {
    setApiToken("");
    setTokenToStorage(null);
  };
  const clearUser = () => {
    setCurrentUser(null);
    setUserToStorage(null);
  };

  const apiLogin = useCallback(
    async (magicLinkToken?: string) => {
      if (apiToken) {
        return;
      }
      setIsAuthInProgress(true);
      clearAuthMessages();

      try {
        let apiToken = "";
        if (magicLinkToken) {
          setLoginStrategy("email");
          const { accessToken } = await api.loginWithMagicLink(magicLinkToken);
          apiToken = accessToken;
        } else {
          setLoginStrategy("Microsoft");
          const activeMsAccount = instance.getActiveAccount();
          if (!activeMsAccount?.username) {
            throw new Error(
              "Could not get email address from active Microsoft account",
            );
          }

          const { idToken } = await instance.acquireTokenSilent({
            ...loginRequest,
            forceRefresh: true,
          });
          const { accessToken, isNewUser } =
            await api.loginWithMsToken(idToken);

          if (isNewUser) {
            setIsAuthInProgress(false);
            setAuthErrorMessage("Additional account setup needed for new user");
            return;
          }
          apiToken = accessToken;
        }

        const {
          permissions = [],
          roles = [],
          user,
        } = jwtDecode<ApiTokenPayload>(apiToken);

        if (!user) {
          throw new Error("Failed to get user info");
        }
        if (!apiToken) {
          throw new Error("Failed to get authorization token");
        }

        const userWithPermissionsAndRoles = {
          ...user,
          permissions,
          roles,
        };

        setApiToken(apiToken);
        setTokenToStorage(apiToken);
        setCurrentUser(userWithPermissionsAndRoles);
        setUserToStorage(userWithPermissionsAndRoles);
      } catch (err) {
        console.error(err);
        clearUser();
        clearToken();
        setAuthErrorMessage("Error logging into app");
      }

      setIsAuthInProgress(false);
    },
    [instance, apiToken],
  );

  const logout = useCallback(async () => {
    clearAuthMessages();
    if (loginStrategy === "Microsoft") {
      await msLocalLogout();
    }
    clearUser();
    clearToken();
  }, [loginStrategy, msLocalLogout]);

  useEffect(() => {
    setCurrentUser(getUserFromStorage());
    setApiToken(getTokenFromStorage());

    const handleAuthEvent = ((e: CustomEvent) => {
      if (e.detail === "attempt to reauthenticate") {
        if (loginStrategy === "Microsoft") {
          // re-auth can happen automatically if Microsoft session still valid
          apiLogin();
        } else {
          logout();
        }
      }
    }) as EventListener;

    subscribe("authProblem", handleAuthEvent);

    return () => {
      unsubscribe("authProblem", handleAuthEvent);
    };
  }, [apiLogin, loginStrategy, logout]);

  return (
    <AuthenticationContext.Provider
      value={{
        apiLogin,
        apiToken,
        authErrorMessage,
        authSuccessMessage,
        currentUser,
        sendMagicLink,
        isAuthInProgress,
        isMsAuthenticated,
        logout,
        msLogin,
      }}
    >
      {currentUser ? children : <UnauthorizedPage />}
    </AuthenticationContext.Provider>
  );
};
