import { Link as RouterLink } from "react-router-dom";
import { Link, Skeleton, Typography } from "@mui/material";

import { WorkOrderRecord } from "@smartship-services/core";
import { justTheDate } from "@smartship-services/core/utils";
import { AccentedAccordion } from "@smartship-services/ui/layout";

export * from "./SelectableEquipmentList";

interface WorkOrderDetailsProps {
  vesselName?: string;
  workOrder: WorkOrderRecord;
}

export const WorkOrderDetails = ({
  vesselName,
  workOrder,
}: WorkOrderDetailsProps) => {
  const rows = [
    {
      label: "Customer",
      content: workOrder.customerName,
    },
    {
      label: "Vessel",
      content: (
        <Link to={`/vessels/${workOrder.vesselId}`} component={RouterLink}>
          {vesselName || <Skeleton />}
        </Link>
      ),
    },
    {
      label: "Onboard Contact",
      content: workOrder.onboardContactName || "",
    },
    {
      label: "Service Engineer(s)",
      content: workOrder.serviceEngineers?.join(", ") || "",
    },
    {
      label: "Intervention Date",
      content: workOrder.plannedStartDate
        ? justTheDate(workOrder.plannedStartDate)
        : "",
    },
    {
      label: "Categories",
      content: workOrder.categories ? workOrder.categories.join(", ") : "",
    },
  ];

  return (
    <table>
      <tbody>
        {rows.map((row, i) => (
          <tr key={"work-order-details-" + i}>
            <td>
              <Typography>
                <strong>{row.label}</strong>
              </Typography>
            </td>
            <td>
              <Typography>{row.content}</Typography>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={2}>
            {workOrder.freeComment ? (
              <AccentedAccordion title="Notes">
                <p style={{ whiteSpace: "pre-line" }}>
                  {workOrder.freeComment || ""}
                </p>
              </AccentedAccordion>
            ) : null}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
