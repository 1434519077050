import { Chip, Tooltip } from "@mui/material";

import {
  EquipmentRecord,
  mapConditionToVisuals,
  WorkOrderStatuses,
} from "@smartship-services/core";

interface TrafficLightProps {
  condition?: EquipmentRecord["condition"];
}

export const TrafficLight = ({ condition }: TrafficLightProps) => {
  const { color, label } = mapConditionToVisuals(condition);
  const badgeColors = {
    success: "badge-success",
    warning: "badge-warning",
    error: "badge-error",
    secondary: "badge-secondary",
  };

  return (
    <span className={`badge badge-outline p-3 pt-4 ${badgeColors[color]}`}>
      {label}
    </span>
  );
};

interface StatusChipProps {
  errorMessage?: string;
  status: string;
}

export const StatusChip = ({ errorMessage, status }: StatusChipProps) => {
  if (status === "rejected") {
    return (
      <Tooltip enterTouchDelay={0} leaveTouchDelay={8000} title={errorMessage}>
        <Chip color="error" label="rejected" variant="outlined" />
      </Tooltip>
    );
  } else {
    return (
      <Chip
        color={
          status === "retry"
            ? "warning"
            : status === "done"
              ? "success"
              : "default"
        }
        label={status}
        variant="outlined"
      />
    );
  }
};

interface WorkOrderStatusChipProps {
  status?: WorkOrderStatuses;
}

export const WorkOrderStatusChip = ({ status }: WorkOrderStatusChipProps) => (
  <Chip
    color={status === "active" ? "success" : "default"}
    label={status || ""}
    variant="outlined"
  />
);
