import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";

import {
  VesselRecord,
  WorkOrderRecord,
  ACTIVE_WORK_ORDER_STORAGE_KEY,
} from "@smartship-services/core";
import { useDataList } from "@smartship-services/core/hooks";
import { justTheDate } from "@smartship-services/core/utils";

import { WorkOrderStatusChip } from "@smartship-services/ui";
import { SearchBox } from "@smartship-services/ui/forms";

const fieldsList = [
  { id: "plannedStartDate", label: "Planned Start Date" },
  { id: "customerName", label: "Customer Name" },
  { id: "vesselName", label: "Vessel Name" },
  { id: "serviceEngineers", label: "Service Engineer(s)" },
];

const WorkOrderListPage = () => {
  const vessels = useDataList<VesselRecord>("vessels");
  const workOrders = useDataList<WorkOrderRecord>("workOrders");

  const workOrdersWithVesselName = useMemo(() => {
    const getVesselNameById = (id: string) => {
      const vessel = vessels?.filter((each) => each.id === id)[0];
      return vessel?.vesselName || id;
    };
    return (
      workOrders?.map((workOrder) => ({
        ...workOrder,
        vesselName: getVesselNameById(workOrder.vesselId),
      })) || []
    );
  }, [vessels, workOrders]);

  const [filteredWorkOrders, setFilteredWorkOrders] = useState<
    WorkOrderRecord[]
  >([]);

  const sessionWorkOrder = sessionStorage.getItem(
    ACTIVE_WORK_ORDER_STORAGE_KEY,
  );
  const activeWorkOrder = sessionWorkOrder
    ? JSON.parse(sessionWorkOrder).id
    : null;

  return (
    <main>
      <h1>Work Orders</h1>
      <div className="flex mb-2 justify-end">
        <Link to="create" className="btn btn-primary">
          <DocumentPlusIcon className="icon-size-default" />
          Create Work Order
        </Link>
      </div>
      <SearchBox<WorkOrderRecord>
        className="mx-auto my-3 max-w-4xl"
        defaultSortBy="plannedStartDate"
        defaultOrder="desc"
        filterableFields={fieldsList}
        sortableFields={fieldsList}
        list={workOrdersWithVesselName}
        setList={setFilteredWorkOrders}
      />
      {workOrders?.length ? (
        <div className="overflow-x-auto">
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>Status</th>
                <th>Planned Start Date</th>
                <th>Customer Name</th>
                <th>Vessel Name</th>
                <th>Service Engineer(s)</th>
              </tr>
            </thead>
            <tbody>
              {filteredWorkOrders.map((each: WorkOrderRecord) => (
                <tr key={each.id} className="hover">
                  <td>
                    <Link
                      to={each.id}
                      className="btn btn-primary btn-outline btn-sm"
                    >
                      View
                    </Link>
                  </td>
                  <td>
                    <WorkOrderStatusChip
                      status={
                        each.id === activeWorkOrder ? "active" : each.status
                      }
                    />
                  </td>
                  <td>
                    {each.plannedStartDate
                      ? justTheDate(each.plannedStartDate)
                      : ""}
                  </td>
                  <td>{each.customerName || ""}</td>
                  <td>{each.vesselName}</td>
                  <td>{each.serviceEngineers?.join(",") || ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </main>
  );
};

export default WorkOrderListPage;
