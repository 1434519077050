import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import CustomTableHead from "./CustomTableHead";

interface EquipmentImportTableProps {
  handleNewHeaders: (arg0: string[]) => void;
  matrix: string[][] | null;
}

export const EquipmentImportTable = ({
  handleNewHeaders,
  matrix,
}: EquipmentImportTableProps) => {
  const previewCount = 5;

  const generateCells = (row: string[]) =>
    row.map((cell: string | Date, i) => (
      <TableCell key={i}>
        {cell instanceof Date ? (
          <span title="date detected">
            🗓 {cell.toISOString().split("T")[0]}{" "}
          </span>
        ) : (
          cell
        )}
      </TableCell>
    ));

  return (
    <TableContainer sx={{ mt: 1, mb: 1 }}>
      <Table size="small">
        {matrix ? (
          <CustomTableHead
            importedHeaders={matrix[0]}
            handleNewHeaders={handleNewHeaders}
          />
        ) : null}
        <TableBody>
          {matrix ? (
            matrix.slice(1, previewCount + 1).map((row, i) => (
              <TableRow hover key={i}>
                <TableCell key="row-number">{i + 1}</TableCell>
                {generateCells(row)}
              </TableRow>
            ))
          ) : (
            <TableRow key="empty-equipment">
              <TableCell>Waiting for data</TableCell>
            </TableRow>
          )}
        </TableBody>
        {matrix ? (
          <caption>
            Previewing first {previewCount} rows out of {matrix.length}
          </caption>
        ) : null}
      </Table>
    </TableContainer>
  );
};
