import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EquipmentRecord } from "@smartship-services/core";
import { getById } from "@smartship-services/core/hooks";

const EquipmentRedirectPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      return;
    }

    getById<EquipmentRecord>("equipment", id)
      .then((equipment) => {
        const { id, vesselId } = equipment || {};
        navigate(`/vessels/${vesselId}/equipment/${id}`);
      })
      .catch((err) => {
        console.error(err);
        navigate("/", { replace: true });
      });
  }, [id, navigate]);

  return <p>Locating equipment by ID {id}...</p>;
};

export default EquipmentRedirectPage;
