import { Link, useLocation } from "react-router-dom";
import { CloudSyncButton } from "../buttons/CloudSyncButton";
import { topSpaceForAppBar } from "../lib/consts";

export const Header = () => {
  const location = useLocation();
  const isHomepage = location.pathname === "/";
  const fontSize = isHomepage ? "text-3xl" : "text-lg";
  const padding = isHomepage ? "py-8" : "py-3";

  return (
    <div className={`flex justify-center align-center ${topSpaceForAppBar}`}>
      <Link to="/" className={`justify-self-center ${padding}`}>
        <span className={`montserrat font-black ${fontSize}`}>mains</span>
      </Link>
      <div className={`absolute top-3 right-3 ${topSpaceForAppBar}`}>
        <CloudSyncButton />
      </div>
    </div>
  );
};
