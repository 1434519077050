import { createTheme } from "@mui/material";

import { fireZone, funnel, oceanBlue, seaweed } from "@smartship-services/ui";

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["Calibri", "Helvetica", "sans-serif"].join(","),
    fontWeightMedium: 700,
    fontWeightBold: 900,
    h1: {
      textTransform: "uppercase",
      fontSize: "2rem",
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontWeight: 700,
      marginBottom: "0.2em",
      color: funnel[100],
    },
    h2: {
      fontSize: "1.5rem",
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontWeight: 700,
      margin: "0.2em",
      color: funnel[100],
    },
    h5: {
      textTransform: "uppercase",
      fontSize: "1rem",
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontWeight: 500,
      color: funnel[100],
    },
  },
  palette: {
    primary: {
      main: oceanBlue[60],
      dark: oceanBlue[100],
    },
    secondary: {
      main: funnel[100],
    },
    success: {
      main: seaweed[60],
    },
    warning: {
      main: fireZone[60], //Firezone 60
    },
  },
});

export default defaultTheme;
