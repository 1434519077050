import {
  apiClient,
  type FetchConfig,
  fetchWrapper,
  type NetworkError,
} from "./fetchWrapper";
import { publish } from "./events";
import {
  deleteData,
  getQueueList,
  getTableCount,
  putDataList,
  putFileMetadataList,
} from "../hooks";
import { getTokenFromStorage } from "../hooks/useAuthentication";
import { consolidateQueue } from "./consolidateQueue";
import { QueueRecord, SyncStatus, VesselRecord } from "../lib/types"; //for getting/setting values from local or session storage
import { OFFLINE_MODE_STORAGE_KEY } from "../hooks/useCloudSync";

import { type MaintenanceDB } from "../lib/db";

const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

const isNetworkOnline = () =>
  navigator.onLine ? Boolean(navigator.onLine) : false;

const mayContinue = () => {
  const isOfflineMode =
    sessionStorage.getItem(OFFLINE_MODE_STORAGE_KEY) === "offline";
  const authToken = getTokenFromStorage();

  if (isOfflineMode || !isNetworkOnline()) {
    console.log("network not connected or offline mode enabled");
    publish("syncStatusChange", SyncStatus.OFF);
    return false;
  } else if (!authToken) {
    publish("authProblem", "attempt to reauthenticate");
    return false;
  } else {
    return true;
  }
};

export const decideIfSafeToSkipError = ({ method, table }: QueueRecord) => {
  if (table === "fileMetadata") {
    // nothing else depends on fileMetadata
    return true;
  }

  if (method === "DELETE") {
    // no changes depend on something being deleted first
    return true;
  }

  if (table === "equipment" && method === "PUT") {
    // other changes probably don't depend on edits to existing equipment
    return true;
  }

  return false;
};

type GenericRecord = Record<string, string | null | undefined>;
const processRecord = async (
  recordId: string,
  record?: GenericRecord,
  workOrderId?: string,
) => {
  if (!record) {
    return {};
  }

  if (recordId.includes("local") || record.vesselId?.includes("local")) {
    return null; //skip local records for playing in development
  }

  if ("filename" in record) {
    // For photos, request body is form data
    const fileFormData = new FormData();
    if (record.fileHandle) {
      fileFormData.append("photo", record?.fileHandle as unknown as Blob);
    }
    fileFormData.append("id", recordId);
    fileFormData.append(
      "equipmentId",
      record.equipmentId || "missing equipment id during sync",
    );
    fileFormData.append(
      "filename",
      record.filename || `${recordId} missing filename during sync`,
    );
    if (workOrderId) {
      fileFormData.append("workOrderId", workOrderId);
    }

    return fileFormData;
  }

  if (workOrderId) {
    record.workOrderId = workOrderId;
  }

  return record;
};

interface RemoteRecord {
  customFieldKeys?: [];
  customFieldValues?: [];
  location?: {
    name: string;
    idFromCustomer: string;
  };
  name: string;
}

const transformRemoteToLocal: Record<string, <R>(arg0: R & RemoteRecord) => R> =
  {
    "/equipment": (equipment) => {
      const combinedCustom = equipment.customFieldKeys?.map((key, i) => {
        const value = equipment.customFieldValues?.[i];
        return [key, value];
      });
      return {
        ...equipment,
        equipmentName: equipment.name,
        location: equipment.location?.name,
        locationIdFromCustomer: equipment.location?.idFromCustomer,
        custom: combinedCustom || [],
      };
    },
    "/locations": (location) => {
      return { ...location, locationName: location.name };
    },
    "/vessels": (vessel) => {
      return { ...vessel, vesselName: vessel.name };
    },
    "/work-orders": (workOrder) => {
      return workOrder;
    },
    "/file-metadata": (fileMetadata) => fileMetadata,
  };

async function syncPull() {
  console.log("sync pull");
  //main sync function catches final errors

  const endpoints = [
    {
      path: "/vessels",
      table: "vessels",
    },
    {
      path: "/locations",
      table: "locations",
    },
    {
      path: "/equipment",
      table: "equipment",
    },
    {
      path: "/work-orders",
      table: "workOrders",
    },
    {
      path: "/file-metadata",
      table: "fileMetadata",
    },
  ];

  for (const { path, table } of endpoints) {
    const newSyncDate = new Date().toISOString();
    const lastSynced = localStorage.getItem(`last-synced-${table}`);
    let lastSyncedQuery = "";
    if (lastSynced) {
      lastSyncedQuery = `?updatedAt=${lastSynced}`;
    }
    const latestData = await apiClient(path + lastSyncedQuery);
    const localRecords = latestData.map(transformRemoteToLocal[path]);
    if (table === "fileMetadata") {
      await putFileMetadataList(localRecords);
    } else {
      await putDataList(table as keyof MaintenanceDB, localRecords);
    }

    localStorage.setItem(`last-synced-${table}`, newSyncDate);
    await sleep(100); //breathing room for the API
  }

  try {
    //sync deletions from cloud
    const newSyncDate = new Date().toISOString();
    const lastSynced = localStorage.getItem(`last-synced-history`);
    let lastSyncedQuery = "";
    if (lastSynced) {
      lastSyncedQuery = `&updatedAt=${lastSynced}`;
    }
    const deletions = await apiClient(
      `/history?method=DELETE${lastSyncedQuery}`,
    );
    if (deletions.length) {
      for (const { tableName, recordId } of deletions) {
        await deleteData(tableName, recordId, true).catch((err) =>
          console.log(err),
        );
      }
    }
    localStorage.setItem(`last-synced-history`, newSyncDate);
  } catch (err) {
    console.error(err);
  }
}

async function handlePushError(
  err: NetworkError,
  request: QueueRecord,
  isErrorSkipped = false,
) {
  const rejectedCodes = [403, 404, 405, 409, 422, 500];
  const { message, statusCode } = err;
  await putDataList("queue", [
    {
      ...request,
      errorMessage: message,
      status: rejectedCodes.includes(statusCode) ? "rejected" : "retry",
    },
  ]);

  if (!isErrorSkipped) {
    throw err;
  }
}

async function syncPush() {
  console.log("sync push");
  //main sync function catches final errors

  const authToken = getTokenFromStorage();
  if (!authToken) {
    throw new Error("Cannot upload changes when not logged in");
  }

  await consolidateQueue();
  const requestQueue = await getQueueList();
  for (const request of requestQueue) {
    const { recordId, method, path, status, workOrderId, record, table } =
      request;
    if (!mayContinue()) {
      break; //abort loop for offline mode or network loss
    }

    if (status === "rejected") {
      //skip previously rejected requests
      continue;
    }

    // backwards compatibility for old queued items
    let url = path;
    if (table === "fileMetadata") {
      url = path.replace("fileMetadata", "file-metadata");
    }

    const config: FetchConfig = {
      method,
    };

    if (method === "POST" || method === "PUT") {
      const processedRecord = await processRecord(
        recordId,
        record as GenericRecord,
        workOrderId,
      );
      if (processedRecord === null) {
        continue; //null means skip this request
      }
      config.body = processedRecord;
    }

    try {
      await apiClient(url, config);
      await deleteData("queue", request.id);
    } catch (err) {
      const isErrorSkipped = decideIfSafeToSkipError(request);
      await handlePushError(err as NetworkError, request, isErrorSkipped);
    }

    await sleep(100); //breathing room for the API
  }
}

//service for components and hooks to call the api
export const api = {
  loginWithMsToken: async (idToken?: string) => {
    if (!idToken) {
      throw new Error("Error getting Microsoft token for login");
    }

    const loginResponse = await fetchWrapper(
      "/rest/mains/users/loginWithMicrosoft",
      {
        method: "POST",
        body: { idToken },
      },
    );

    return loginResponse;
  },
  loginWithMagicLink: async (token?: string) => {
    if (!token) {
      throw new Error("Error getting token for login");
    }

    const loginResponse = await fetchWrapper(
      "/rest/mains/users/loginWithMagicLink",
      {
        method: "POST",
        body: { token },
      },
    );

    return loginResponse;
  },
  sendMagicLink: async (email: string, source: string) => {
    if (!email) {
      throw new Error("Error getting email for login");
    }
    await fetchWrapper("/rest/auth/token", {
      method: "POST",
      body: { email, source },
    });
  },
  sync: async () => {
    console.log("syncing");
    if (!mayContinue()) {
      return;
    }

    publish("syncStatusChange", SyncStatus.SYNCING);

    //sync push
    const queueCount = await getTableCount("queue");
    if (queueCount > 0) {
      try {
        await syncPush();
      } catch (err) {
        console.error("sync push error", err);
        publish("syncStatusChange", SyncStatus.ERROR);
        const { message } = err as Error;
        publish("syncError", message);
        //if push fails, abort sync early
        return;
      }
    }

    //to be sure, check queue for requests to be retried
    const queueCountAfterPush = await getTableCount("queue");
    if (queueCountAfterPush > 0) {
      publish("syncStatusChange", SyncStatus.PENDING);
      publish(
        "syncError",
        "One or more pending changes were not sent. Next sync will retry.",
      );
      return;
    } else {
      //sync pull
      try {
        await syncPull();
        publish("syncStatusChange", SyncStatus.DONE);
      } catch (err) {
        console.error("sync pull error", err);
        publish("syncStatusChange", SyncStatus.ERROR);
        const { message } = err as NetworkError;
        publish("syncError", message || "Error trying to reach server");
      }
    }
  },
  getWorkOrderSummary: async (workOrderId: string) => {
    try {
      const summaryResponse = await apiClient(
        `/work-orders/${workOrderId}?includeSummary=true`,
      );
      return summaryResponse;
    } catch (err) {
      console.error(err);
      if (401 === (err as NetworkError).statusCode) {
        throw new Error("Authentication failed. Please log in again.");
      } else {
        throw new Error("Error fetching work order summary from cloud");
      }
    }
  },
  download: async (metadataId: string) => {
    //for fetching files that require authorization headers
    if (!mayContinue()) {
      return "offline";
    }

    try {
      const photoResponse = await apiClient(
        `/file-metadata/${metadataId}/download`,
      );

      if (!photoResponse) {
        return "";
      }

      const photoUrl = URL.createObjectURL(photoResponse);
      return photoUrl;
    } catch (err) {
      return "";
    }
  },
  importVessel: async (vesselId: number) => {
    try {
      const vesselResponse = await apiClient(`/vessels/import`, {
        method: "POST",
        body: { vesselId },
      });
      return vesselResponse as VesselRecord;
    } catch (err) {
      throw new Error("Error importing vessel");
    }
  },
};
