import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ArrowUpRightIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";

import {
  EquipmentActivityRecord,
  EquipmentActivityStatus,
  EquipmentCondition,
  EquipmentRecord,
  mapConditionToVisuals,
  WorkOrderRecord,
} from "@smartship-services/core";
import {
  getChecklistId,
  getWorkOrderListByVessel,
} from "@smartship-services/core/hooks";
import { isValidDate, justTheDate } from "@smartship-services/core/utils";
import { EquipmentConditionIcon } from "@smartship-services/ui";
import { readableWorkOrder } from "@smartship-services/ui/forms";

const CONDITION_CHECKLIST_ID = getChecklistId("condition");

interface ConditionTimelineProps {
  conditionActivity: EquipmentActivityRecord[];
}

const ConditionTimeline = ({ conditionActivity }: ConditionTimelineProps) => (
  <div className="overflow-auto pb-3 mb-3 max-h-screen">
    <ul className="timeline timeline-vertical lg:timeline-horizontal">
      {conditionActivity.map(({ body, createdAt, id }, index) => {
        const condition = body.equipmentFields?.condition as EquipmentCondition;
        const conditionComment = body.checklistFields?.comment ?? "";
        const date = isValidDate(createdAt)
          ? justTheDate(createdAt as Date)
          : "[no date]";
        const isFirst = index === 0;
        const isLast = index === conditionActivity.length - 1;
        const { label } = mapConditionToVisuals(condition);

        return (
          <li key={id}>
            {isFirst ? null : <hr />}
            <div className="timeline-start lg:min-w-32 text-center">{date}</div>
            <div className="timeline-middle px-2">
              <EquipmentConditionIcon condition={condition} />
            </div>
            <div className="timeline-end timeline-box text-center">
              {conditionComment ? (
                <details tabIndex={0} className="collapse">
                  <summary className="cursor-pointer text-secondary hover:text-black">
                    <span className="font-bold text-sm">{label}</span>
                    <DocumentTextIcon className="icon-size-default inline ml-2" />
                  </summary>
                  <p className="max-h-36 overflow-y-auto min-w-36 lg:max-w-96 text-left break-words pt-2 text-sm">
                    {conditionComment}
                  </p>
                </details>
              ) : (
                <span className="font-bold text-secondary text-sm">
                  {label}
                </span>
              )}
            </div>
            {isLast ? null : <hr />}
          </li>
        );
      })}
    </ul>
  </div>
);

interface EquipmentHistoryProps {
  equipment: EquipmentRecord;
}

export const EquipmentHistory = ({ equipment }: EquipmentHistoryProps) => {
  const [workOrders, setWorkOrders] = useState<WorkOrderRecord[]>([]);
  const [conditionActivity, setConditionActivity] = useState<
    EquipmentActivityRecord[]
  >([]);

  useEffect(() => {
    if (!equipment) {
      return;
    }

    getWorkOrderListByVessel(equipment.vesselId)
      .then((workOrders) => {
        const workOrdersForThisEquipment = workOrders.filter(
          ({ equipmentIds }) => equipmentIds.includes(equipment.id),
        );
        setWorkOrders(workOrdersForThisEquipment);
      })
      .catch((err) => {
        console.error(err);
        setWorkOrders([]);
      });

    const onlyConditionActivity =
      equipment.activity?.filter(
        ({ checklistId, status }) =>
          checklistId === CONDITION_CHECKLIST_ID &&
          status === EquipmentActivityStatus.COMPLETE,
      ) || [];
    setConditionActivity(onlyConditionActivity);
  }, [equipment]);

  return (
    <>
      <h6>Condition</h6>
      {conditionActivity?.length ? (
        <ConditionTimeline conditionActivity={conditionActivity} />
      ) : (
        <p className="mb-2">none</p>
      )}
      <h6>Work Orders</h6>
      {workOrders.length ? (
        <ul className="menu">
          {workOrders.map((workOrder) => (
            <li key={workOrder.id}>
              <Link to={`/work-orders/${workOrder.id}`}>
                <ArrowUpRightIcon className="icon-size-default text-primary mr-1" />
                {readableWorkOrder(workOrder)}
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>none</p>
      )}
    </>
  );
};
