import { ChangeEvent, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  AddAPhoto as AddPhotoIcon,
  Build as BuildIcon,
  DeleteForever as DeleteIcon,
  ExpandMore as ExpandIcon,
  FilterAlt as FilterAltIcon,
  ThumbDown as ThumbDownIcon,
  ThumbUp as ThumbUpIcon,
} from "@mui/icons-material";

import {
  EquipmentField,
  equipmentKeyDescriptions,
  FileMetadataRecord,
  makeNameHumanReadable,
  type Suggestions,
} from "@smartship-services/core";
import { DateField, QuickParagraphField, QuickTextField } from "./generic";
import { HelpButton } from "../buttons";
import { QrReaderFormField } from "../lib/QrReader";

interface SelectEquipmentFieldProps {
  field: string;
  setField: (arg0: string) => void;
}

export const SelectEquipmentField = ({
  field,
  setField,
}: SelectEquipmentFieldProps) => {
  const label = "Column";

  const handleChange = (event: SelectChangeEvent) => {
    setField(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth error={!field}>
        <Select
          displayEmpty
          id="select-equipment-field"
          inputProps={{ "aria-label": label }}
          onChange={handleChange}
          size="small"
          value={field}
          variant="outlined"
        >
          <MenuItem key="empty" value="">
            <em>{label}</em>
          </MenuItem>
          {equipmentKeyDescriptions.map(({ key, label, description }) => (
            //TODO: special handling for custom and combination options
            <MenuItem key={key} value={key} title={description}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

interface FilterFieldProps {
  handler: (arg0: string | null) => void;
  value: string | null;
}

export const FilterField = ({ handler, value }: FilterFieldProps) => {
  return (
    <TextField
      id="outlined-basic"
      InputProps={{
        endAdornment: (
          <InputAdornment disablePointerEvents position="end">
            <FilterAltIcon sx={{ opacity: 0.5 }} />
          </InputAdornment>
        ),
      }}
      margin="none"
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        //TODO: debounce for more responsive input
        handler(e.target.value);
      }}
      size="small"
      sx={{ minWidth: "120px" }}
      value={value || ""}
      variant="outlined"
    />
  );
};

interface EquipmentComboboxProps {
  id: string;
  setter: (arg0: string) => void;
  suggestions?: string[];
  value: string;
}

const filter = createFilterOptions<string>();
export const EquipmentCombobox = ({
  id,
  setter,
  suggestions,
  value,
}: EquipmentComboboxProps) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      blurOnSelect
      disablePortal
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        //suggest creating a new value
        if (inputValue !== "" && !options.includes(inputValue)) {
          filtered.push(`Create: ${inputValue}`);
        }

        return filtered;
      }}
      freeSolo
      fullWidth
      handleHomeEndKeys
      id={`combobox-${id}`}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        if (newValue?.includes("Create: ")) {
          newValue = newValue.split(": ")[1];
        }
        setter(newValue || "");
      }}
      onInputChange={(event, newValue) => {
        setInputValue(newValue);
      }}
      options={suggestions || []}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            equipmentKeyDescriptions.filter((each) => each.key === id)[0]
              ?.label || id
          }
        />
      )}
      value={value}
    />
  );
};

interface EquipmentTextFieldProps {
  id: string;
  otherProps?: Record<string, string | number | boolean>;
  setter: (arg0: string) => void;
  value: string;
}

export const EquipmentTextField = ({
  id,
  setter,
  otherProps,
  value,
}: EquipmentTextFieldProps) => (
  <TextField
    fullWidth
    id={`combobox-${id}`}
    label={
      equipmentKeyDescriptions.filter((each) => each.key === id)[0]?.label || id
    }
    onChange={(e) => {
      setter(e.target.value);
    }}
    value={value}
    {...otherProps}
  />
);

interface EquipmentTrafficLightProps {
  setter: (arg0: string) => void;
  value: string | null;
}

export const EquipmentTrafficLight = ({
  setter,
  value,
}: EquipmentTrafficLightProps) => (
  <ToggleButtonGroup
    aria-label="equipment condition"
    exclusive
    onChange={(e, newValue) => setter(newValue || "")}
    value={value}
  >
    <ToggleButton color="success" value="good" aria-label="good condition">
      <ThumbUpIcon sx={{ mr: 1 }} />
      Good
    </ToggleButton>
    <ToggleButton
      color="warning"
      value="needs service"
      aria-label="needs service"
    >
      <BuildIcon sx={{ mr: 1 }} />
      Needs service
    </ToggleButton>
    <ToggleButton color="error" value="fail" aria-label="fail inspection">
      <ThumbDownIcon sx={{ mr: 1 }} />
      Fail
    </ToggleButton>
    <HelpButton
      content={
        <ul>
          <li>
            Good: good working condition, only planned maintenance required
          </li>
          <li>
            Needs service: deteriorated working condition, corrective
            maintenance to be planned
          </li>
          <li>
            Fail: not working, needs immediate corrective maintenance or needs
            to be replaced
          </li>
        </ul>
      }
    />
  </ToggleButtonGroup>
);

const autoDownloadToGallery = (capturedPhoto: File) => {
  const tabletPhotoFilenameRegex = /^\d+.jpg$/;
  if (tabletPhotoFilenameRegex.test(capturedPhoto.name)) {
    const imageUrl = URL.createObjectURL(capturedPhoto);
    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = capturedPhoto.name;
    const revokeUrl = () => {
      setTimeout(() => {
        URL.revokeObjectURL(imageUrl);
        a.removeEventListener("click", revokeUrl);
      }, 2000);
    };
    a.addEventListener("click", revokeUrl, false);
    a.click();
  }
};

interface ImageMenuProps {
  imageList: FileMetadataRecord[];
  removeImage: (arg0: FileMetadataRecord) => void;
}

const ImageMenu = ({ imageList, removeImage }: ImageMenuProps) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(menuAnchor);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  return (
    <Box sx={{ display: "inline" }}>
      <Button endIcon={<ExpandIcon />} onClick={handleClick} sx={{ ml: 2 }}>
        {`${imageList.length} photo${
          imageList.length === 1 ? "" : "s"
        } attached`}
      </Button>
      <Menu anchorEl={menuAnchor} open={isOpen} onClose={handleClose}>
        {imageList.length > 0 ? (
          imageList.map((image) => (
            <MenuItem
              key={image.id}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {image.filename || "missing filename"}
              <IconButton
                color="error"
                onClick={() => removeImage(image)}
                title="Remove photo"
              >
                <DeleteIcon />
              </IconButton>
            </MenuItem>
          ))
        ) : (
          <MenuItem>Add photos to see them listed here</MenuItem>
        )}
      </Menu>
    </Box>
  );
};

interface ImageUploadFieldProps {
  label?: string;
  imageMetadata: FileMetadataRecord[];
  setter: (arg0: "add" | "remove", arg1: FileMetadataRecord[]) => void;
}

export const ImageUploadField = ({
  label,
  imageMetadata,
  setter,
}: ImageUploadFieldProps) => {
  const removeImage = (imageToRemove: FileMetadataRecord) => {
    setter("remove", [imageToRemove]);
  };

  const handleImageEvent = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const eventImageList = Array.from(e.target.files);
    const imageMetadataDrafts = eventImageList.map((image) => {
      const id = uuid();
      return {
        id,
        filename: image.name || `${id}_missing_filename`,
        fileHandle: image,
      };
    });

    setter("add", imageMetadataDrafts);

    // Trigger download if user took new photo with tablet camera
    // Otherwise the photo only exists temporarily in hidden browser storage
    autoDownloadToGallery(e.target.files[0]);
  };

  return (
    <Box>
      <label htmlFor="image-upload-field">
        <input
          accept="image/*"
          id="image-upload-field"
          onChange={handleImageEvent}
          multiple
          style={{ display: "none" }}
          type="file"
        />
        <Button
          component="span"
          startIcon={<AddPhotoIcon />}
          variant="outlined"
        >
          {label || "Add photo(s)"}
        </Button>
      </label>
      <ImageMenu imageList={imageMetadata} removeImage={removeImage} />
    </Box>
  );
};

interface TailoredFormFieldProps {
  fieldKey: string;
  originalValue: EquipmentField | undefined;
  setter: (arg0: string, arg1: EquipmentField | undefined) => void;
  suggestions?: Suggestions;
}

export const TailoredFormField = ({
  originalValue,
  fieldKey,
  setter,
  suggestions,
}: TailoredFormFieldProps) => {
  const [tempState, setTempState] = useState(originalValue);

  const setterWithTemp = (newValue: EquipmentField | undefined) => {
    //for form fields without their own state to hold pending changes
    setTempState(newValue);
    setter(fieldKey, newValue);
  };

  switch (fieldKey) {
    case "almacoSerialNumber":
      return (
        <QuickTextField
          label={
            originalValue
              ? makeNameHumanReadable(fieldKey)
              : "will autofill on save if apId is valid"
          }
          setter={(newValue) => setter(fieldKey, newValue)}
          value={(originalValue as string) || ""}
        />
      );
    case "apId":
      return (
        <QrReaderFormField
          setter={setterWithTemp}
          value={(tempState as string) || ""}
        />
      );
    case "condition":
      return (
        <EquipmentTrafficLight
          setter={setterWithTemp}
          value={(tempState as string) || ""}
        />
      );
    case "freeComment":
      return (
        <QuickParagraphField
          label={makeNameHumanReadable(fieldKey)}
          setter={(newValue) => setter("freeComment", newValue)}
          value={(originalValue as string) || ""}
        />
      );
    case "warrantyEnds": //same as next
    case "installationDate":
      return <DateField setter={setterWithTemp} value={tempState as Date} />;
    case "location":
      return (
        <EquipmentCombobox
          id={fieldKey}
          setter={(newValue) => {
            setter("location", newValue.replace("Create: ", ""));
            //let backend fill these in by location name or create them
            setter("locationId", "");
            setter("locationIdFromCustomer", "");
          }}
          suggestions={suggestions?.location}
          value={(originalValue as string) || ""}
        />
      );
    case "makerName":
      return (
        <EquipmentCombobox
          id={fieldKey}
          setter={(newValue) =>
            setter("makerName", newValue.replace("Create: ", ""))
          }
          suggestions={suggestions?.makerName}
          value={(originalValue as string) || ""}
        />
      );
    default:
      return (
        <QuickTextField
          label={makeNameHumanReadable(fieldKey)}
          setter={(newValue) => setter(fieldKey, newValue)}
          value={(originalValue as string) || ""}
        />
      );
  }
};
