import ReactSelect from "react-select";

export const Select: ReactSelect = (props) => {
  return (
    <ReactSelect
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: "oklch(var(--p))",
          primary25: "oklch(var(--p)/25%)",
          primary50: "oklch(var(--p)/50%)",
          primary75: "oklch(var(--p)/75%)",
          secondary: "oklch(var(--s))",
          accent: "oklch(var(--a))",
          success: "oklch(var(--su))",
          danger: "oklch(var(--er))",

          neutral: "oklch(var(--n))",
        },
      })}
      {...props}
    />
  );
};
