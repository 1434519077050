import { useQrReader } from "../lib/QrReader";
import { defaultCardSizeClass } from "../lib/consts";
import { ScanQrCodeIcon } from "../assets/icons";
import { ContentAndButtonCard } from "./ContentAndButtonCard";

export const ScanQrCard = ({ className = defaultCardSizeClass }) => {
  const { QrDrawer, openScanner } = useQrReader();

  return (
    <>
      <ContentAndButtonCard
        accented={true}
        action={openScanner}
        title="QR Scan"
      >
        <ScanQrCodeIcon className={`text-secondary mx-auto ${className}`} />
      </ContentAndButtonCard>
      <QrDrawer />
    </>
  );
};
