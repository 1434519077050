import { RectangleStackIcon } from "@heroicons/react/24/outline";
import { defaultCardSizeClass } from "@smartship-services/ui";
import {
  ConditionSummaryCard,
  ContentAndButtonCard,
  EquipmentListCard,
  ReportsCard,
  ScanQrCard,
} from "@smartship-services/ui/cards";

const WorkOrdersCard = ({ className = defaultCardSizeClass }) => (
  <ContentAndButtonCard accented={true} path="/work-orders" title="Orders">
    <RectangleStackIcon
      className={`text-secondary mx-auto [&>path]:stroke-[1.2] ${className}`}
    />
  </ContentAndButtonCard>
);

/* ----- Customer external roles ----- */

export const EquipmentUserView = () => (
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
    <ScanQrCard className="max-h-36 my-6" />
    <EquipmentListCard className="max-h-36 my-6" />
  </div>
);

export const OnboardTechnicianView = () => (
  <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
    <div className="col-span-2 sm:col-span-4">
      <ConditionSummaryCard />
    </div>
    <EquipmentListCard />
    <ScanQrCard />
    <WorkOrdersCard />
    <ReportsCard />
  </div>
);

/* ----- ALMACO internal roles ----- */

// Add here when additional designs become available
