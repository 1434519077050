import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon as BackIcon } from "@heroicons/react/24/outline";

interface PageTitleWithOptionalButtonsProps {
  OptionalRightSideButton?: JSX.Element;
  title: string;
  showBackButton?: boolean;
}

export const PageTitleWithOptionalButtons = ({
  OptionalRightSideButton,
  showBackButton = true,
  title,
}: PageTitleWithOptionalButtonsProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between text-secondary px-2 pt-3">
      <div className="icon-size-default shrink-0">
        {showBackButton ? (
          <button onClick={() => navigate(-1)} aria-label="back">
            <BackIcon className="icon-size-default shrink-0" />
          </button>
        ) : (
          <span id="left button placeholder"></span>
        )}
      </div>
      <h5 className="hyphens-auto mt-1">{title}</h5>
      <div className="icon-size-default shrink-0 overflow-hidden">
        {OptionalRightSideButton ? (
          OptionalRightSideButton
        ) : (
          <span id="right button placeholder"></span>
        )}
      </div>
    </div>
  );
};
