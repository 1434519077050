import { useEffect, useState } from "react";
import { Alert, Button, Link, Typography } from "@mui/material";
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";

import { QueueRecord } from "@smartship-services/core";
import {
  deleteData,
  useAuthentication,
  useQueueList,
} from "@smartship-services/core/hooks";
import { api, subscribe, unsubscribe } from "@smartship-services/core/utils";

import { HelpButton } from "@smartship-services/ui/buttons";
import { QueueDisplay } from "./QueueDisplay";

const SyncQueuePage = () => {
  const queue = useQueueList();
  const [rejectedRequests, setRejectedRequests] = useState<QueueRecord[]>([]);
  const [pendingRequests, setPendingRequests] = useState<QueueRecord[]>([]);
  const [rejectedHref, setRejectedHref] = useState("");
  const [pendingHref, setPendingHref] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { apiToken } = useAuthentication();

  const handleDelete = async (id: string) => {
    if (!id) {
      return;
    }

    try {
      await deleteData("queue", id);
    } catch (err) {
      setErrorMessage("Error deleting queued change");
      console.error(err);
    }
  };

  const generateRejectedJson = (rejected: QueueRecord[]) => {
    if (!rejected || rejected.length === 0) {
      return;
    }
    try {
      const rejectedJson = new Blob([JSON.stringify(rejected)], {
        type: "application/json",
      });
      const href = URL.createObjectURL(rejectedJson);
      setRejectedHref(href);
    } catch (err) {
      console.error("error generating file for rejected requests", err);
    }
  };

  const generatePendingJson = (pending: QueueRecord[]) => {
    if (!pending || pending.length === 0) {
      return;
    }
    try {
      const pendingJson = new Blob([JSON.stringify(pending)], {
        type: "application/json",
      });
      const href = URL.createObjectURL(pendingJson);
      setPendingHref(href);
    } catch (err) {
      console.error("error generating file for pending requests", err);
    }
  };

  useEffect(() => {
    if (!queue || queue.length === 0) {
      setRejectedRequests([]);
      setPendingRequests([]);
      return;
    }

    const rejected: QueueRecord[] = [];
    const allOther = queue.filter((request) => {
      if (request.status === "rejected") {
        rejected.push(request);
        return false;
      }

      return true;
    });

    if (rejected.length > 0) {
      generateRejectedJson(rejected);
    }

    if (allOther.length > 0) {
      generatePendingJson(allOther);
    }

    setRejectedRequests(rejected);
    setPendingRequests(allOther);
  }, [queue]);

  useEffect(() => {
    const syncErrorHandler = ((e: CustomEvent) => {
      if (e.detail) {
        setErrorMessage(e.detail);
      } else {
        setErrorMessage("Unknown sync error");
      }
    }) as EventListener;

    subscribe("syncError", syncErrorHandler);

    return () => {
      unsubscribe("syncError", syncErrorHandler);

      if (rejectedHref) {
        URL.revokeObjectURL(rejectedHref);
      }
      if (pendingHref) {
        URL.revokeObjectURL(pendingHref);
      }
    };
  }, [pendingHref, rejectedHref]);

  return (
    <main>
      <Typography variant="h1">
        Sync Queue
        <HelpButton
          content={
            <div>
              <p>
                Cloud data sync happens automatically every 5 minutes, unless
                the app is in offline mode or the network isn't connected. You
                can also manually sync any time by clicking the blue sync button
                below, or in the top bar by opening the cloud menu and clicking
                on the current sync status.
              </p>
              <p>Photo uploads are included in automatic sync.</p>
            </div>
          }
        />
      </Typography>
      {!apiToken && pendingRequests.length ? (
        <Alert severity="warning">
          Cannot upload changes. Not logged in or login expired
        </Alert>
      ) : null}
      {errorMessage ? (
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      ) : null}
      {rejectedRequests.length > 0 ? (
        <section style={{ marginBottom: "2em", marginTop: "2em" }}>
          <Typography variant="h2">Rejected Changes</Typography>
          <Typography>
            These changes were rejected by the server, for example due to data
            validation errors. You can tap on 'rejected' to see an error message
            and/or{" "}
            <Link href={rejectedHref} download="rejectedChanges.json">
              download the list
            </Link>{" "}
            to backup the data and assist IT in troubleshooting.
          </Typography>
          <QueueDisplay handleDelete={handleDelete} list={rejectedRequests} />
        </section>
      ) : null}
      <section style={{ marginBottom: "2em", marginTop: "2em" }}>
        <Typography variant="h2">
          Pending Changes
          <Button
            color="primary"
            startIcon={<CloudArrowUpIcon className="icon-size-default" />}
            onClick={api.sync}
            sx={{ ml: 1 }}
          >
            Sync now
          </Button>
        </Typography>
        {pendingRequests.length > 0 ? (
          <Typography>
            If there's trouble with syncing changes, you can{" "}
            <Link href={pendingHref} download="pendingChanges.json">
              download the list
            </Link>{" "}
            to backup the data and assist IT in troubleshooting.
          </Typography>
        ) : null}
        <QueueDisplay handleDelete={handleDelete} list={pendingRequests} />
      </section>
      <footer style={{ bottom: 0, marginTop: "5em", marginBottom: "1em" }}>
        App version {process.env.REACT_APP_VERSION_NUMBER || "unknown"}
      </footer>
    </main>
  );
};

export default SyncQueuePage;
