import { ReactNode, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EquipmentField, EquipmentRecord } from "@smartship-services/core";
import {
  deleteData,
  getSerialFromQr,
  modifyData,
  useAuthentication,
  useData,
} from "@smartship-services/core/hooks";
import { PageTitleWithOptionalButtons } from "@smartship-services/ui/layout";
import { SelectWorkOrder } from "@smartship-services/ui/forms";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { EquipmentEditForm } from "@smartship-services/ui/forms";

const EditWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <main className="m-auto max-w-6xl">
      <div className="border border-funnel40 bg-neutral accent-top">
        <PageTitleWithOptionalButtons title="Edit Equipment" />
        {children}
      </div>
    </main>
  );
};

const EditEquipmentPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthentication();
  const canModify = currentUser?.permissions?.includes("mains:vessels:write");

  const { equipmentId = "" } = useParams();
  const equipment = useData<EquipmentRecord>("equipment", equipmentId);

  const [activeWorkOrder, setActiveWorkOrder] = useState<string | null>(null);
  const [pendingChanges, setPendingChanges] = useState<
    Partial<EquipmentRecord>
  >({});
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (key: string, newValue: EquipmentField | undefined) => {
    setPendingChanges((prev) => ({ ...prev, [key]: newValue }));

    if (key === "apId") {
      const matchingSerial = getSerialFromQr(newValue as string);
      if (matchingSerial) {
        setPendingChanges((prev) => ({
          ...prev,
          almacoSerialNumber: matchingSerial,
        }));
      }
    }
  };

  const skipWorkOrder = (): boolean => {
    if (activeWorkOrder) {
      return false;
    }

    const cantSkipWorkOrder =
      !currentUser?.permissions?.includes("mains:admin:read");
    if (cantSkipWorkOrder) {
      setErrorMessage(
        "Please set an active work order to associate with these changes.",
      );
      return true;
    }
    const confirmedSkip = window.confirm(
      "With no active work order, your changes will not appear in any report. Continue?",
    );

    return confirmedSkip;
  };

  const saveChanges = async () => {
    setErrorMessage("");
    if (Object.keys(pendingChanges).length === 0) {
      setErrorMessage("No pending changes to save");
      return;
    }

    if (!equipment) {
      setErrorMessage("Error finding equipment to edit.");
      return;
    }

    if (skipWorkOrder()) {
      return;
    }

    try {
      await modifyData("equipment", equipmentId, {
        ...pendingChanges,
        vesselId: equipment.vesselId,
      });
      navigate(-1);
    } catch (err) {
      console.error(err);
      setErrorMessage("Error saving data.");
    }
  };

  const handleDelete = async () => {
    if (!equipment) {
      setErrorMessage("Error finding equipment to delete.");
      return;
    }

    const isDeleteConfirmed = window.confirm(
      "Permanently delete this equipment?",
    );
    if (!isDeleteConfirmed) {
      return;
    }

    try {
      await deleteData<EquipmentRecord>("equipment", equipment.id);
    } catch (err) {
      console.error(err);
      setErrorMessage("Error when deleting equipment " + equipment.id);
    }
  };

  if (!canModify) {
    return (
      <EditWrapper>
        <p className="m-3">Missing permission to edit equipment</p>
      </EditWrapper>
    );
  }

  if (!equipment) {
    return (
      <EditWrapper>
        <p className="m-3">No equipment with this ID found</p>
      </EditWrapper>
    );
  }

  return (
    <EditWrapper>
      <EquipmentEditForm
        saveHandler={saveChanges}
        changeHandler={handleChange}
        deleteHandler={handleDelete}
        equipment={equipment}
        errorMessage={errorMessage}
      >
        <div
          role="alert"
          className={`alert mb-3 max-w-xl border ${
            activeWorkOrder ? "" : "border border-warning"
          }`}
        >
          <div>
            {activeWorkOrder ? (
              <p className="mb-2">
                Activity will be included in the report for this work order:
              </p>
            ) : (
              <p className="mb-2">
                <ExclamationTriangleIcon className="icon-size-default text-warning inline mr-2" />
                No active work order. To include activity in reports, please
                select one:
              </p>
            )}
            <SelectWorkOrder
              isUsingSession={true}
              setter={setActiveWorkOrder}
              vesselId={equipment.vesselId}
            />
          </div>
        </div>
      </EquipmentEditForm>
    </EditWrapper>
  );
};

export default EditEquipmentPage;
