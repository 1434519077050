import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { Stack, ToggleButton, Typography } from "@mui/material";
import { PlaylistAdd as PlaylistAddIcon } from "@mui/icons-material";

import { WorkOrderCategories, WorkOrderRecord } from "@smartship-services/core";
import { writeData } from "@smartship-services/core/hooks";
import {
  DateField,
  MultipleSelect,
  QuickParagraphField,
  QuickTextField,
  SelectVessel,
} from "@smartship-services/ui/forms";
import {
  HelpButton,
  ResetButton,
  SaveButton,
} from "@smartship-services/ui/buttons";
import { SelectableEquipmentList } from "../WorkOrder/WorkOrderDetails";

const defaultWorkOrderFields: WorkOrderRecord = {
  id: "",
  categories: [],
  customerName: "",
  equipmentIds: [],
  freeComment: "",
  onboardContactName: "",
  plannedStartDate: null,
  serviceEngineers: [],
  status: "planned",
  vesselId: "",
};

const workOrderCategories = ["inventory", "inspection", "maintenance", "other"];

const CreateWorkOrderPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isShowingVesselEquipment, setIsShowingVesselEquipment] =
    useState(false);
  const [workOrderForm, setWorkOrderForm] = useState<WorkOrderRecord>(
    defaultWorkOrderFields,
  );

  //independently handled form fields
  const [equipmentIds, setEquipmentIds] = useState<string[]>([]);
  const [vesselId, setVesselId] = useState("");

  const handleFormValues = (
    field: string,
    newValue: Date | string | string[] | null,
  ) => {
    //TODO: form field validation
    setWorkOrderForm((prev) => {
      const updated = { ...prev };
      updated[field] = newValue;
      updated.vesselId = vesselId;
      sessionStorage.setItem("work-order-form", JSON.stringify(updated));
      return updated;
    });
  };

  const saveForm = () => {
    const draftForm = {
      ...workOrderForm,
      equipmentIds,
      vesselId,
      status: "planned",
      id: uuid(),
    };
    try {
      writeData("workOrders", draftForm);
      resetForm(true);
      navigate(`/work-orders/${draftForm.id}`);
    } catch (err) {
      alert("Error saving work order");
      console.error(err);
    }
  };

  const resetForm = (skipSure = false) => {
    if (!skipSure) {
      const isSure = window.confirm(
        "Are you sure you want to empty all form fields?",
      );
      if (!isSure) {
        return;
      }
    }

    setWorkOrderForm((prev) => {
      const emptied = { ...prev };
      Object.keys(emptied).forEach((key) => {
        if (key !== "vesselId") {
          emptied[key] = defaultWorkOrderFields[key];
        }
      });
      return emptied;
    });
    setEquipmentIds([]);
    sessionStorage.removeItem("work-order-form");
  };

  useEffect(() => {
    if (vesselId && vesselId !== workOrderForm.vesselId) {
      setEquipmentIds([]);
    }
  }, [vesselId, workOrderForm.vesselId]);

  useEffect(() => {
    //start with category from uri if provided
    const initialCategory = searchParams.get("category") as WorkOrderCategories;
    if (initialCategory) {
      setWorkOrderForm((prev) => ({ ...prev, categories: [initialCategory] }));
      setSearchParams({});
    }

    //restore (and prefer) form values from session if exists
    const sessionWorkOrder = sessionStorage.getItem("work-order-form");
    if (sessionWorkOrder) {
      const parsedSessionWorkOrder = JSON.parse(sessionWorkOrder);
      setWorkOrderForm((prev) => ({ ...prev, ...parsedSessionWorkOrder }));
    }
  }, [searchParams, setSearchParams]);

  // noinspection SqlDialectInspection
  return (
    <main>
      <Typography variant="h1">Create Work Order</Typography>
      <Stack spacing={1.5} maxWidth={600} sx={{ mb: 2, mt: 2 }}>
        <SelectVessel setter={(newId) => setVesselId(newId || "")} />
        <MultipleSelect
          label="Categories"
          options={workOrderCategories}
          setter={(newValue) => handleFormValues("categories", newValue)}
          value={workOrderForm.categories || []}
        />
        <QuickTextField
          label="Customer Name"
          setter={(newValue) => handleFormValues("customerName", newValue)}
          value={workOrderForm.customerName}
        />
        <DateField
          label="Planned Start Date"
          setter={(newValue) => handleFormValues("plannedStartDate", newValue)}
          value={workOrderForm.plannedStartDate}
        />
        <QuickTextField
          label="Onboard Contact"
          setter={(newValue) =>
            handleFormValues("onboardContactName", newValue)
          }
          value={workOrderForm.onboardContactName}
        />
        <QuickTextField
          label="Service Engineers"
          setter={(newValue) =>
            handleFormValues("serviceEngineers", newValue.split(", "))
          }
          value={workOrderForm.serviceEngineers.join(", ")}
        />
        <QuickParagraphField
          label="Notes"
          setter={(newValue) => handleFormValues("freeComment", newValue)}
          value={workOrderForm.freeComment}
        />
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <SaveButton handler={() => saveForm()} />
          <ResetButton handler={() => resetForm()} />
        </Stack>
        <Stack direction="row" spacing={1}>
          <ToggleButton
            color="primary"
            disabled={!vesselId}
            selected={isShowingVesselEquipment}
            onChange={() => setIsShowingVesselEquipment((prev) => !prev)}
            value="Show equipment list"
          >
            <PlaylistAddIcon sx={{ mr: 1 }} />
            {vesselId ? "Select equipment from vessel" : "No vessel selected"}
          </ToggleButton>
          <HelpButton
            content={
              <Typography>
                Click checkboxes to select items. Equipment can be selected and
                removed now or later
              </Typography>
            }
          />
        </Stack>
      </Stack>
      {isShowingVesselEquipment ? (
        <SelectableEquipmentList
          setSelectedIds={setEquipmentIds}
          selectedIds={equipmentIds || []}
          vesselId={vesselId}
        />
      ) : null}
    </main>
  );
};
export default CreateWorkOrderPage;
