import { LightBulbIcon } from "@heroicons/react/24/outline";
import { EquipmentRecord } from "@smartship-services/core";
import { useAuthentication, useData } from "@smartship-services/core/hooks";
import {
  EquipmentConditionIcon,
  EquipmentDetails,
  EquipmentDisplay,
} from "@smartship-services/ui";
import { AccentedAccordion, PhotoGallery } from "@smartship-services/ui/layout";
import { Link, useParams } from "react-router-dom";
import { EquipmentHistory } from "./EquipmentHistory";
import { ContentAndButtonCard } from "@smartship-services/ui/cards";

const EquipmentPage = () => {
  const { equipmentId = "" } = useParams();
  const equipment = useData<EquipmentRecord>("equipment", equipmentId);
  const { currentUser } = useAuthentication();
  const hasEditPermission = currentUser?.permissions?.includes(
    "mains:vessels:write",
  );

  if (!equipment) {
    return (
      <EquipmentDisplay
        equipment={equipment}
        hasEditPermission={hasEditPermission}
      >
        <span>No equipment found with this ID.</span>
      </EquipmentDisplay>
    );
  }
  return (
    <EquipmentDisplay
      equipment={equipment}
      hasEditPermission={hasEditPermission}
    >
      <div className="flex flex-col items-center">
        <div className="w-full max-w-lg text-sm">
          <p className="font-bold text-secondary montserrat uppercase">
            Equipment Info
          </p>
          <EquipmentDetails equipment={equipment} />
        </div>
        <div className="grid grid-cols-2 gap-3 w-full max-w-xs my-5">
          <div className="tooltip" data-tip="Not yet available">
            <ContentAndButtonCard
              disabled={true}
              title="Manuals"
              path="manuals"
            >
              <LightBulbIcon className="text-secondary w-full p-4" />
            </ContentAndButtonCard>
          </div>

          <ContentAndButtonCard
            disabled={!hasEditPermission}
            title="Condition"
            path="condition"
          >
            <EquipmentConditionIcon
              condition={equipment?.condition}
              sizeClass="w-full p-4"
            />
          </ContentAndButtonCard>
          {!hasEditPermission ? (
            <Link
              to="alert"
              className="col-span-2 btn btn-primary w-full max-w-sm"
            >
              Make an Alert
            </Link>
          ) : null}
        </div>
        <div className="w-full">
          <AccentedAccordion title="Photos" isMountedWhenCollapsed={false}>
            <PhotoGallery equipmentId={equipment.id} />
          </AccentedAccordion>
          <AccentedAccordion title="History">
            <EquipmentHistory equipment={equipment} />
          </AccentedAccordion>
        </div>
      </div>
    </EquipmentDisplay>
  );
};

export default EquipmentPage;
