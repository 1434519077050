import { useState } from "react";
import {
  Alert,
  Button,
  Drawer,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";

import { QrReader } from "./QrReader";
import { ScanQrCodeIcon } from "../../assets/icons";

export { useQrReader } from "./useQrReader";

export const extractApid = (uri: string) => {
  if (uri?.includes("=")) {
    return uri.split("=")[1];
  } else {
    return uri;
  }
};

interface QrReaderDrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  resultCallback: (arg0: string) => void;
}

export const QrReaderDrawer = ({
  closeDrawer,
  isOpen,
  resultCallback,
}: QrReaderDrawerProps) => {
  const [resultText, setResultText] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);

  const handleToastClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsToastOpen(false);
  };

  const successHandler = (qrResultText: string) => {
    setResultText(qrResultText);
    setIsToastOpen(true);

    resultCallback(qrResultText);

    closeDrawer();
  };

  return (
    <>
      <Drawer anchor="bottom" onClose={closeDrawer} open={isOpen}>
        <Button onClick={closeDrawer}>Close</Button>
        <QrReader onSuccess={successHandler} />
      </Drawer>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        autoHideDuration={6000}
        onClose={handleToastClose}
        open={isToastOpen}
      >
        <Alert onClose={handleToastClose} severity="success">
          QR code result:
          <br />
          <a href={resultText} target="_blank" rel="noopener noreferrer">
            {resultText}
          </a>
        </Alert>
      </Snackbar>
    </>
  );
};

//standalone button that can open the QR reader and give result to any callback
interface QrReaderButtonProps {
  resultCallback: (arg0: string) => void;
}

export const QrReaderButton = ({ resultCallback }: QrReaderButtonProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (openState: boolean) => {
    setIsDrawerOpen(openState);
  };

  return (
    <>
      <Tooltip
        arrow={true}
        title="Open QR code reader"
        enterTouchDelay={0}
        leaveTouchDelay={5000}
      >
        <IconButton
          aria-label="Open QR code reader"
          onClick={() => toggleDrawer(true)}
        >
          <ScanQrCodeIcon className="icon-size-default" />
        </IconButton>
      </Tooltip>
      <QrReaderDrawer
        closeDrawer={() => toggleDrawer(false)}
        isOpen={isDrawerOpen}
        resultCallback={resultCallback}
      />
    </>
  );
};

//form field with integrated QR reader, state to be managed externally
interface QrReaderFormFieldProps {
  setter: (arg0: string) => void;
  value: string;
}

export const QrReaderFormField = ({
  setter,
  value,
}: QrReaderFormFieldProps) => (
  <TextField
    fullWidth
    id="qr-content-field"
    label="QR Code APID"
    onChange={(e) => setter(e.target.value)}
    value={value}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <QrReaderButton
            resultCallback={(result) => setter(extractApid(result))}
          />
        </InputAdornment>
      ),
    }}
  />
);
