import {
  type ChangeEvent,
  type MouseEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AdjustmentsHorizontalIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { IdAndLabel } from "@smartship-services/core";
import {
  asString,
  compareByCondition,
  compareByKey,
} from "@smartship-services/core/utils";
import { Checkboxes } from "./Checkboxes";
import Fuse from "fuse.js";
import { useSearchParams } from "react-router-dom";

const borderColor = "border-[#DBDCDC]"; // Funnel 40
const focusBorderColor = "focus:border-[#DBDCDC]";

interface IdAndLabelWithMultiselect extends IdAndLabel {
  includeMultiselect?: boolean;
}

interface SearchBoxProps<T> {
  className?: string;
  defaultOrder?: Order;
  defaultSortBy?: string;
  filterableFields?: IdAndLabelWithMultiselect[];
  list: T[];
  setList: (arg0: T[]) => void;
  sortableFields?: IdAndLabel[];
}

type Order = "asc" | "desc";
type Filters = Record<string, string>;
type Multiselect = Record<string, Array<string> | undefined>;

const SortIcon = ({ order }: { order: Order }) =>
  order === "asc" ? (
    <BarsArrowDownIcon className="icon-size-default" />
  ) : (
    <BarsArrowUpIcon className="icon-size-default" />
  );

const applyFilters = <T extends Record<string, unknown>>(
  list: T[],
  fullTextSearchTerm: string,
  fieldSearchTerms: Filters,
  multiselections: Multiselect,
) => {
  let searchResults = list;

  if (fullTextSearchTerm) {
    searchResults = new Fuse(list, {
      keys: Object.keys(list[0] || {}),
    })
      .search(fullTextSearchTerm)
      .map(({ item }) => item);
  }

  const filteredList = searchResults.filter((item) => {
    // Apply field specific filters
    const unmatchedFieldFilters = Object.entries(fieldSearchTerms).find(
      ([key, searchTerm]) =>
        !asString(item[key]).toLowerCase().includes(searchTerm.toLowerCase()),
    );
    if (unmatchedFieldFilters) {
      return false;
    }

    // Apply field specific multiselections
    const unmatchedMultiselection = Object.entries(multiselections).find(
      ([key, selections]) =>
        selections && !selections.includes(asString(item[key])),
    );
    if (unmatchedMultiselection) {
      return false;
    }

    return true;
  });

  return filteredList;
};

/*
  Supports full text search of all fields by default
  Filtering and/or sorting individual fields enabled if given a list of fields
*/
export const SearchBox = <T extends Record<string, unknown>>({
  className = "",
  defaultOrder = "asc",
  defaultSortBy = "",
  filterableFields = [],
  list,
  setList,
  sortableFields = [],
}: SearchBoxProps<T>) => {
  const [areFiltersDisplayed, setAreFiltersDisplayed] = useState(false);
  const [sortBy, setSortBy] = useState<string>(defaultSortBy);
  const [order, setOrder] = useState<Order>(defaultOrder);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("q") || "");
  const [filters, setFilters] = useState<Filters>({});
  const [multiselect, setMultiselect] = useState<Multiselect>({});

  const filteredList = useMemo(
    () => applyFilters<T>(list, searchTerm, filters, multiselect),
    [list, searchTerm, filters, multiselect],
  );

  const activeFilters = Object.values(filters).filter((x) => x).length;
  const activeMultiselections = Object.values(multiselect).filter(
    (x) => x,
  ).length;
  const areFiltersActive = Boolean(activeFilters || activeMultiselections);

  const clearSearch = () => setSearchTerm("");
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setSearchParams({ q: e.target.value });
  };
  const clearFilters = () => {
    setFilters({});
    setMultiselect({});
  };
  const handleFilters = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.id.split("-")[0];
    setFilters((prev) => ({ ...prev, [key]: e.target.value }));
  };
  const handleMultiselect = (key: string, selection?: string[]) => {
    setMultiselect((prev) => ({ ...prev, [key]: selection }));
  };

  const toggleFilterDisplay = () => setAreFiltersDisplayed((prev) => !prev);

  const changeSortBy = (e: MouseEvent<HTMLButtonElement>) => {
    const newSortBy = e.currentTarget.id.split("-")[0];
    const newOrder = newSortBy === sortBy && order === "asc" ? "desc" : "asc";
    setSortBy(newSortBy);
    setOrder(newOrder);
  };

  useEffect(() => {
    // Send filtered and/or sorted list to parent component
    if (sortBy === "condition") {
      setList([...filteredList].sort(compareByCondition(order)));
    } else {
      setList([...filteredList].sort(compareByKey(sortBy, order)));
    }
  }, [filteredList, sortBy, order, setList]);

  return (
    <div className={className}>
      <div className="join w-full shadow">
        <label htmlFor="searchbox" className="relative w-full">
          <span className="absolute inset-y-0 left-0 pl-2 flex items-center z-[1]">
            <MagnifyingGlassIcon className="icon-size-default text-secondary" />
          </span>
          <input
            id="searchbox"
            aria-label="full text search"
            name="searchbox"
            type="text"
            className={`join-item input input-bordered ${borderColor} ${focusBorderColor} w-full px-10`}
            onChange={handleSearch}
            value={searchTerm}
          />
          <button
            className={`absolute inset-y-0 right-0 btn btn-square btn-ghost ${
              searchTerm ? "" : "hidden"
            }`}
            aria-label="clear search"
            onClick={clearSearch}
          >
            <XMarkIcon className="icon-size-default" />
          </button>
        </label>
        {filterableFields.length || sortableFields.length ? (
          <button
            className={`join-item btn btn-square btn-outline border ${borderColor} bg-base-100 relative`}
            aria-label="toggle filters"
            onClick={toggleFilterDisplay}
          >
            {areFiltersActive ? (
              <div className="badge badge-xs badge-primary absolute top-2 right-2">
                <span className="sr-only">filters active</span>
              </div>
            ) : null}
            <AdjustmentsHorizontalIcon className="icon-size-default" />
          </button>
        ) : null}
      </div>
      <div
        className={`border ${borderColor} border-t-0 grid grid-cols-1 sm:grid-cols-2 gap-2 px-2 bg-base-100 overflow-y-auto transition-all duration-500 ${
          areFiltersDisplayed
            ? "max-h-96 border-b-1 py-2 shadow-md"
            : "max-h-0 border-b-0"
        }`}
        style={{ scrollbarWidth: "none" }}
      >
        {filterableFields.length ? (
          <div className="sm:col-span-2 flex justify-center my-2">
            <button
              className="btn btn-warning btn-outline btn-sm w-full sm:max-w-sm"
              onClick={clearFilters}
            >
              Clear Filters
            </button>
          </div>
        ) : null}
        {filterableFields.map((field) => (
          <div key={field.id}>
            <label className="form-control">
              <div className="label pb-0">
                <span className="label-text">{field.label || field.id}</span>
              </div>
              <input
                id={`${field.id}-filter`}
                className="input input-bordered"
                onChange={handleFilters}
                value={filters[field.id] || ""}
              />
            </label>
            {field.includeMultiselect ? (
              <Checkboxes<T>
                id={field.id}
                list={list}
                resetTrigger={!areFiltersActive}
                setSelection={handleMultiselect}
              />
            ) : null}
          </div>
        ))}
        {sortableFields.length ? (
          <div
            className="sm:col-span-2 flex flex-wrap justify-center gap-2"
            data-testid="searchbox-sort-area"
          >
            {sortableFields.map((field) => (
              <button
                id={`${field.id}-sortbutton`}
                key={field.id}
                onClick={changeSortBy}
                className={`btn btn-ghost border border-neutral shadow-md ${
                  sortBy === field.id ? "btn-active" : ""
                }`}
              >
                <span className="font-medium">{field.label || field.id}</span>
                {sortBy === field.id ? (
                  <SortIcon order={order} />
                ) : (
                  <SortIcon order="asc" />
                )}
              </button>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
