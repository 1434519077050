import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

export type AlertType = "success" | "error" | "warning" | "info";

const colorByAlertType = {
  info: "alert-info",
  error: "alert-error",
  success: "alert-success",
  warning: "alert-warning",
};

interface AlertIconProps {
  type: AlertType;
  classIcon?: string;
}

const AlertIcon = ({ type, classIcon = "h-6 w-6" }: AlertIconProps) => {
  switch (type) {
    case "info":
      return <InformationCircleIcon className={classIcon} />;
    case "error":
      return <XCircleIcon className={classIcon} />;
    case "success":
      return <CheckCircleIcon className={classIcon} />;
    case "warning":
      return <ExclamationTriangleIcon className={classIcon} />;
    default:
      return <InformationCircleIcon className={classIcon} />;
  }
};

export interface AlertProps {
  type: AlertType;
  message?: string;
  classIcon?: string;
  extraClass?: string;
}

export const Alert = ({
  type,
  message,
  classIcon,
  extraClass = "",
}: AlertProps) => {
  if (!message) {
    return null;
  }

  return (
    <div
      role="alert"
      className={`alert ${colorByAlertType[type] ?? ""} ${extraClass}`}
    >
      <AlertIcon type={type} classIcon={classIcon} />
      <span>{message}</span>
    </div>
  );
};
