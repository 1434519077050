import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { SaveAlt as SaveIcon } from "@mui/icons-material";
import { v4 as uuid } from "uuid";

import {
  EquipmentRecord,
  LocationRecord,
  VesselRecord,
} from "@smartship-services/core";
import {
  modifyData,
  useData,
  useSpreadsheetParser,
  writeDataList,
} from "@smartship-services/core/hooks";
import { asString, pushIfUnique } from "@smartship-services/core/utils";
import { HelpButton } from "../../buttons";
import { FileUploadField } from "..";
import { EquipmentImportTable } from "../..";

const instructions = {
  fileImport: (
    <Typography>
      Several common filetypes are supported:&nbsp;
      <u>.xlsx</u>, <u>.xls</u>, <u>.ods</u>, <u>.csv</u>, and plain text
    </Typography>
  ),
  columnNames: (
    <ul>
      <li>
        <Typography>
          For each column in the original spreadsheet, select an equivalent name
          from the database.
        </Typography>
      </li>
      <li>
        <Typography>
          If there are no good matches, choose <em>Custom</em> to use the column
          name from the spreadsheet.
        </Typography>
      </li>
      <li>
        <Typography>
          <em>Equipment Name</em> (*required) is needed for each item to have a
          readable name on lists.
        </Typography>
      </li>
    </ul>
  ),
  checkData: (
    <Typography>
      The goal is to automatically detect common problems like one row with a
      misspelled brand name.
    </Typography>
  ),
  upload: (
    <>
      <Typography>
        If online, the data will sent to the database immediately.
      </Typography>
      <Typography>
        If offline, the data will be saved locally and queued to be sent when
        reconnected.
      </Typography>
    </>
  ),
};

export const ImportEquipmentForm = ({ vesselId }: { vesselId: string }) => {
  const vesselData = useData<VesselRecord>("vessels", vesselId);
  const [data, parseSpreadsheet] = useSpreadsheetParser();
  const [columnNameMap, setColumnNameMap] = useState<string[]>([]);

  const handleSubmit = () => {
    if (!data || !vesselData) {
      return;
    }
    const processedData = [];
    const dataWithoutOriginalHeaders = data.slice(1);
    const standardColumnNames: string[] = vesselData?.standardColumnNames || [];
    const customColumnNames: string[] = [];

    const locationNames: string[] = [];
    const locationDrafts: LocationRecord[] = [];
    const draftLocation = (locationName: string, row: unknown[]) => {
      const draft: LocationRecord = {
        id: uuid(),
        locationName,
        vesselId,
      };
      const customerCodeIndex = columnNameMap.indexOf("locationIdFromCustomer");
      if (customerCodeIndex !== -1) {
        draft.idFromCustomer = row[customerCodeIndex] as string;
      }

      return draft;
    };

    for (const row of dataWithoutOriginalHeaders) {
      const equipment: EquipmentRecord = {
        custom: [],
        equipmentName: "equipment",
        id: uuid(),
        vesselId: vesselId,
      };

      for (const i in row) {
        const key = columnNameMap[i];
        switch (key) {
          case "custom": {
            const originalColumnName = data[0][i];
            pushIfUnique(customColumnNames, originalColumnName);
            equipment.custom.push([originalColumnName, row[i]]);
            break;
          }
          case "location": {
            pushIfUnique(standardColumnNames, key);
            const locationName = row[i] || "unknown";
            equipment[key] = locationName;
            const isUnique = pushIfUnique(locationNames, locationName);
            if (isUnique) {
              const draft = draftLocation(locationName, row);
              locationDrafts.push(draft);
              equipment.locationId = draft.id;
            } else {
              equipment.locationId = locationDrafts.filter(
                (draft) => draft.locationName === locationName,
              )[0].id;
            }
            break;
          }
          case "idFromCustomer": //same as next
          case "locationIdFromCustomer":
            pushIfUnique(standardColumnNames, key);
            equipment[key] = asString(row[i]);
            break;
          case "ignore":
            //skip ignored columns
            break;
          default:
            pushIfUnique(standardColumnNames, key);
            equipment[key] = row[i];
        }
      }

      //TODO: add error handling so user can address problems
      processedData.push(equipment);
    }

    const modifiedVesselData = {
      standardColumnNames,
      customColumnNames,
    };
    modifyData("vessels", vesselId, modifiedVesselData);
    writeDataList("locations", locationDrafts);
    writeDataList("equipment", processedData);
    alert("Saved!");
  };

  return vesselData ? (
    <main>
      <Typography variant="h1">Data Import</Typography>
      <Typography variant="h2">{vesselData.vesselName}</Typography>
      <Typography variant="h5">
        Step 1. Import a spreadsheet
        <HelpButton content={instructions.fileImport} />
      </Typography>
      <Box sx={{ m: 2.5 }}>
        <FileUploadField handler={(file) => parseSpreadsheet(file)} />
      </Box>
      <Typography variant="h5">
        Step 2. Match data to standard column names
        <HelpButton content={instructions.columnNames} />
      </Typography>
      <EquipmentImportTable
        handleNewHeaders={(values) => setColumnNameMap(values)}
        matrix={data}
      />
      <Typography variant="h5">
        Step 3. Check data for irregularities
        <HelpButton content={instructions.checkData} />
      </Typography>
      <Box sx={{ m: 2.5 }}>
        <Typography variant="body2">
          <em>In development.</em>
        </Typography>
      </Box>
      <Typography variant="h5">
        Step 4. Upload to database
        <HelpButton content={instructions.upload} />
      </Typography>
      <Box sx={{ m: 2.5 }}>
        <Button
          disabled={
            !data /*TODO: also disable until all column names selected*/
          }
          onClick={handleSubmit}
          startIcon={<SaveIcon />}
          variant="contained"
        >
          Confirm and save
        </Button>
      </Box>
    </main>
  ) : (
    <main>
      <Typography>No vessel found with this ID.</Typography>
    </main>
  );
};
