import { FC, useEffect, useMemo, useRef, useState } from "react";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";

import {
  rebuildSummaryFromActivity,
  getFileIdsFromEquipmentList,
  getSortedEquipmentFromWorkOrder,
  MAX_PHOTOS_PER_REPORT,
  splitEquipmentListByMaxPhotos,
} from "./reportUtils";

import {
  PhotoMap,
  processBlobForDocx,
  useDocx,
} from "@smartship-services/core/hooks";
import type {
  EquipmentRecord,
  WorkOrderRecord,
} from "@smartship-services/core";

import { api } from "@smartship-services/core/utils";
import { SelectWorkOrder, Alert } from "@smartship-services/ui/forms";
import { HelpButton } from "@smartship-services/ui/buttons";

const MAX_DOWNLOAD_BATCH = 10;

type StatusPerDocument = "unclicked" | "loading" | "clicked";

const CreateWorkOrderReport: FC = () => {
  const { generateDownloadLink, generateReport } = useDocx();
  const [activeWorkOrder, setActiveWorkOrder] =
    useState<WorkOrderRecord | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isGoodDisplayed, setIsGoodDisplayed] = useState(false);
  const [arePhotosDisplayed, setArePhotosDisplayed] = useState(false);
  const [conditionSource, setConditionSource] = useState<
    "current" | "activity"
  >("current");

  const photoCount = activeWorkOrder?.summary?.photoIds.length ?? 0;
  const allWorkOrderEquipment: EquipmentRecord[] = useMemo(() => {
    if (!activeWorkOrder) {
      return [];
    }
    const workOrderBySource =
      conditionSource === "current"
        ? activeWorkOrder
        : {
            ...activeWorkOrder,
            summary: rebuildSummaryFromActivity(activeWorkOrder?.summary),
          };
    return getSortedEquipmentFromWorkOrder(workOrderBySource, isGoodDisplayed);
  }, [activeWorkOrder, isGoodDisplayed, conditionSource]);
  // Visual indication for user of which reports they've already clicked
  const [statusPerDocument, setStatusPerDocument] = useState<
    StatusPerDocument[]
  >([]);
  const equipmentPerDocument = useMemo(() => {
    const splitEquipmentList = splitEquipmentListByMaxPhotos(
      allWorkOrderEquipment,
    );
    setStatusPerDocument(splitEquipmentList.map(() => "unclicked"));
    return splitEquipmentList;
  }, [allWorkOrderEquipment]);

  const [photoCountPerDocument, setPhotoCountPerDocument] =
    useState(photoCount);
  const [photoDownloadCount, setPhotoDownloadCount] = useState(0);

  const photoObjectURLCache = useRef<Record<string, string>>({});

  useEffect(() => {
    const cache = photoObjectURLCache.current;
    return () => {
      Object.values(cache).forEach((url) => URL.revokeObjectURL(url));
    };
  }, []);

  const downloadAllRelatedPhotos = async (
    photoIds: string[],
  ): Promise<PhotoMap> => {
    if (!photoIds?.length) {
      return {};
    }

    const photoMap: PhotoMap = {};

    const downloadPhoto = async (id: string) => {
      let photoObjectURL = photoObjectURLCache.current[id];

      if (!photoObjectURL) {
        const response = await api.download(id);
        if (response === "offline") {
          setErrorMessage("Photo download not available offline");
          throw new Error("Break loop: offline");
        }

        if (!response) {
          // No need to stop on single failure
          setErrorMessage("One or more photo downloads failed");
          return;
        }

        photoObjectURL = response;
        photoObjectURLCache.current[id] = response;
      }

      try {
        const photoBlob = await fetch(photoObjectURL).then((r) => r.blob());

        const processedBlob = await processBlobForDocx(photoBlob);

        if (processedBlob) {
          photoMap[id] = processedBlob;
        }
        setPhotoDownloadCount((count) => count + 1);
      } catch (err) {
        setErrorMessage("One or more downloaded photos could not be retrieved");
        return;
      }
    };

    try {
      for (let c = 0; c < photoIds.length; c += MAX_DOWNLOAD_BATCH) {
        const idsBatch = photoIds.slice(c, c + MAX_DOWNLOAD_BATCH);
        await Promise.all(idsBatch.map(downloadPhoto));
      }
    } catch (err) {
      console.error(err);
      setErrorMessage((err as Error).message || "Error downloading photos");
    }

    return photoMap;
  };

  const handleSelectWorkOrder = async (id: string | null) => {
    setErrorMessage("");
    setPhotoDownloadCount(0);

    if (!id) {
      setActiveWorkOrder(null);
      return;
    }

    try {
      const workOrder = (await api.getWorkOrderSummary(
        id,
      )) as WorkOrderRecord | null;
      setActiveWorkOrder(workOrder);
    } catch (err) {
      console.error(err);
      setActiveWorkOrder(null);
      setErrorMessage(
        (err as Error).message || "Error retrieving work order summary",
      );
    }
  };
  const handleGoodSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsGoodDisplayed(e.target.checked);
  };
  const handlePhotoSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArePhotosDisplayed(e.target.checked);
  };

  const updateStatusPerPart = (
    partNumber: number | undefined,
    newStatus: StatusPerDocument,
  ) => {
    if (partNumber === undefined) {
      return;
    }

    setStatusPerDocument((prev) => {
      const updatedStatuses = [...prev];
      updatedStatuses[partNumber] = newStatus;
      return updatedStatuses;
    });
  };

  const createReport = async (
    equipmentList: EquipmentRecord[],
    partNumber?: number,
  ) => {
    if (!activeWorkOrder) {
      setErrorMessage("No work order");
      return;
    }

    setErrorMessage("");
    setPhotoDownloadCount(0);
    setIsLoading(true);
    updateStatusPerPart(partNumber, "loading");

    try {
      const photosPerDocument = getFileIdsFromEquipmentList(equipmentList);
      setPhotoCountPerDocument(photosPerDocument.length);

      const photoMap = arePhotosDisplayed
        ? await downloadAllRelatedPhotos(photosPerDocument)
        : {};

      const report = generateReport(
        activeWorkOrder,
        equipmentList,
        { arePhotosDisplayed },
        photoMap,
      );

      const url = await generateDownloadLink(report);

      const a = document.createElement("a");
      a.href = url;
      a.download = `Work_order_${activeWorkOrder.id}.docx`;
      a.click();
      updateStatusPerPart(partNumber, "clicked");

      URL.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
      setErrorMessage("Error creating document");
      updateStatusPerPart(partNumber, "unclicked");
    }

    setIsLoading(false);
  };

  return (
    <>
      <h2>Work Order Inspection Report</h2>
      <Alert type="error" message={errorMessage} extraClass="max-w-3xl" />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 my-6 max-w-3xl">
        <SelectWorkOrder
          isUsingSession={false}
          setter={handleSelectWorkOrder}
        />
        <span className="self-center">
          Select a work order (requires network connection)
        </span>
        <div>
          <label className="label cursor-pointer justify-start gap-2">
            <input
              type="checkbox"
              name={"include-good-condition"}
              className="toggle toggle-primary"
              checked={isGoodDisplayed}
              onChange={handleGoodSwitch}
            />
            <span className="label-text">
              Include list of equipment in good condition
            </span>
          </label>
          <label
            className="label cursor-pointer justify-start gap-2"
            title={!activeWorkOrder ? "Please select a work order" : ""}
          >
            <input
              type="checkbox"
              name={"include-photos"}
              className="toggle toggle-primary"
              disabled={!activeWorkOrder}
              checked={arePhotosDisplayed}
              onChange={handlePhotoSwitch}
            />
            <span className="label-text">
              Include photos (count: {photoCount})
            </span>
          </label>
          {isLoading &&
          arePhotosDisplayed &&
          photoDownloadCount < photoCountPerDocument ? (
            <progress
              className="progress progress-primary max-w-sm"
              value={photoDownloadCount}
              max={photoCountPerDocument}
            ></progress>
          ) : null}
          {arePhotosDisplayed && photoCount > 10 ? (
            <p className="text-warning">
              Downloading many photos may use a large amount of data
            </p>
          ) : null}
          {arePhotosDisplayed && equipmentPerDocument.length > 1 ? (
            <Alert
              type="info"
              message={`Report will be split to avoid exceeding ${MAX_PHOTOS_PER_REPORT} photos per document`}
            />
          ) : null}
        </div>
        <div>
          <label className="label cursor-pointer justify-start gap-2">
            <input
              type="radio"
              name="radio_work-order-condition-source"
              className="radio radio-secondary checked:radio-primary"
              checked={conditionSource === "current"}
              onChange={() => setConditionSource("current")}
            />
            <span className="label-text">
              Show current condition and free comments
            </span>
          </label>
          <label className="label cursor-pointer justify-start gap-2">
            <input
              type="radio"
              name="radio_work-order-condition-source"
              className="radio radio-secondary checked:radio-primary"
              checked={conditionSource === "activity"}
              onChange={() => setConditionSource("activity")}
            />
            <span className="label-text nowrap">
              Show only work order condition and comments
              <HelpButton content="Currently only available for equipment inspected after 2024-02-22 using the new Condition form with active work order" />
            </span>
          </label>
        </div>
        <div>
          {arePhotosDisplayed && equipmentPerDocument.length > 1 ? (
            equipmentPerDocument.map((partialList, index) => (
              <button
                key={partialList[0].id}
                disabled={!activeWorkOrder || isLoading}
                className={`btn btn-outline w-full mb-2 ${statusPerDocument[index] === "clicked" ? "btn-secondary" : "btn-primary"}`}
                onClick={() => createReport(partialList, index)}
              >
                {statusPerDocument[index] === "loading" ? (
                  <span className="loading loading-spinner loading-sm"></span>
                ) : (
                  <DocumentPlusIcon className="icon-size-default" />
                )}
                {`${statusPerDocument[index] === "clicked" ? "Recreate" : "Create"} report (Part ${index + 1})`}
              </button>
            ))
          ) : (
            <button
              disabled={!activeWorkOrder || isLoading}
              className="btn btn-primary btn-outline w-full"
              onClick={() => createReport(allWorkOrderEquipment)}
            >
              {isLoading ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                <DocumentPlusIcon className="icon-size-default" />
              )}
              Create report
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateWorkOrderReport;
