import {
  Document,
  Footer,
  HeadingLevel,
  ImageRun,
  Packer,
  PageNumber,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
} from "docx";

import { EquipmentRecord, WorkOrderRecord } from "../../lib/types";

import {
  classificationBullets,
  classificationText,
  endingText,
  equipmentKeysToDisplay,
  footerText,
  purposeText,
  workOrderKeysToDisplay,
} from "./contentConfig";
import {
  borderNone,
  centerAligned,
  colorMap,
  documentStyle,
  fullWidth,
  halfWidth,
  MAX_PHOTO_DIMENSION,
} from "./styleConfig";
import {
  asString,
  isValidDate,
  justTheDate,
  limitScaleProportionally,
} from "../../utils";
import { mapConditionToVisuals } from "../..";

interface LocationFromApi {
  name: string;
  idFromCustomer: string;
} //Backend type differs a little from frontend

interface GenerateReportOptions {
  arePhotosDisplayed?: boolean;
}

interface PhotoBufferAndSize {
  data: ArrayBuffer;
  transformation: {
    height: number;
    width: number;
  };
}

export interface PhotoMap {
  [key: string]: PhotoBufferAndSize | null;
}

export const processBlobForDocx = async (
  photo: Blob,
): Promise<PhotoBufferAndSize | null> => {
  //For docx ImageRun, which requires photos in buffer format, width, and height

  try {
    //Bitmap allows us to get natural size of photo
    const bitmap = await createImageBitmap(photo);
    const [height, width] = limitScaleProportionally(
      [bitmap.height, bitmap.width],
      MAX_PHOTO_DIMENSION,
    );
    bitmap.close(); //Memory cleanup

    const data = await photo.arrayBuffer();
    return {
      data,
      transformation: {
        height,
        width,
      },
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};

const cellWithText = (content?: unknown) => {
  const text = Array.isArray(content) ? content.join(", ") : asString(content);
  return new TableCell({
    children: [new Paragraph(text || "")],
    ...borderNone,
    ...halfWidth,
  });
};

const cellWithDate = (date?: string | Date) => {
  const text = isValidDate(date) ? justTheDate(date as Date) : asString(date);
  return new TableCell({
    children: [new Paragraph(text || "")],
    ...borderNone,
    ...halfWidth,
  });
};

const cellWithCondition = (condition?: EquipmentRecord["condition"]) => {
  const { color, label } = mapConditionToVisuals(condition);

  return new TableCell({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: label,
            color: colorMap[color],
          }),
        ],
      }),
    ],
    ...borderNone,
    ...halfWidth,
  });
};

export const useDocx = () => {
  const generateReport = (
    workOrder: WorkOrderRecord,
    equipment: EquipmentRecord[],
    options: GenerateReportOptions,
    photoMap: PhotoMap,
  ) => {
    const workOrderDetails = new Table({
      rows: Object.entries(workOrderKeysToDisplay).map(
        ([key, label]) =>
          new TableRow({
            children: [
              cellWithText(label),
              key.toLowerCase().includes("date")
                ? cellWithDate(workOrder[key] as Date)
                : cellWithText(workOrder[key]),
            ],
          }),
      ),
      ...fullWidth,
    });

    const purpose = purposeText.map(
      (line) =>
        new Paragraph({
          text: line,
          spacing: {
            after: 100,
          },
        }),
    );

    const classification = [
      ...classificationText.map((line) => new Paragraph(line)),
      ...classificationBullets.map(
        ([label, detail]) =>
          new Paragraph({
            text: label + ": " + detail,
            bullet: {
              level: 0,
            },
          }),
      ),
    ];

    const equipmentPhotoGallery = (photoIds: string[]) =>
      new Paragraph({
        children: photoIds
          .filter((id) => !!photoMap[id])
          .map((id) => new ImageRun(photoMap[id] as PhotoBufferAndSize)),
      });

    const equipmentList = (equipmentList: EquipmentRecord[]) => {
      const list: Array<Paragraph | Table> = [];

      for (const equipment of equipmentList) {
        //Name
        list.push(
          new Paragraph({
            text: equipment.name as string,
            heading: HeadingLevel.HEADING_4,
          }),
        );
        const detailRows: TableRow[] = [];

        //Location
        const location =
          equipment.location as unknown as LocationFromApi | null;
        detailRows.push(
          new TableRow({
            children: [
              cellWithText("Location"),
              cellWithText(location?.name || "not specified"),
            ],
          }),
        );

        //Condition
        detailRows.push(
          new TableRow({
            children: [
              cellWithText("Traffic Light Condition"),
              cellWithCondition(equipment.condition),
            ],
          }),
        );

        //Other details
        detailRows.push(
          ...Object.entries(equipmentKeysToDisplay)
            .filter(([key]) => !!equipment[key])
            .map(
              ([key, label]) =>
                new TableRow({
                  children: [
                    cellWithText(label),
                    key.toLowerCase().includes("date")
                      ? cellWithDate(equipment[key] as Date)
                      : cellWithText(equipment[key]),
                  ],
                }),
            ),
        );

        list.push(
          new Table({
            rows: detailRows,
            ...fullWidth,
          }),
        );

        //Photos
        if (options.arePhotosDisplayed) {
          list.push(
            equipmentPhotoGallery(
              equipment.fileMetadata?.map(({ id }) => id) || [],
            ),
          );
        }
      }

      return list;
    };

    const children = [
      new Paragraph({
        text: "INSPECTION REPORT",
        heading: HeadingLevel.HEADING_1,
      }),
      new Paragraph({
        text: "ALMACO GROUP",
        heading: HeadingLevel.HEADING_2,
      }),
      workOrderDetails,
      new Paragraph({
        text: "1. PURPOSE",
        heading: HeadingLevel.HEADING_3,
      }),
      ...purpose,
      new Paragraph({
        text: "2. CLASSIFICATION",
        heading: HeadingLevel.HEADING_3,
      }),
      ...classification,
      new Paragraph({
        text: "3. EQUIPMENT INSPECTED",
        heading: HeadingLevel.HEADING_3,
      }),
      ...equipmentList(equipment),
    ];

    //Ending text
    children.push(
      new Paragraph({
        widowControl: true,
        spacing: { before: 480 },
        children: [
          ...endingText.map((text) => new TextRun({ text, break: 2 })),
        ],
      }),
    );

    const footer = new Footer({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              children: [PageNumber.CURRENT],
              color: colorMap.secondary,
            }),
          ],
          ...centerAligned,
        }),
        new Paragraph({
          children: [
            new TextRun({ text: footerText, color: colorMap.secondary }),
          ],
          ...centerAligned,
        }),
      ],
    });

    const report = new Document({
      creator: "ALMACO",
      title: "Inspection report",
      sections: [
        {
          children,
          footers: {
            default: footer,
          },
        },
      ],
      styles: documentStyle,
    });

    return report;
  };

  const generateDownloadLink = async (doc: Document) => {
    const docBlob = await Packer.toBlob(doc);
    return URL.createObjectURL(docBlob);
  };

  return {
    generateDownloadLink,
    generateReport,
  };
};
