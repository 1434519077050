import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { VesselRecord } from "@smartship-services/core";

export const VesselList = ({
  vessels,
}: {
  vessels: VesselRecord[] | undefined;
}) => {
  return (
    <main>
      <Typography variant="h1">Vessels</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Vessel Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vessels?.map((each: VesselRecord) => (
              <TableRow hover key={each.id}>
                <TableCell>
                  <Button
                    component={RouterLink}
                    to={each.id}
                    variant="outlined"
                  >
                    View
                  </Button>
                </TableCell>
                <TableCell>{each.vesselName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </main>
  );
};
