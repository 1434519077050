import { VesselRecord } from "@smartship-services/core";
import {
  useAllVessels,
  useAppState,
  useDataList,
} from "@smartship-services/core/hooks";
import { PageTitleWithOptionalButtons } from "../../layout";
import { Alert, Select } from "..";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "@smartship-services/core/utils";

export const ImportVesselForm = () => {
  const { vessels, error: fetchAllVesselsError } = useAllVessels();
  const [selectedVessel, setSelectedVessel] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const navigate = useNavigate();
  const { changeVessel } = useAppState();

  // WebPortal ids of the vessels that have already been added to the database
  const alreadyAddedVessels = useDataList<VesselRecord>("vessels")
    ?.map((v) => Number(v.wpVesselId))
    .filter(Boolean);

  const options = vessels
    ?.map((vessel) => ({
      value: vessel.id,
      label: vessel.name,
    }))
    .filter(({ value }) => !alreadyAddedVessels?.includes(value));

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(undefined);
    setIsLoading(true);
    try {
      if (!selectedVessel) {
        return;
      }

      const createdVessel = await api.importVessel(selectedVessel);

      await api.sync();

      changeVessel(createdVessel.id);
      navigate(`/vessels/${createdVessel.id}`);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error("An unknown error occurred"));
      }
    }
    setIsLoading(false);
  };

  return (
    <form
      onSubmit={onSubmit}
      className="mx-auto max-w-2xl accent-top bg-neutral flex flex-col gap-4 p-4"
    >
      <PageTitleWithOptionalButtons
        title="Import Vessel"
        showBackButton={false}
      />
      <Select
        required
        name={"vessel"}
        options={options}
        onChange={(e) => setSelectedVessel(e?.value)}
      />
      <Alert
        type={"error"}
        message={(fetchAllVesselsError || error)?.message}
      />
      {selectedVessel ? (
        <p className={"alert info"}>
          Importing vessel and all associated equipment can take a few minutes.
        </p>
      ) : null}
      <button className="btn btn-primary grow" disabled={isLoading}>
        {isLoading ? (
          <span className="loading loading-spinner loading-sm"></span>
        ) : null}
        Import
      </button>
    </form>
  );
};
