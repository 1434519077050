import { Link, useLocation } from "react-router-dom";

import { useAppState } from "@smartship-services/core/hooks";
import { CollapsableMenu } from "./CollapsableMenu";
import { ScanQrCodeIcon, ShipIcon } from "../../assets/icons";
import { useQrReader } from "../../lib/QrReader/useQrReader";

export const AppBar = () => {
  const location = useLocation();
  const { openScanner, QrDrawer } = useQrReader();
  const { activeVessel } = useAppState();

  return (
    <div className="fixed bottom-0 lg:top-0 left-0 z-50 h-14 w-full bg-white shadow-[0px_0px_6px_#D2D2D2]">
      <div className="grid grid-cols-3 montserrat">
        <Link to="/" className="text-primary">
          <div className="h-14 flex justify-center items-center">
            <ShipIcon aria-label="Return to homepage" className="h-12 w-12" />
          </div>
        </Link>
        <div className="h-14 flex items-center justify-center">
          {location.pathname === "/" ? (
            <p
              className="text-center text-xs truncate"
              title={activeVessel?.vesselName || "No active vessel"}
            >
              {activeVessel?.vesselName || (
                <Link to="/vessels/active">No active vessel</Link>
              )}
            </p>
          ) : (
            <button onClick={openScanner} aria-label="Open QR code reader">
              <ScanQrCodeIcon className="h-10 w-10" />
            </button>
          )}
        </div>
        <CollapsableMenu />
      </div>
      <QrDrawer />
    </div>
  );
};
