import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Box, ToggleButton, Typography } from "@mui/material";
import { PlaylistAdd as PlaylistAddIcon } from "@mui/icons-material";

import {
  ACTIVE_WORK_ORDER_STORAGE_KEY,
  EquipmentField,
  EquipmentRecord,
  WorkOrderRecord,
} from "@smartship-services/core";
import {
  deleteData,
  getEquipmentListByIds,
  getVesselById,
  modifyData,
  useData,
} from "@smartship-services/core/hooks";
import {
  DeleteButton,
  SetActiveWorkOrderButton,
} from "@smartship-services/ui/buttons";
import { AlertToast } from "@smartship-services/ui/forms";
import { SelectableEquipmentList, WorkOrderDetails } from "./WorkOrderDetails";
import { WorkOrderEquipmentList } from "./EquipmentList";

const equipmentColumnNames = [
  "equipmentName",
  "condition",
  "location",
  "makerName",
  "model",
  "apId",
];

const WorkOrderPage = () => {
  const { workOrderId = "" } = useParams<{ workOrderId: string }>();
  const workOrder = useData<WorkOrderRecord>("workOrders", workOrderId);
  const [vesselName, setVesselName] = useState<string>();
  const [workOrderEquipment, setWorkOrderEquipment] = useState<
    EquipmentRecord[]
  >([]);
  const [currentEquipmentIds, setCurrentEquipmentIds] = useState<string[]>([]);
  const [isEditingEquipment, setIsEditingEquipment] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastColor, setToastColor] = useState<"success" | "error">("success");
  const [toastMessage, setToastMessage] = useState("");

  const handleOpenToast = (isError = false) => {
    if (isError) {
      setToastMessage("Error saving changes");
      setToastColor("error");
    } else {
      setToastMessage("Saved changes");
      setToastColor("success");
    }
    setIsToastOpen(true);
  };

  const handleModify = (key: string, newValue: EquipmentField) => {
    if (!workOrder?.id) {
      handleOpenToast(true);
      return;
    }

    try {
      modifyData<WorkOrderRecord>("workOrders", workOrderId, {
        [key]: newValue,
      });
      setIsEditingEquipment(false);
      handleOpenToast();
    } catch (err) {
      console.error(err);
      handleOpenToast(true);
    }
  };

  const handleSetActive = () => {
    sessionStorage.setItem(
      ACTIVE_WORK_ORDER_STORAGE_KEY,
      JSON.stringify({
        id: workOrderId,
        label: workOrderId + vesselName,
        vesselId: workOrder?.vesselId,
      }),
    );
    setIsActive(true);
  };
  const handleSetInactive = () => {
    sessionStorage.removeItem(ACTIVE_WORK_ORDER_STORAGE_KEY);
    setIsActive(false);
  };

  useEffect(() => {
    if (!workOrder?.equipmentIds) {
      setWorkOrderEquipment([]);
      setCurrentEquipmentIds([]);
      return;
    }

    getVesselById(workOrder?.vesselId)
      .then((vessel) => {
        setVesselName(vessel?.vesselName || workOrder.vesselId);
      })
      .catch((err) => {
        console.error(err);
        setVesselName(workOrder.vesselId);
      });

    setCurrentEquipmentIds(workOrder.equipmentIds);

    getEquipmentListByIds(workOrder.equipmentIds)
      .then((results) => {
        const parsedResults = results.map((equipment, i) => {
          if (!equipment) {
            return {
              id: workOrder.equipmentIds[i],
              equipmentName: "[missing or deleted equipment]",
            } as EquipmentRecord;
          } else {
            return equipment;
          }
        });
        setWorkOrderEquipment(parsedResults);
      })
      .catch((err) => {
        console.error(err);
        setWorkOrderEquipment([]);
      });
  }, [workOrder]);

  useEffect(() => {
    const sessionWorkOrder = sessionStorage.getItem(
      ACTIVE_WORK_ORDER_STORAGE_KEY,
    );
    const activeId = sessionWorkOrder ? JSON.parse(sessionWorkOrder).id : null;
    setIsActive(Boolean(activeId && activeId === workOrderId));
  }, [workOrderId]);

  return workOrder ? (
    <main id="main">
      <Typography variant="h1" sx={{ mb: 1 }}>
        {`Work Order #${workOrderId.slice(0, 8)}...`}
      </Typography>
      {isActive ? (
        <Alert onClose={handleSetInactive}>Active work order</Alert>
      ) : (
        <SetActiveWorkOrderButton handler={handleSetActive} />
      )}
      <Typography variant="h2">Details</Typography>
      <WorkOrderDetails vesselName={vesselName} workOrder={workOrder} />
      <Typography variant="h2">Equipment List</Typography>
      <Box sx={{ mb: 2, mt: 2 }}>
        <ToggleButton
          color="primary"
          selected={isEditingEquipment}
          onChange={
            isEditingEquipment
              ? () => handleModify("equipmentIds", currentEquipmentIds)
              : () => setIsEditingEquipment((prev) => !prev)
          }
          value="Show equipment list"
        >
          <PlaylistAddIcon sx={{ mr: 1 }} />
          {isEditingEquipment ? "Save changes" : "Change selected equipment"}
        </ToggleButton>
      </Box>
      {isEditingEquipment ? (
        <SelectableEquipmentList
          selectedIds={currentEquipmentIds}
          setSelectedIds={setCurrentEquipmentIds}
          showAll={false}
          vesselId={workOrder.vesselId}
        />
      ) : (
        <WorkOrderEquipmentList
          columnNames={equipmentColumnNames}
          list={workOrderEquipment}
        />
      )}
      <Box
        sx={{
          alignItems: "end",
          display: "flex",
          justifyContent: "end",
          width: "100%",
        }}
      >
        <DeleteButton
          handler={() =>
            deleteData<WorkOrderRecord>("workOrders", workOrder.id)
          }
          label="work order"
        />
      </Box>
      <AlertToast
        isOpen={isToastOpen}
        message={toastMessage}
        setter={(isOpen) => setIsToastOpen(isOpen)}
        severity={toastColor}
      />
    </main>
  ) : (
    <main>
      <Typography>No work order found with this ID.</Typography>
    </main>
  );
};

export default WorkOrderPage;
