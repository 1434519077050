import type {
  EquipmentRecord,
  WorkOrderRecord,
} from "@smartship-services/core";
import { compareByKey } from "@smartship-services/core/utils";
import { EquipmentCondition } from "@smartship-services/core";
import { getChecklistId } from "@smartship-services/core/hooks";

export const MAX_PHOTOS_PER_REPORT = 100;

const CONDITION_CHECKLIST_ID = getChecklistId("condition");

export const getFileIdsFromEquipmentList = (
  equipmentList: EquipmentRecord[],
): string[] =>
  equipmentList
    .map(({ fileMetadata }) => fileMetadata?.map(({ id }) => id) ?? [])
    .flat();

export const rebuildSummaryFromActivity = (
  summary: WorkOrderRecord["summary"],
): WorkOrderRecord["summary"] => {
  if (!summary) {
    return;
  }

  const allEquipment = [
    ...summary.failEquipment,
    ...summary.needsServiceEquipment,
    ...summary.otherEquipment,
    ...summary.goodEquipment,
  ];

  const failEquipment: EquipmentRecord[] = [];
  const needsServiceEquipment: EquipmentRecord[] = [];
  const otherEquipment: EquipmentRecord[] = [];
  const goodEquipment: EquipmentRecord[] = [];

  for (const originalEquipment of allEquipment) {
    // Backend pre-filters activity to only contain activity from this work order
    // pre-sorted with most recent first

    const conditionActivityList =
      originalEquipment.activity?.filter(
        ({ checklistId }) => checklistId === CONDITION_CHECKLIST_ID,
      ) ?? [];

    // Use most recent condition
    const activityCondition =
      conditionActivityList[0]?.body.equipmentFields?.condition;
    // Use most recent comment if available, or nothing
    const activityComment = String(
      originalEquipment.activity?.find(
        ({ body }) => body.checklistFields?.comment,
      )?.body.checklistFields?.comment || "",
    );

    const equipment = {
      ...originalEquipment,
      condition: activityCondition,
      freeComment: activityComment,
    };

    if (equipment.condition === EquipmentCondition.FAIL) {
      failEquipment.push(equipment);
    } else if (equipment.condition === EquipmentCondition.NEEDS_SERVICE) {
      needsServiceEquipment.push(equipment);
    } else if (equipment.condition === EquipmentCondition.GOOD) {
      goodEquipment.push(equipment);
    } else {
      otherEquipment.push(equipment);
    }
  }

  return {
    ...summary,
    failEquipment,
    needsServiceEquipment,
    otherEquipment,
    goodEquipment,
  };
};

export const getSortedEquipmentFromWorkOrder = (
  workOrder: WorkOrderRecord | null,
  isGoodDisplayed: boolean,
): EquipmentRecord[] => {
  if (!workOrder?.summary) {
    return [];
  }

  // Sort to keep equipment in same location together
  return [
    ...workOrder.summary.failEquipment.sort(compareByKey("locationId")),
    ...workOrder.summary.needsServiceEquipment.sort(compareByKey("locationId")),
    ...workOrder.summary.otherEquipment.sort(compareByKey("locationId")),
    ...(isGoodDisplayed
      ? workOrder.summary.goodEquipment.sort(compareByKey("locationId"))
      : []),
  ];
};

export const splitEquipmentListByMaxPhotos = (
  equipmentList: EquipmentRecord[],
): Array<Array<EquipmentRecord>> => {
  const allEquipmentLists: Array<Array<EquipmentRecord>> = [];
  let accumulatedPhotoCount = 0;
  let currentPartialList: Array<EquipmentRecord> = [];

  equipmentList.forEach((equipment, index) => {
    const isFirstEquipment = index === 0;
    const isLastEquipment = index === equipmentList.length - 1;
    const photosForThisEquipment = equipment.fileMetadata?.length ?? 0;

    accumulatedPhotoCount += photosForThisEquipment;

    if (accumulatedPhotoCount <= MAX_PHOTOS_PER_REPORT || isFirstEquipment) {
      currentPartialList.push(equipment);
    } else {
      allEquipmentLists.push(currentPartialList);
      accumulatedPhotoCount = photosForThisEquipment;
      currentPartialList = [equipment];
    }

    if (isLastEquipment) {
      allEquipmentLists.push(currentPartialList);
    }
  });

  return allEquipmentLists;
};
