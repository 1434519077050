import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ImageList, ImageListItem, useMediaQuery } from "@mui/material";

import { FileMetadataRecord } from "@smartship-services/core";
import {
  deleteData,
  getFileListByEquipment,
  writeDataList,
} from "@smartship-services/core/hooks";
import { api } from "@smartship-services/core/utils";
import { ShowOnlyFor } from "./ShowOnlyFor";
import { ImageUploadField } from "../forms";

const addImagesToDatabase = async (imageDrafts: FileMetadataRecord[]) => {
  try {
    await writeDataList("fileMetadata", imageDrafts);
  } catch (err) {
    console.log("Error saving photo data");
  }
};

interface PhotoMap {
  id: string;
  img: string;
  isOnlyOnThisDevice: boolean;
  title: string;
}

interface PhotoGalleryProps {
  equipmentId: string;
}

export const PhotoGallery = ({ equipmentId }: PhotoGalleryProps) => {
  const isWide = useMediaQuery("(min-width:600px)");
  const isVeryWide = useMediaQuery("(min-width:1200px)");
  const [imageMetadata, setImageMetadata] = useState<FileMetadataRecord[]>([]);
  const [photoMap, setPhotoMap] = useState<PhotoMap[]>([]);
  const [photoUrls, setPhotoUrls] = useState<string[]>([]);

  useEffect(() => {
    const getPhotos = async () => {
      try {
        const fileMetadata = await getFileListByEquipment(equipmentId);
        const nonEmptyFilelist = fileMetadata.filter(
          (x) => x,
        ) as FileMetadataRecord[];
        setImageMetadata(nonEmptyFilelist);
      } catch (err) {
        console.error(err);
      }
    };
    getPhotos();
  }, [equipmentId]);

  useEffect(() => {
    const generateUrls = async () => {
      try {
        const urls: string[] = [];
        const srcMap = await Promise.all(
          imageMetadata.map(async (image) => {
            let imgUrl = "";
            if (image.fileHandle) {
              imgUrl = URL.createObjectURL(image.fileHandle);
            } else if (image.s3location) {
              imgUrl = await api.download(image.id);
            }

            urls.push(imgUrl);
            return {
              id: image.id,
              img: imgUrl,
              isOnlyOnThisDevice: !!image.fileHandle && !image.s3location,
              title: image.filename || "missing filename",
            };
          }),
        );

        setPhotoUrls(urls);
        setPhotoMap(srcMap);
      } catch (err) {
        console.error(err);
      }
    };
    generateUrls();
  }, [imageMetadata]);

  const handleImageChange = async (
    change: "add" | "remove",
    newMetadata: FileMetadataRecord[],
  ) => {
    if (change === "remove") {
      const toRemove = newMetadata[0];
      setImageMetadata((prev) => {
        const removed = prev.filter(({ id }) => id !== toRemove.id);
        return removed;
      });

      try {
        await deleteData("fileMetadata", toRemove.id);
      } catch (err) {
        console.log("Error deleting fileMetadata", toRemove.id);
      }
    } else if (change === "add") {
      setImageMetadata((prev) => {
        newMetadata.forEach((image, i) => {
          newMetadata[i].equipmentId = equipmentId;
        });
        addImagesToDatabase(newMetadata);
        return [...prev, ...newMetadata];
      });
    }
  };

  useEffect(() => {
    return () => {
      //clean up memory references to photos
      photoUrls.forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, [photoUrls]);

  return (
    <>
      <ShowOnlyFor permissions={["mains:vessels:write"]}>
        <ImageUploadField
          imageMetadata={imageMetadata}
          setter={handleImageChange}
        />
      </ShowOnlyFor>
      <ImageList className="mt-2" cols={isVeryWide ? 4 : isWide ? 3 : 1}>
        {photoMap.map(({ id, img, isOnlyOnThisDevice, title }) => (
          <ImageListItem key={id}>
            {img ? (
              <>
                <img src={img} alt={title} loading="lazy" />
                {isOnlyOnThisDevice ? (
                  <Link to="/sync-queue">
                    <div className="absolute btn glass rounded-none w-full top-0">
                      <span className="text-black font-bold opacity-100">
                        Upload photos from your device
                      </span>
                    </div>
                  </Link>
                ) : null}
              </>
            ) : (
              <div className="card card-bordered" title={title}>
                <div className="card-body">
                  <h6 className="card-title">Awaiting photo</h6>
                  This photo will appear when uploaded by the user who took it.
                </div>
              </div>
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};
