import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  EquipmentField,
  EquipmentRecord,
  equipmentKeyDescriptions,
} from "@smartship-services/core";
import { ReactNode, useMemo } from "react";
import { HelpButton } from "../../buttons";
import { TailoredFormField } from "..";
import { ShowOnlyFor } from "../../layout";
import { buildSuggestions } from "@smartship-services/core/utils";
import { useEquipmentListByVessel } from "@smartship-services/core/hooks";
import { useNavigate } from "react-router-dom";

interface Params {
  saveHandler: () => void;
  changeHandler: (key: string, newValue: EquipmentField | undefined) => void;
  deleteHandler: () => void;
  equipment: EquipmentRecord;
  errorMessage?: string;
  children?: ReactNode;
}

const hiddenFields = [
  "condition",
  "custom",
  "ignore",
  "locationIdFromCustomer",
];

export const EquipmentEditForm = ({
  saveHandler,
  changeHandler,
  deleteHandler,
  equipment,
  errorMessage,
  children,
}: Params) => {
  const navigate = useNavigate();
  const allVesselEquipment = useEquipmentListByVessel(equipment.vesselId ?? "");
  const suggestions = useMemo(
    () => buildSuggestions(allVesselEquipment),
    [allVesselEquipment],
  );
  return (
    <div className="p-3 flex flex-col items-center">
      <div className="w-full overflow-x-auto mb-2">
        <table className="border-separate border-spacing-y-3 border-spacing-x-2 mx-auto">
          <tbody>
            {equipmentKeyDescriptions
              .filter(({ key }) => !hiddenFields.includes(key))
              .map(({ description, key, label }) => {
                return (
                  <tr key={key}>
                    <td>{label}</td>
                    <td>
                      <TailoredFormField
                        fieldKey={key}
                        originalValue={equipment[key]}
                        setter={changeHandler}
                        suggestions={suggestions}
                      />
                    </td>
                    <td>
                      <HelpButton content={description} />
                    </td>
                  </tr>
                );
              })}
            <tr key="condition">
              <td>Traffic Light Condition</td>
              <td>
                <button
                  className="btn"
                  onClick={() =>
                    navigate(
                      `/vessels/${equipment.vesselId}/equipment/${equipment.id}/condition`,
                    )
                  }
                >
                  Open condition form
                </button>
              </td>
              <td>
                <HelpButton content="Tip: The new Condition button on the equipment page is a quick shortcut" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {children}
      <button onClick={saveHandler} className="btn btn-primary w-96 max-w-full">
        Save
      </button>
      {errorMessage ? (
        <div role="alert" className="alert alert-warning my-3 max-w-md">
          <ExclamationTriangleIcon className="icon-size-default" />
          <span>{errorMessage}</span>
        </div>
      ) : null}
      {equipment.updatedAt ? (
        <footer className="w-full grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
          <em>
            Last modified remotely:{" "}
            {new Date(equipment.updatedAt).toLocaleString()}
          </em>
          <ShowOnlyFor permissions={["mains:admin:read"]}>
            <button
              onClick={deleteHandler}
              className="btn btn-outline btn-error w-48 justify-self-end"
            >
              Delete equipment
            </button>
          </ShowOnlyFor>
        </footer>
      ) : null}
    </div>
  );
};
