import { EquipmentRecord, Suggestions } from "../lib/types";
import { uniqueObjectValuesByKey } from "./helpers";

export const buildSuggestions = (list?: EquipmentRecord[]) => {
  const suggested: Suggestions = {};
  if (!list) {
    return suggested;
  }

  suggested.makerName = uniqueObjectValuesByKey<string>(
    list,
    "makerName",
  ).sort();
  suggested.location = uniqueObjectValuesByKey<string>(list, "location").sort();
  return suggested;
};
